import React, { useState, forwardRef, useImperativeHandle } from 'react'
import { warnInfo } from '../../../../apis/community'
import { Image } from 'antd'
import Close from '../../imgs/close.png'
import Dot from '../../imgs/icon_dian.png'
import './index.fhd.scss'
const WarnInfo = forwardRef((props, ref) => {
    const [visible, setVisible] = useState(false)
    const [row, setRow] = useState({})
    const [detail, setDetail] = useState({})
    const hide = () => {
        setVisible(false)
    }
    useImperativeHandle(ref, () => ({
        open(row) {
            setRow(row)
            getInfo(row)
            if (!visible) {
                setVisible(true)
            }
        },
        close() {
            setVisible(false)
        }
    }))
    const getInfo = ({ id }) => {
        warnInfo(id).then(res => {
            setDetail(res.data)
        })
    }
    const warnMap = {
        1: '人口提示',
        2: '事件提示',
        3: '安全提示'
    }
    const warnRightMap = {
        1: '疑似群租房',
        2: '人口上报爆发',
        3: '逾期任务超量',
        4: '报事处理超时',
        5: '诉求事件上报爆发',
        6: '不满意',
        7: '燃气隐患房屋',
        8: '人口流出爆发',
        9: '人口上报过少',
        11: '租赁纠纷事件处理超时',
        12: '租赁纠纷事件上报爆发'
    }
    const statusMap = {
        0: '正在处理',
        1: '处理完成'
    }
    const textMap = {
        0: '待处理',
        1: '处理中',
        2: '已处理'
    }
    const getLabel = status => {
        const map = {
            0: 'todo-label',
            1: 'pend-label',
            2: 'done-label'
        }
        return map[status]
    }
    return visible ? (
        <div className="warn-info">
            <img onClick={hide} src={Close} alt="err" className="close-img" />
            <div className="report-title">
                <span className="title-text">
                    {warnMap[row.eventType] +
                        ' | ' +
                        warnRightMap[row.warnType]}
                </span>
                <span className={getLabel(row.warnStatus)}>
                    {textMap[row.warnStatus]}
                </span>
            </div>
            <div className="report-detail">
                <div className="record-item">
                    {detail?.logList?.length > 0 && (
                        <div class="item-tail"></div>
                    )}
                    <div className="record-time">
                        {detail?.logList?.length > 0 && (
                            <img src={Dot} alt="err" className="dot-img"></img>
                        )}
                        <span className="time">提示时间： {row.warnTime}</span>
                    </div>
                    <div className="record-content">
                        <div className="record-row">
                            提示类型：
                            {warnRightMap[row.warnType] || '-'}
                        </div>
                        <div className="record-row">
                            提示事件内容：
                            {row.warnDesc || '-'}
                        </div>
                        <div className="record-row">
                            社区：
                            {detail.communityBaseInfoName || '-'}
                        </div>
                    </div>
                </div>
                {detail?.logList?.map((item, ix) => (
                    <div className="record-item" key={ix}>
                        {ix === detail?.logList.length - 1 ? null : (
                            <div class="item-tail"></div>
                        )}
                        <div className="record-time">
                            <img src={Dot} alt="err" className="dot-img"></img>
                            <span className="time">
                                处理时间： {item.handleTime}
                            </span>
                        </div>
                        <div className="record-content">
                            <div className="record-row">
                                处理人：{item.handleUser || '-'}
                            </div>
                            <div className="record-row">
                                处理意见：{statusMap[item.handleStatus] || '-'}
                            </div>
                            <div className="record-row">
                                反馈描述：{item.handleContent || '-'}
                            </div>
                            {item.handleStatus === 1 ? (
                                <>
                                    <div className="record-row flex">
                                        <div> 处理前图片：</div>
                                        <div style={{ flex: 1 }}>
                                            {item?.beforeImageUrls?.map(
                                                (icon, ix) => (
                                                    <span
                                                        key={ix}
                                                        style={{
                                                            marginRight: 8
                                                        }}
                                                    >
                                                        <Image
                                                            src={icon}
                                                            width={80}
                                                        ></Image>
                                                    </span>
                                                )
                                            )}
                                        </div>
                                    </div>
                                    <div className="record-row flex">
                                        <div> 处理后图片：</div>
                                        <div style={{ flex: 1 }}>
                                            {item?.afterImageUrls?.map(
                                                (icon, ix) => (
                                                    <span
                                                        key={ix}
                                                        style={{
                                                            marginRight: 8
                                                        }}
                                                    >
                                                        <Image
                                                            src={icon}
                                                            width={80}
                                                        ></Image>
                                                    </span>
                                                )
                                            )}
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <div className="record-row flex">
                                    <div> 图片：</div>
                                    <div style={{ flex: 1 }}>
                                        {item?.imageUrls?.map((icon, ix) => (
                                            <span
                                                key={ix}
                                                style={{ marginRight: 8 }}
                                            >
                                                <Image
                                                    src={icon}
                                                    width={80}
                                                ></Image>
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    ) : null
})

export default WarnInfo

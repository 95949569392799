import classNames from 'classnames'
import React, { useState } from 'react'
import shang from "../../../assets/home/shang.svg"
import xia from "../../../assets/home/xia.svg"
import { demandsList,demandsReportAsync } from 'apis/home'
import style from './DemandsReport.fhd.module.scss'
import ListModal from 'components/ListModal/ListModal'
import { useData } from '../../../hooks'
import { useNavigate } from 'react-router-dom'
import { useContext } from 'react'
import appContext from 'App.context'

export default function DemandsReport() {
    const navigate = useNavigate()
    const { communeId } = useContext(appContext)
    const [data] = useData(demandsReportAsync, communeId)
    // 模态框列表数据
    const [modalList, setModalList] = useState()

    // 模态框标题
    const title = [
        { name: '小区名称', width: 172 },
        { name: '上报总数', width: 112 },
        { name: '待处理', width: 112 },
        { name: '处理中', width: 112 },
        { name: '已完结', width: 112 },
        { name: '处理时效', width: 112 },
        { name: '满意度', width: 112 },
    ]
    const [listShow, setListShow] = useState(false)

    // 获取模态框列表
    const getList = async () => {
        const { data } = await demandsList(communeId)
        setModalList(data)
    }

    // 打开模态框
    const openModal = () => {
        getList()
        setListShow(true)
    }

    // 关闭模态框
    const closeModal = () => {
        setListShow(false)
    }

    return (
        <div className={style.container} style={{ display: typeof data !== "undefined" ? '' : 'none' }}>
            <div className={style.trigger} onClick={openModal}>
                <div className={style.item}>
                    <div className={style.title}>上报总数</div>
                    <div className={style.mes}>
                        <div>{data?.total}</div>
                        <div className={style.on}>
                            <div className={classNames(data?.totalRate < 0 ? style.down : data?.totalRate === 0 ? style.none : '','mr-1')}>{data?.totalRate === 0 ? '-' : data?.totalRate > 0 ? '+' + data?.totalRate + '%' : data?.totalRate + '%'}</div>
                            <img src={data?.totalRate < 0 ? xia : shang} style={{ display: data?.totalRate !== 0 ? 'block' : 'none' }} alt="" />
                        </div>
                    </div>
                </div>
                <div className={classNames(style.item)}>
                    <div className={style.title}>处理时效</div>
                    <div className={style.mes}>
                        <div>{data?.hours}h</div>
                        <div className={style.on}>
                            <div className={classNames(data?.hoursRate < 0 ? style.down : data?.hoursRate === 0 ? style.none : '','mr-1')}>{data?.hoursRate === 0 ? '-' : data?.hoursRate > 0 ? '+' + data?.hoursRate + '%' : data?.hoursRate + '%'}</div>
                            <img src={data?.hoursRate < 0 ? xia : shang} style={{ display: data?.hoursRate !== 0 ? 'block' : 'none' }} alt="" />
                        </div>
                    </div>
                </div>
                <div className={classNames(style.item)}>
                    <div className={style.title}>已完结</div>
                    <div className={style.mes}>
                        <div>{data?.done}</div>
                        <div className={style.on}>
                            <div className={classNames(data?.doneRate < 0 ? style.down : data?.doneRate === 0 ? style.none : '','mr-1')}>{data?.doneRate === 0 ? '-' : data?.doneRate > 0 ? '+' + data?.doneRate + '%' : data?.doneRate + '%'}</div>
                            <img src={data?.doneRate < 0 ? xia : shang} style={{ display: data?.doneRate !== 0 ? 'block' : 'none' }} alt="" />
                        </div>
                    </div>
                </div>
                <div className={classNames(style.item)}>
                    <div className={style.title}>满意度</div>
                    <div className={style.mes}>
                        <div>{data?.score}</div>
                        <div className={style.on}>
                            <div className={classNames(data?.scoreRate < 0 ? style.down : data?.scoreRate === 0 ? style.none : '','mr-1')}>{data?.scoreRate === 0 ? '-' : data?.scoreRate > 0 ? '+' + data?.scoreRate + '%' : data?.scoreRate + '%'}</div>
                            <img src={data?.scoreRate < 0 ? xia : shang} style={{ display: data?.scoreRate !== 0 ? 'block' : 'none' }} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            {
                listShow ? (
                    <div className="box-center">
                        <ListModal height={856} width={902} title={title} closeModal={closeModal}>
                            <ul className="overflow-y" style={{ height: '808px' }}>
                                {modalList?.map((s, i) => {
                                    return (
                                        <li
                                            className='m-hover trigger flex ai-c height-48'
                                            style={{ backgroundColor: i % 2 === 0 ? 'rgba(78,90,105,0.4)' : '' }}
                                            key={s.id}
                                            onClick={() => {
                                                navigate(`/community?communityInfoId=${s.id}`)
                                            }}
                                        >
                                            <span className='w-[172px] pl-4'>{s.name}</span>
                                            <span className='w-[112px] pl-4'>{s.total}</span>
                                            <span className='w-[112px] pl-4'>{s.todo}</span>
                                            <span className='w-[112px] pl-4'>{s.doing}</span>
                                            <span className='w-[112px] pl-4'>{s.done}</span>
                                            <span className='w-[112px] pl-4'>{s.hours}</span>
                                            <span className='w-[112px] pl-4'>{s.score}%</span>
                                        </li>
                                    )
                                })}
                            </ul>
                        </ListModal>
                    </div>
                ) : null
            }
        </div >
    )
}


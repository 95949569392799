import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Line } from "@antv/g2plot"
import { useData } from '../../../hooks'
import useDOM from "../../../hooks/useDOM"
import { demandsTrend } from 'apis/home'
import Wrapper from '../../../components/Wrapper/Wrapper'
import appContext from 'App.context'

export default function DemandsType() {

    const [container, getDOM] = useDOM()
    const lineRef = useRef()
    const { communeId } = useContext(appContext)
    const [dataSource] = useData(demandsTrend, communeId)
    const [rendered, setRendered] = useState(false)
    const legend = [
        { value: '扰民投诉', color: '#FEB903' },
        { value: '租赁纠纷', color: '#FF7A7A' },
        { value: '其他诉求', color: '#5D7092' },
    ]

    const data = useMemo(() => {

        const complain = []
        dataSource?.forEach(s => {
            complain.push(
                {
                    "date": s.name,
                    "type": "扰民投诉",
                    "value": s.complain
                }
            )
        })
        const lease = []
        dataSource?.forEach(s => {
            lease.push(
                {
                    "date": s.name,
                    "type": "租赁纠纷",
                    "value": s.lease
                }
            )
        })
        const other = []
        dataSource?.forEach(s => {
            other.push(
                {
                    "date": s.name,
                    "type": "其他诉求",
                    "value": s.other
                }
            )
        })
        return [...complain, ...lease, ...other]
    }, [dataSource])

    const createChart = useCallback(() => {
        if (!container) return

        const config = {
            data,
            xField: 'date',
            yField: 'value',
            width: container.clientWidth,
            height: container.clientHeight,
            padding: [20, 10, 30, 40],
            yAxis: {
                label: {
                    // 数值格式化为千分位
                    formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
                    style: {
                        fill: "white",
                        display: "none"
                    }
                },
                grid: {
                    line: {
                        style: {
                            opacity: 0.1,
                        }
                    }
                }
            },
            xAxis: {
                label: {
                    style: {
                        fill: "white"
                    }
                }
            },
            legend: false,
            colorField: 'type',
            color: ['#FEB903', '#FF7A7A', '#5D7092'],
            seriesField: 'type',
        }
        if (rendered) {
            lineRef.current.update(config)
            return
        }
        const line = new Line("_type_fhd", config)
        line.render()
        setRendered(true)
        lineRef.current = line
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [container, data])

    useEffect(() => {
        createChart()
    }, [createChart])
    return (
        <Wrapper className="w-[458px] md:w-[600px]" height={166} title="租赁诉求事件上报趋势">
            <div className='flex height-full'>
                <div ref={getDOM} style={{ width: "80%" }}>
                    <div id="_type_fhd"></div>
                </div>
                <ul className="pt-2 pl-2">
                    {legend.map(s => {
                        return (
                            <li key={s.value} className="flex ai-c mt-3 mb-3">
                                <span style={{ backgroundColor: s.color, width: '8px', height: '8px', borderRadius: '50%', marginRight: '8px' }}></span>
                                <span className='fs-12'>{s.value}</span>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </Wrapper>
    )
}

export function checkValIsNull(val) {
    // return val ?? '-';
    return typeof val === "undefined" || val === null || val === "" || val === 0
}

// 数据空值处理
export function formatValue(value, unit = "") {
    if (checkValIsNull(value)) return "-"
    if (typeof value === 'number' && isNaN(value)) return "-"
    return value + unit
}

export function getQuery(params) {
    if (typeof params !== "string") {
        return console.error("参数类型错误，非 string")
    }
    const values = params
        .replace("?", "")
        .split("&")
        .map(item => item.split("="))
    let result = {}
    for (const item of values) {
        result[item[0]] = decodeURI(item[1])
    }
    return result
}

// 重置url
export const resetUrl = () => {
    window.history.replaceState(
        null,
        null,
        window.location.href?.substring(0, window.location.href?.indexOf("?"))
    )
}

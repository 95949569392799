import React, { useEffect, useMemo, useRef, useState } from 'react'
import classNames from 'classnames'
import style from "./StatisticsList.fhd.module.scss"
import { handleData, scrollList } from 'apis/home'
import { useContext } from 'react'
import appContext from 'App.context'
import LogsModal from './LogsModal'

export default function StatisticsList() {
    const { communeId } = useContext(appContext)
    const ref = useRef()

    // 获取日，月，总数数据
    const [realTime, setRealTime] = useState()
    const getData = async () => {
        const { data } = await handleData(communeId)
        setRealTime(data)
    }
    // 事件处理展示数据
    const realTimeData = useMemo(() => {
        return [
            {
                label: '累计处理数',
                value: realTime?.total,
                unit: '条',
            },
            {
                label: '当月处理数',
                value: realTime?.month,
                unit: '条',
            },
            {
                label: '当日处理数',
                value: realTime?.today,
                unit: '条',
            },
        ]
    }, [realTime])

    // tab切换
    const tabs = [
        {
            label: '租赁纠纷',
            value: 'lease',
        },
        {
            label: '扰民投诉',
            value: 'comp',
        },
        {
            label: '其他诉求',
            value: 'oth',
        },
    ]
    const [chooseTab, setChooseTab] = useState('comp')
    const changeTab = (value) => {
        setChooseTab(value)
        getList(value)
    }

    const [list, setList] = useState()
    // const [show, setShow] = useState(false)
    // 单个数据详情
    // const [warnData, setWarnData] = useState()

    // 获取滚动列表数据
    const getList = async (chooseTab) => {
        let { data } = await scrollList(communeId, chooseTab)
        if (data.length == 0) return
        setList(data.filter(s => {
            if (!list?.includes(s.id)) return s.status !== '无效反馈' && s.status !== '已取消'
        }))
    }
    useEffect(() => {
        getList(chooseTab)
        getData()
    }, [])

    let timer = useRef()
    let parent
    let child1
    let child2
    let count = 0

    useEffect(() => {
        clearInterval(timer.current)
        parent = document.getElementById('statisticsParent');
        child1 = document.getElementById('statisticsChild1');
        child2 = document.getElementById('statisticsChild2');
        if (list?.length < 9) return
        child2.innerHTML = child1.innerHTML;
        timer.current = setInterval(() => {
            if (child1.clientHeight == 0) return
            if (parent.scrollTop >= child1.scrollHeight) {
                count = 0
                parent.scrollTop = count;
                getList()
            } else {
                parent.scrollTop = count++
            }
        }, 50)
        return () => {
            clearInterval(timer.current)
        }
    }, [list])

    const inner = () => {
        clearInterval(timer.current)
    }

    const out = () => {
        parent = document.getElementById('statisticsParent');
        child1 = document.getElementById('statisticsChild1');
        child2 = document.getElementById('statisticsChild2');
        clearInterval(timer.current)
        timer.current = setInterval(() => {
            if (child1.clientHeight == 0) return
            if (parent.scrollTop >= child1.scrollHeight) {
                count = 0
                parent.scrollTop = count;
                getList()
            } else {
                parent.scrollTop = count++
            }
        }, 50)
    }

    // 打开模态框，获取报事记录
    const getDetail = async (id) => {
        // setWarnData(data)
        // setShow(true)
        ref.current.open(id, chooseTab)
    }

    // 关闭模态框
    // const closeModal = () => {
    //     setShow(false)
    // }

    return (
        <div className={style.container}>
            <div className={style.realTime}>
                {realTimeData.map(s => {
                    return (
                        <div key={s.label}>
                            <div>
                                <span className={style.count}>{s.value}</span>
                                <span>{s.unit}</span>
                            </div>
                            <div>{s.label}</div>
                        </div>
                    )
                })}
            </div>
            <div className={style.tabs}>
                {tabs.map(s => {
                    return (
                        <div
                            className={chooseTab === s.value ? style.tabActive : ''}
                            key={s.value}
                            onClick={() => changeTab(s.value)}
                        >
                            <span>{s.label}</span>
                        </div>
                    )
                })}
            </div>
            <div className={style.title}>
                <span>报事人手机号</span>
                <span>上报时间</span>
                <span>办理状态</span>
            </div>
            <div className={style.itemContainer} id="statisticsParent" onMouseEnter={() => {
                inner()
            }} onMouseLeave={() => {
                out()
            }} onScroll={(e) => {
                count = e.target.scrollTop
            }}>
                <ul className={style.child} id="statisticsChild1" >
                    {list?.map(s => {
                        return (
                            <li className={classNames(style.item)} key={s.id} onClick={() => {
                                getDetail(s.id)
                            }}>
                                <span>{s?.phone || '-'}</span>
                                <span>{s?.reportTime}</span>
                                <span style={{ color: s?.status === '已处理' ? '#10DF9D' : s?.status === '待处理' ? '#FEB903' : s?.status === '处理中' ? '#26a6ff' : 'white' }}>{s?.status}</span>
                            </li>
                        )
                    })}
                </ul>
                <div id='statisticsChild2' className={style.child}></div>
            </div>
            {/* {show ? <StatisticsModal warnData={warnData} closeModal={closeModal}></StatisticsModal> : null} */}
            <LogsModal ref={ref}></LogsModal>
        </div>
    )
}
import axios from "../request";

export const statistics = (data) => axios.post('/big_screen/warn/statistics/list',data)

// 社区数据
export const getSmallCommunities = (communityBaseInfoId) => axios.get(`/big_screen/yyh/statistics/${communityBaseInfoId}`)

// 获取预警列表
export const getWarns = (communityBaseInfoId) => axios.get(`/big_screen/warn/community/statistics/${communityBaseInfoId}`)
// 小区数据
export const getCommunitiesInfo = (communityBaseInfoId) => axios.get(`/big_screen/warn/community/all/statistics/${communityBaseInfoId}`)
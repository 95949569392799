import Axios2 from "axios"

const successCode = '000'

const axios2 = Axios2.create({
    // baseURL: 'http://api-zuul2.k8s.testuoko.com', // test1
    // baseURL: 'https://api-zuul-k8s-pre.uoko.com:20443', // pre
    baseURL: 'https://api-gateway.uoko.com', // prod
    headers: { "Content-Type": "application/json;charset=UTF-8", RequestSource: "management" },
})

axios2.interceptors.request.use((config) => {
    config.url = `cms${config.url}`
    return config
})

axios2.interceptors.response.use(
    (res) => {
        return new Promise((resolve, reject) => {
            let { data } = res
            // 错误情况
            if (data?.code !== successCode) {
                reject(data)
            } else {
                // 标准格式判断
                if (!isStandardFormat(data)) {
                    console.error("非标准返回格式，请联系后端人员修改")
                }
                resolve(data)
            }
        })
    },
    (error) => {
        console.log(error)
        const res = error.response?.data
        const msg = res?.message ?? "请求错误,请检查网络后重试"
        return Promise.reject(msg)
    }
)

function isStandardFormat(data) {
    return !!data?.code
}
export default axios2

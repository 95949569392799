import { useEffect, useState } from "react"
import { createPortal } from "react-dom"

const Modal = ({ mask, children, visible = false, width = 900, height = "fit-content" }) => {
    const [_visible, _setVisible] = useState(false)
    useEffect(() => {
        _setVisible(visible)
    }, [visible])
    return createPortal(
        <div
            style={{
                position: "fixed",
                top: '50%',
                left: '50%',
                transform:'translate(-50%,-50%)',
                display: _visible ? "block" : "none",
                width,
                height,
                zIndex: 999,
            }}
        >
            {children}
        </div>,
        document.body
    )
}

export default Modal

import React from 'react'

import classNames from "classnames"
import shang from "../../../assets/home/shang.svg"
import { leasePeople, peopleList } from 'apis/home'
import xia from "../../../assets/home/xia.svg"
import ListModal from 'components/ListModal/ListModal'
import { useData } from "../../../hooks"
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useContext } from 'react'
import appContext from 'App.context'

export default function PeopleData() {
    const navigate = useNavigate()
    const { communeId } = useContext(appContext)
    const [data] = useData(leasePeople, communeId)
    // 模态框列表数据
    const [modalList, setModalList] = useState()
    // 模态框标题
    const title = [
        { name: '小区名称', width: 172 },
        { name: '租赁人口数', width: 170 },
        { name: '整租人口数', width: 170 },
        { name: '合租人口数', width: 170 },
        { name: '未知人口数', width: 170 },
    ]
    const [listShow, setListShow] = useState(false)

    // 获取模态框列表数据
    const getList = async () => {
        const { data } = await peopleList(communeId)
        setModalList(data)
    }

    // 打开模态框
    const openModal = () => {
        getList()
        setListShow(true)
    }

    // 关闭模态框
    const closeModal = () => {
        setListShow(false)
    }

    return (
        <>
            <div className='trigger' onClick={openModal} style={{ display: data?.allCount ? '' : 'none' }}>
                <div className="fs-20 pl-4 flex ai-c">
                    <div className='t-color  flex ai-c'><span className='t-Md fs-36'>{data?.allCount}</span>人</div>
                    <div className="ml-2">
                        <div className="flex ai-c color-4C99FF">
                            <div className={classNames('fs-14 mr-1', data?.allMonthRatio < 0 ? 'color-10DF9D' : data?.allMonthRatio === 0 ? 't-color' : '')}>{data?.allMonthRatio === 0 ? '-' : data?.allMonthRatio > 0 ? '+' + data?.allMonthRatio + '%' : data?.allMonthRatio + '%'}</div>
                            <img style={{ display: data?.allMonthRatio !== 0 ? "" : 'none' }} className="height-16 width-16" src={data?.allMonthRatio < 0 ? xia : shang} alt="" />
                        </div>
                    </div>
                </div>
                <div className="pl-3 pr-2">
                    <div className="flex jc-b ai-c">
                        <div className='fs-14 '>整租人口</div>
                        <div className="flex ai-c">
                            <div className='t-color fs-20 t-Md mr-1'>{data?.wholeTenantPopulationCount}</div>
                            <div className="flex ai-c jc-e color-4C99FF" style={{ minWidth: '80px' }}>
                                <div className={classNames('fs-14 mr-1', data?.wholeTenantPopulationMonthRatio < 0 ? 'color-10DF9D' : data?.wholeTenantPopulationMonthRatio === 0 ? 't-color' : '')}>{data?.wholeTenantPopulationMonthRatio === 0 ? '-' : data?.wholeTenantPopulationMonthRatio > 0 ? '+' + data?.wholeTenantPopulationMonthRatio + '%' : data?.wholeTenantPopulationMonthRatio + '%'}</div>
                                <img style={{ display: data?.wholeTenantPopulationMonthRatio !== 0 ? "" : 'none' }} className="height-16 width-16" src={data?.wholeTenantPopulationMonthRatio < 0 ? xia : shang} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className='flex jc-b ai-c'>
                        <div className='fs-14 '>合租人口</div>
                        <div className="flex ai-c">
                            <div className='t-color fs-20 t-Md mr-1'>{data?.rentJointlyCount}</div>
                            <div className="flex ai-c jc-e color-4C99FF" style={{ minWidth: '80px' }}>
                                <div className={classNames('fs-14 mr-1', data?.rentJointlyMonthRatio < 0 ? 'color-10DF9D' : data?.rentJointlyMonthRatio === 0 ? 't-color' : '')}>{data?.rentJointlyMonthRatio === 0 ? '-' : data?.rentJointlyMonthRatio > 0 ? '+' + data?.rentJointlyMonthRatio + '%' : data?.rentJointlyMonthRatio + '%'}</div>
                                <img style={{ display: data?.rentJointlyMonthRatio !== 0 ? "" : 'none' }} className="height-16 width-16" src={data?.rentJointlyMonthRatio < 0 ? xia : shang} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className='flex jc-b ai-c'>
                        <div className='fs-14 '>未知人口</div>
                        <div className="flex ai-c">
                            <div className='t-color fs-20 t-Md mr-1'>{data?.unknownCount}</div>
                            <div className="flex ai-c jc-e color-4C99FF" style={{ minWidth: '80px' }}>
                                <div className={classNames('fs-14 mr-1', data?.unknownMonthRatio < 0 ? 'color-10DF9D' : data?.unknownMonthRatio === 0 ? 't-color' : '')}>{data?.unknownMonthRatio === 0 ? '-' : data?.unknownMonthRatio > 0 ? '+' + data?.unknownMonthRatio + '%' : data?.unknownMonthRatio + '%'}</div>
                                <img style={{ display: data?.unknownMonthRatio !== 0 ? "" : 'none' }} className="height-16 width-16" src={data?.unknownMonthRatio < 0 ? xia : shang} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {listShow ? (
                <div className="box-center">
                    <ListModal height={856} width={902} title={title} closeModal={closeModal}>
                        <ul className="overflow-y" style={{ height: '808px' }}>
                            {modalList?.map((s, i) => {
                                return (
                                    <li
                                        className='flex ai-c height-48 trigger m-hover'
                                        key={s.communityInfoId}
                                        onClick={() => {
                                            navigate(`/community?communityInfoId=${s.communityInfoId}`)
                                        }}
                                        style={{ backgroundColor: i % 2 === 0 ? 'rgba(78,90,105,0.4)' : '' }}
                                    >
                                        <span className='w-[172px] pl-4 text-ellipesis '>{s.communityInfoName}</span>
                                        <span className='w-[170px] pl-4'>{s.allCount}</span>
                                        <span className='w-[170px] pl-4'>{s.wholeTenantPopulationCount}</span>
                                        <span className='w-[170px] pl-4'>{s.rentJointlyCount}</span>
                                        <span className='w-[170px] pl-4'>{s.unknownCount}</span>
                                    </li>
                                )
                            })}
                        </ul>
                    </ListModal>
                </div>
            ) : null}
        </>
    )
}

import Wrapper from "../../components/Wrapper/Wrapper"
import CommunityIntro from "./CommunityIntro/CommunityIntro"
import CommunityFB from "./CommunityFB/CommunityFB"
import PeopleData from "./PeopleData/PeopleData"
import DemandsType from "./DemandsType/DemandsType"
import DemandsReport from "./DemandsReport/DemandsReport"
import Statistics from "./Statistics/Statistics"
import HouseStatusTrend from "./HouseStatusTrend/HouseStatusTrend"
import PopulationTrend from "./PopulationTrend/PopulationTrend"
import Map from "./Map/Map"
import { getUsingStatistics2 } from "../../apis/house"
import { useData } from "../../hooks"

import StatisticsList from "./StatisticsList/StatisticsList"
import { useContext, useRef } from "react"
import appContext from "../../App.context"
import "./Home.scss"
import PlatformData from "./PlatformData/PlatformData"

const Home = () => {
    const { communeId } = useContext(appContext)

    const statisticsRef = useRef()
    const communityFBRef = useRef()

    const [cardDataSource] = useData(getUsingStatistics2, communeId)

    const openModal = (key) => {
        switch (key) {
            case "Statistics":
                communityFBRef.current.closeModal()
                break
            case "CommunityFB":
                statisticsRef.current.closeModal()
                break
            default:
        }
    }

    return (
        <div className="home height-full flex fd-c">
            <div className="body">
                <div className="flex jc-b mt-2 h-full">
                    <div className="flex flex-col w-[458px] md:w-[600px]">
                        <div className="flex w-full justify-between mb-2">
                            <Wrapper
                                height={206}
                                title="社区租赁房屋数据"
                                className="w-[225px] md:w-[290px]">
                                <CommunityIntro></CommunityIntro>
                            </Wrapper>
                            <Wrapper
                                height={206}
                                title="社区租赁人口数据"
                                className="w-[225px] md:w-[290px]">
                                <PeopleData></PeopleData>
                            </Wrapper>
                        </div>
                        {/* 社区租赁房屋变化趋势 */}
                        <HouseStatusTrend className='mb-2' />
                        {/* 社区租住人口搬入搬离趋势 */}
                        <PopulationTrend />
                        <Wrapper
                            className="w-[458px] md:w-[600px] mt-2"
                            height={190}
                            title="社区租赁经营主体分布">
                            <CommunityFB ref={communityFBRef} openModal={openModal} />
                        </Wrapper>
                        <Wrapper
                            className="w-[458px] md:w-[600px] mt-2"
                            height={136}
                            title="租住平台运营数据">
                            <PlatformData />
                        </Wrapper>
                    </div>
                    <div className="flex jc-b flex-1 pl-2 h-[963px]">
                        <div className="flex flex-1 pr-2">
                            {/* 地图 */}
                            <Wrapper className={"w-full"}>
                                <Map type="house" statistics={cardDataSource} />
                            </Wrapper>
                        </div>
                        <div className="flex flex-col gap-2 h-full w-[458px] md:w-[600px]">
                            <Wrapper
                                className="w-[458px] md:w-[600px]"
                                height={216}
                                title="工作提示">
                                <Statistics ref={statisticsRef} openModal={openModal} />
                            </Wrapper>
                            <Wrapper
                                className="flex-1 w-[458px] md:w-[600px]"
                                title="事件处理"
                                height={390}
                            >
                                <StatisticsList></StatisticsList>
                            </Wrapper>
                            <Wrapper
                                className="w-[458px] md:w-[600px]"
                                height={174}
                                title="租赁诉求事件上报统计">
                                <DemandsReport></DemandsReport>
                            </Wrapper>
                            {/* 社区租赁纠纷上报趋势 */}
                            <DemandsType></DemandsType>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home

import { conmunitiesConf } from "./config"
import { useEffect, useMemo, useRef } from "react"
import personSVG from "assets/remote/people.svg"
import eventSVG from "assets/remote/event.svg"
import warnSVG from "assets/remote/safe.svg"
import { getUnitStatistics } from "../../../../apis/community"
import { useData } from "../../../../hooks"

import "./BackGroundCommon.scss"
import "./BackGroundStyles"

const BackGround = ({ onSelect, communityInfoId: smallCommunityID }) => {
    const imgRef = useRef()

    // 获取小区详情
    // const [communityDetail] = useData(getCommunityDetail, smallCommunityID);
    useEffect(() => {
        onSelect?.(conmunitiesConf.find((item) => item.id === smallCommunityID)?.points?.[0].id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const smallCommunity = useMemo(
        () => conmunitiesConf.find((item) => item.id === smallCommunityID),
        [smallCommunityID]
    )

    const [units] = useData(getUnitStatistics, smallCommunityID)

    return (
        <div className="community-background" ref={imgRef}>
            {/* 整体背景 */}
            <img className="full-bg" src={smallCommunity?.photo} alt="" />
            {/* 背景-阴影 */}
            <div className="bg-left"></div>
            <div className="bg-right"></div>
            <div className="bg-top"></div>

            {/* 小区图片 */}
            {smallCommunity?.photo &&<div className="bg-wrap">
                <div className="bg-img-wrap flex items-center justify-center">
                    <img className="bg" src={smallCommunity?.photo} alt="" />
                </div>
            </div>}

            {/* 小区描点 */}
            <div className="points-wrap">
                {smallCommunity?.points?.map((localUnit, i) => {
                    const unit = units?.find((unit) => unit.buildingUnitId === localUnit.id)
                    const hasWarn = !!(
                        unit?.populationWarnAmount ||
                        unit?.eventWarnAmount ||
                        unit?.securityWarnAmount
                    )
                    // const leftTop = getLeftTop(item.x, item.y)
                    return (
                        <>
                            <div
                                className="point"
                                key={i}
                                style={{ left: localUnit.x, top: localUnit.y }}
                                onClick={() => {
                                    onSelect?.(localUnit?.id)
                                }}
                            >
                                {localUnit.name}
                            </div>
                            {/* cntent  */}
                            {hasWarn && (
                                <div
                                    style={{ left: localUnit?.x, top: localUnit?.y }}
                                    className="content top"
                                >
                                    {/* <div className="title">
                                        <span className="text">{communityDetail?.communityName}</span>
                                    </div> */}
                                    <div className="main">
                                        {!!unit?.populationWarnAmount && (
                                            <span className="item">
                                                <img src={personSVG} alt="" />
                                                <span className="label">人口提示</span>
                                                <span className="value">
                                                    {unit?.populationWarnAmount ?? "-"}
                                                </span>
                                            </span>
                                        )}
                                        {!!unit?.eventWarnAmount && (
                                            <span className="item">
                                                <img src={eventSVG} alt="" />
                                                <span className="label">事件提示</span>
                                                <span className="value">
                                                    {unit?.eventWarnAmount ?? "-"}
                                                </span>
                                            </span>
                                        )}
                                        {!!unit?.securityWarnAmount && (
                                            <span className="item">
                                                <img src={warnSVG} alt="" />
                                                <span className="label">安全提示</span>
                                                <span className="value">
                                                    {unit?.securityWarnAmount ?? "-"}
                                                </span>
                                            </span>
                                        )}
                                    </div>
                                </div>
                            )}
                        </>
                    )
                })}
            </div>
        </div>
    )
}

export default BackGround

// // ⚠️单元第一个需要是1单元

import xyc from "./assets/bg/香悦城.webp"
import imc from "./assets/bg/IMC国际广场.webp"
import dyzh from "./assets/bg/大有智慧.webp"
import fhzx from "./assets/bg/峰会中心.webp"
import gticon from "./assets/bg/高投icon.webp"
import htcsc1 from "./assets/bg/航天城上城.webp"
import htcsc2 from "./assets/bg/航天城上城2期.webp"
import ljgj from "./assets/bg/两江国际.webp"
import ldzc from "./assets/bg/绿地之窗.webp"
import rljz from "./assets/bg/润莱金座.webp"
import slgg from "./assets/bg/世龙公馆.webp"
import sdzx from "./assets/bg/蜀都中心.webp"
import sdzx2 from "./assets/bg/蜀都中心2期.webp"
import wgj from "./assets/bg/无国界.webp"
import xyh from "./assets/bg/香月湖.webp"
import xczx from "./assets/bg/雄川中心.webp"
import trhqsd from "./assets/bg/泰然环球时代.webp"
import dygjzx2 from "./assets/bg/大源国际中心2期.webp"
import zltfhy from "./assets/bg/中粮.webp"
import thxqu from "./assets/bg/天荟.webp"
import jksdgc from "./assets/bg/金控时代广场.webp"
import frgjgc from "./assets/bg/富润国际广场.webp"
import fngc from "./assets/bg/福年广场.webp"

export const conmunitiesConf = [
    {
        id: "1518900026860150784",
        // photo: 'https://image.uoko.com/yueyahuCommunity/香悦城4k.webp',
        photo: xyc,
        points: [
            {
                name: "1栋1单元",
                id: "1539773343842213888",
                y: "38.80%",
                x: 1250,
            },
            {
                name: "1栋2单元",
                id: "1539773343875768335",
                y: "35.46%",
                x: 1160,
            },

            {
                name: "1栋3单元",
                id: "1539773343884156957",
                y: "30.00%",
                x: 996,
            },

            {
                name: "1栋4单元",
                id: "1539773343884157088",
                y: "28.61%",
                x: 927,
            },
            {
                name: "1栋5单元",
                id: "1539773343888351280",
                y: "43.15%",
                x: 763,
            },
        ],
    },
    {
        id: "1518900030253342720",
        // "https://image.uoko.com/yueyahuCommunity/中粮4k169.webp"
        photo: zltfhy,
        points: [
            {
                name: "1栋1单元",
                id: "1531564216028536833",
                x: 723,
                y: 327,
            },
            {
                name: "2栋1单元",
                id: "1531564368969637889",
                x: 979,
                y: 306,
            },
            {
                name: "3栋1单元",
                id: "1531564414779826177",
                x: 1225,
                y: 357,
            },
        ],
    },
    {
        id: "1542681685526880256",
        photo: sdzx,
        points: [
            // {
            //     name: "1栋1单元",
            //     id: "1542682972465176576",
            //     x: 700,
            //     y: 560,
            // },
            // {
            //     name: "2栋1单元",
            //     id: "1542682972465176576",
            //     x: 1169,
            //     y: 557,
            // },
            {
                name: "3栋1单元",
                id: "1542682972465176576",
                x: 932,
                y: 442,
            },
            {
                name: "4栋1单元",
                id: "1542683978217332737",
                x: 674,
                y: 430,
            },
            {
                name: "5栋1单元",
                id: "1542684067807666177",
                x: 1169,
                y: 418,
            },
        ],
    },
    {
        id: "1542684539004166144",
        photo: thxqu,
        // photo: "https://image.uoko.com/yueyahuCommunity/天荟4k169.webp",
        points: [
            {
                name: "2栋1单元",
                id: "1542684754763358209",
                x: 1126,
                y: 400,
            },
            {
                name: "2栋2单元",
                id: "1542684754763358386",
                x: 1024,
                y: 350,
            },
            {
                name: "3栋1单元",
                id: "1542684977770307585",
                x: 762,
                y: 369,
            },
            {
                name: "3栋2单元",
                id: "1542686588424986624",
                x: 720,
                y: 410,
            },
        ],
    },
    {
        id: "1518900026092593152",
        // photo: "https://image.uoko.com/yueyahuCommunity/金控时代广场4k169.webp",
        photo: jksdgc,
        points: [
            {
                name: "3栋1单元",
                id: "1544239778391957514",
                x: 820,
                y: 370,
            },
            {
                name: "3栋2单元",
                id: "1544239778404540454",
                x: 890,
                y: 364,
            },
            {
                name: "4栋1单元",
                id: "1544239778417123331",
                x: 1057,
                y: 400,
            },
            {
                name: "4栋2单元",
                id: "1544239778421317657",
                x: 1122,
                y: 392,
            },
        ],
    },
    {
        id: "1518900029313818624",
        photo: imc,
        points: [
            {
                name: "1栋2单元",
                id: "1522106410527399936",
                y: "61.43%",
                x: 761,
            },
            {
                name: "1栋3单元",
                id: "1522106539602911232",
                y: "37.66%",
                x: 690,
            },
            {
                name: "1栋4单元",
                id: "1522106539607105626",
                y: "35.28%",
                x: 817,
            },
            {
                name: "1栋5单元",
                id: "1522107070039760896",
                y: "34.55%",
                x: 1103,
            },
            {
                name: "1栋6单元",
                id: "1522107070039761049",
                y: "38.39%",
                x: 1235,
            },
            {
                name: "1栋1单元",
                id: "1529752301639868416",
                y: "60.79%",
                x: 1171,
            },
        ],
    },
    {
        id: "1518900030823768064",
        photo: dyzh,
        points: [
            {
                name: "1栋1单元",
                id: "1529752796181864448",
                y: "58.14%",
                x: 1293,
            },
        ],
    },

    {
        id: "1518900027875172352",
        photo: fhzx,
        points: [
            {
                name: "3栋1单元",
                id: "1529754077172637697",
                y: "38.57%",
                x: 882,
            },
            {
                name: "3栋2单元",
                id: "1529754077172637842",
                y: "38.57%",
                x: 1056,
            },
            {
                name: "2栋1单元",
                id: "1529754322648473600",
                y: "36.84%",
                x: 1184,
            },
        ],
    },
    {
        id: "1529755463780179968",
        photo: gticon,
        points: [
            {
                name: "1栋1单元",
                id: "1529755531744681985",
                y: "32.72%",
                x: 957,
            },
            {
                name: "1栋2单元",
                id: "1544239778383568897",
                y: "34.55%",
                x: 802,
            },
        ],
    },
    {
        id: "1518900025723494400",
        photo: htcsc2,
        points: [
            {
                name: "1栋1单元",
                id: "1529757402685284353",
                y: "36.84%",
                x: 587,
            },
            {
                name: "1栋2单元",
                id: "1529757402685284876",
                y: "30.80%",
                x: 646,
            },
            {
                name: "2栋1单元",
                id: "1529758122151026689",
                y: "34.46%",
                x: 942,
            },
            {
                name: "3栋1单元",
                id: "1529759045782904833",
                y: "42.14%",
                x: 1019,
            },
            {
                name: "5栋1单元",
                id: "1529759473903902721",
                y: "53.66%",
                x: 1128,
            },
            {
                name: "7栋1单元",
                id: "1529759536474529793",
                y: "67.55%",
                x: 1248,
            },
            {
                name: "9栋1单元",
                id: "1544239778337431553",
                y: "84.28%",
                x: 1203,
            },
        ],
    },
    {
        id: "1531480650674380800",
        photo: htcsc1,
        points: [
            {
                name: "10栋1单元",
                id: "1531486730250919937",
                y: "43.78%",
                x: 567,
            },
            {
                name: "10栋2单元",
                id: "1531486730250920460",
                y: "39.95%",
                x: 677,
            },
            {
                name: "11栋1单元",
                id: "1531517954361171969",
                y: "37.11%",
                x: 795,
            },
            {
                name: "11栋2单元",
                id: "1531517954361172456",
                y: "34.19%",
                x: 886,
            },
            {
                name: "12栋1单元",
                id: "1531518086150397953",
                y: "42.78%",
                x: 1214,
            },
            {
                name: "12栋2单元",
                id: "1531518086150398440",
                y: "45.80%",
                x: 1122,
            },
        ],
    },
    {
        id: "1518900026465886208",
        photo: ljgj,
        points: [
            {
                name: "2栋1单元",
                id: "1531548555197849601",
                y: "48.90%",
                x: 951,
            },
            {
                name: "3栋1单元",
                id: "1531549461695995905",
                y: "45.16%",
                x: 1113,
            },
            {
                name: "4栋1单元",
                id: "1531549461695996062",
                y: "47.35%",
                x: 1243,
            },
            {
                name: "5栋1单元",
                id: "1531550939366072321",
                y: "54.66%",
                x: 1103,
            },
            {
                name: "6栋1单元",
                id: "1531551444205084673",
                y: "57.13%",
                x: 879,
            },
            {
                name: "7栋1单元",
                id: "1531551444205084843",
                y: "53.75%",
                x: 761,
            },
            {
                name: "1栋1单元",
                id: "1531551988692852737",
                y: "38.85%",
                x: 630,
            },
            {
                name: "1栋2单元",
                id: "1531552681042419712",
                y: "34.00%",
                x: 766,
            },
        ],
    },
    {
        id: "1518900027216666624",
        photo: ldzc,
        points: [
            {
                name: "3栋1单元",
                id: "1531553173575344129",
                y: "37.96%",
                x: 1207,
            },
            {
                name: "4栋1单元",
                id: "1531553246589788161",
                y: "54.93%",
                x: 1135,
            },
            {
                name: "4栋2单元",
                id: "1531553246593982467",
                y: "54.74%",
                x: 1285,
            },
        ],
    },
    {
        id: "1518900032069476352",
        photo: rljz,
        points: [
            {
                name: "1栋1单元",
                id: "1531553855418179585",
                y: "53.10%",
                x: 1075,
            },
            {
                name: "1栋2单元",
                id: "1531553855418179700",
                y: "49.18%",
                x: 1136,
            },
            {
                name: "1栋3单元",
                id: "1531553855418179815",
                y: "46.81%",
                x: 1063,
            },
            {
                name: "2栋1单元",
                id: "1531554044384157697",
                y: "45.80%",
                x: 972,
            },
            {
                name: "2栋2单元",
                id: "1531554044384157824",
                y: "47.35%",
                x: 842,
            },
            {
                name: "2栋3单元",
                id: "1531554044384157933",
                y: "55.38%",
                x: 719,
            },
        ],
    },
    {
        id: "1518900031398387712",
        photo: slgg,
        points: [
            {
                name: "1栋1单元",
                id: "1531554951490478081",
                y: "45.07%",
                x: 588,
            },
            {
                name: "2栋1单元",
                id: "1531555135259713537",
                y: "22.72%",
                x: 791,
            },
            {
                name: "2栋2单元",
                id: "1531555135263907883",
                y: "23.08%",
                x: 978,
            },
            {
                name: "3栋1单元",
                id: "1531555657802883073",
                y: "24.09%",
                x: 1217,
            },
        ],
    },
    {
        id: "1518900028961497088",
        photo: sdzx2,
        points: [
            {
                name: "1栋2单元",
                id: "1531556095008743425",
                y: "35.31%",
                x: 1119,
            },
            {
                name: "1栋1单元",
                id: "1531556413410942976",
                y: "32.66%",
                x: 930,
            },
            {
                name: "2栋1单元",
                id: "1544239778454872072",
                y: "51.09%",
                x: 655,
            },
            {
                name: "2栋2单元",
                id: "1544239778488426499",
                y: "56.48%",
                x: 849,
            },
        ],
    },
    {
        id: "1518900029930381312",
        photo: wgj,
        points: [
            {
                name: "18栋1单元",
                id: "1531560068969177089",
                y: "33.49%",
                x: 703,
            },
            {
                name: "18栋2单元",
                id: "1531560068973371674",
                y: "33.39%",
                x: 817,
            },
            {
                name: "16栋1单元",
                id: "1531560432749551617",
                y: "46.81%",
                x: 1169,
            },
            {
                name: "16栋2单元",
                id: "1531560432749551687",
                y: "53.65%",
                x: 1182,
            },
            {
                name: "12栋1单元",
                id: "1531560751936086017",
                y: "40.51%",
                x: 668,
            },
            {
                name: "12栋2单元",
                id: "1531560751936086178",
                y: "46.17%",
                x: 646,
            },
        ],
    },
    {
        id: "1518900031113175040",
        photo: xyh,
        points: [
            {
                name: "6栋1单元",
                id: "1531561363960537089",
                y: "46.81%",
                x: 669,
            },
            {
                name: "7栋1单元",
                id: "1531561409405820929",
                y: "53.10%",
                x: 810,
            },
            {
                name: "8栋1单元",
                id: "1531561460458889217",
                y: "32.76%",
                x: 1142,
            },
            {
                name: "9栋1单元",
                id: "1531561529211920385",
                y: "29.74%",
                x: 1005,
            },
            {
                name: "10栋1单元",
                id: "1531561565123551233",
                y: "26.19%",
                x: 898,
            },
        ],
    },
    {
        id: "1518900027506073600",
        photo: xczx,
        points: [
            {
                name: "2栋1单元",
                id: "1531563714834374657",
                y: "55.38%",
                x: 899,
            },
            {
                name: "3栋1单元",
                id: "1531563927976321025",
                y: "46.90%",
                x: 1117,
            },
        ],
    },
    {
        id: "1536883768673214464",
        photo: trhqsd,
        points: [
            {
                name: "1栋1单元",
                id: "1538804343922532352",
                y: "56.57%",
                x: 1029,
            },
            {
                name: "1栋2单元",
                id: "1538804343922532488",
                y: "55.38%",
                x: 903,
            },
            {
                name: "1栋3单元",
                id: "1544239778526175236",
                y: "50.09%",
                x: 714,
            },
        ],
    },
    {
        id: "1518900030546944000",
        photo: dygjzx2,
        points: [
            {
                name: "2栋1单元",
                id: "1529753061849079809",
                y: "33.76%",
                x: 729,
            },
            {
                name: "3栋1单元",
                id: "1529753127439605761",
                y: "33.76%",
                x: 1034,
            },
        ],
    },
    {
        id: "1564555251570483200",
        photo: frgjgc,
        points: [
            {
                name: "2栋1单元",
                id: "1564555421460766720",
                y: '60%',
                x: 1100,
            },
            {
                name: "2栋2单元",
                id: "1564555561084952576",
                y: '59%',
                x: 1290,
            },
        ],
    },
    {
        id: "1518900029636780032",
        photo: fngc,
        points: [
            {
                name: "T2栋1单元",
                id: "1529753127439605761",
                y: '54%',
                x: 950,
            },
            {
                name: "T3栋1单元 ",
                id: "1529753061849079809",
                y: '52%',
                x: 1100,
            },
        ],
    },
]

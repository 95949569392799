import React, { useState, forwardRef, useImperativeHandle } from 'react'
import classNames from 'classnames'
import {
    getReportList,
    getHouseUseList,
    getPopulationList,
    getAffairsList,
} from "../../../../apis/community"
import ListModal from '../../../../components/ListModal/ListModal'
import './index.fhd.scss'
const LeaseList = forwardRef((props, ref) => {
    const [dataList, setDataList] = useState([]);
    const [visible, setVisible] = useState(false);
    const [eventType, setEventType] = useState(0);
    const [communityId, setCommunityId] = useState(0);
    const hideWarningList = () => {
        setVisible(false);
    }
    useImperativeHandle(ref, () => ({
        // eventType: population 人口 house 房屋 dispute 纠纷
        open(communityId, eventType) {
            setEventType(eventType)
            setCommunityId(communityId)
            if (!visible) {
                setVisible(true)
            }
            eventType === 'dispute' && getReportListFun(communityId);
            eventType === 'house' && getHouseUseListFun(communityId, '');
            eventType === 'population' && getPopulationListFun(communityId, '');
            eventType === 'affairsStatistics' && getAffairsListFun(communityId, '');
        },
        close() {
            setVisible(false)
        }
    }))
    const getReportListFun = async (communityId) => {
        const { data } = await getReportList(communityId)
        setDataList(data)
    }
    const getHouseUseListFun = async (communityId, houseUseTypeQuery) => {
        const { data } = await getHouseUseList(communityId, houseUseTypeQuery)
        setDataList(data)
    }
    const getPopulationListFun = async (communityId, houseUseTypeQuery) => {
        const { data } = await getPopulationList({
            communityInfoId: communityId, houseUseType: houseUseTypeQuery
        })
        setDataList(data)
    }
    const getAffairsListFun = async (communityId, houseUseTypeQuery) => {
        const { data } = await getAffairsList(communityId)
        setDataList(data)
    }
    const openHouse = (houseId) => {
        setVisible(false)
        props?.openHouse(houseId, { house: 'baseInfo', population: 'live', dispute: 'report', affairsStatistics: 'report' }[eventType]);
    }
    // 切换分类
    const filterType = (type) => {
        eventType === 'house' && getHouseUseListFun(communityId, type)
        eventType === 'population' && getPopulationListFun(communityId, type)
    }
    const title = [
        {
            name: '楼栋单元',
            width: 300
        },
        {
            name: '房屋号',
            width: 300
        },
        {
            name: '房屋使用类型',
            width: 200,
            select: true
        },
    ]
    const title2 = [
        {
            name: '楼栋单元',
            width: 200
        },
        {
            name: '房屋号',
            width: 200
        },
        {
            name: '房屋使用类型',
            width: 200,
            select: true
        },
        {
            name: '居住人数',
            width: 200
        },
    ]
    const title3 = [
        {
            name: '楼栋单元',
            width: 100
        },
        {
            name: '房屋号',
            width: 100
        },
        {
            name: '上报数',
            width: 100,
        },
        {
            name: '待处理',
            width: 100,
        },
        {
            name: '处理中',
            width: 100,
        },
        {
            name: '已完结',
            width: 100,
        },
        {
            name: '处理时效',
            width: 100,
        },
        {
            name: '满意度',
            width: 100,
        },
    ]
    const selectList = [
        {
            label: '整租',
            type: '整租'
        },
        {
            label: '合租',
            type: '合租'
        },
        {
            label: '闲置',
            type: '闲置'
        },
        {
            label: '未知',
            type: '未知'
        },
    ]
    const selectList2 = [
        {
            label: '整租',
            type: 402
        },
        {
            label: '合租',
            type: 401
        },
        {
            label: '闲置',
            type: 103
        },
        {
            label: '未知',
            type: 0
        },
    ]
    return (
        visible ?
            <div className='warningList h-[950px]'>
                <ListModal
                    width={900}
                    height={'950px'}
                    title={{ 'population': title2, 'house': title, 'dispute': title3, 'affairsStatistics': title3 }[eventType]}
                    dateChoose={false}
                    closeModal={hideWarningList}
                    selectList={{ 'population': selectList2, 'house': selectList }[eventType]}
                    filterType={filterType}
                >
                    <div className={classNames('flex fd-c')} >


                        <div className={classNames('list h-[902px]')} style={{ paddingTop: '0' }}>
                            {
                                dataList?.map(item => {
                                    return (
                                        eventType === 'dispute' || eventType === 'affairsStatistics' ? <div className={classNames('item flex ai-c cursor-pointer')} onClick={() => openHouse(item?.id)}>
                                            <div className={classNames('fs-14 w-25 pl-4 py-3')}>{item.buildingName}</div>
                                            <div className={classNames('fs-14 w-25 pl-4 py-3')}>{item.name}</div>
                                            <div className={classNames('fs-14 w-25 pl-4 py-3')}>{item.total}</div>
                                            <div className={classNames('fs-14 w-25 pl-4 py-3')}>{item.todo}</div>
                                            <div className={classNames('fs-14 w-25 pl-4 py-3')}>{item.doing}</div>
                                            <div className={classNames('fs-14 w-25 pl-4 py-3')}>{item.done}</div>
                                            <div className={classNames('fs-14 w-25 pl-4 py-3')}>{item.hours}</div>
                                            <div className={classNames('fs-14 w-25 pl-4 py-3')}>{item.score}</div>
                                        </div> :
                                            eventType === 'house' ? <div className={classNames('item flex ai-c cursor-pointer')} onClick={() => openHouse(item?.houseId)}>
                                                <div className={classNames('fs-14 w-75 pl-4 py-3')}>{item.buildingUnitName}</div>
                                                <div className={classNames('fs-14 w-75 pl-4 py-3')}>{item.floorHouseName}</div>
                                                <div className={classNames('fs-14 w-50 pl-4 py-3')}>{item.houseUseTypeMemo}</div>
                                            </div> :
                                                eventType === 'population' ? <div className={classNames('item flex ai-c cursor-pointer')} onClick={() => openHouse(item?.houseId)}>
                                                    <div className={classNames('fs-14 w-50 pl-4 py-3')}>{item.buildingUnitName}</div>
                                                    <div className={classNames('fs-14 w-50 pl-4 py-3')}>{item.floorHouseName}</div>
                                                    <div className={classNames('fs-14 w-50 pl-4 py-3')}>{item.houseUseTypeDesc}</div>
                                                    <div className={classNames('fs-14 w-50 pl-4 py-3')}>{item.populationCount}</div>
                                                </div> : ''
                                    )
                                })
                            }
                        </div>
                    </div>
                </ListModal>
            </div>
            : ''
    )
})

export default LeaseList

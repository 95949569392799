import { forwardRef, useImperativeHandle, useState } from "react"
import "./logsModal.scss"
import { leaseHistory } from "apis/home"
import classNames from "classnames"
import { useMemo } from "react"
import { reportHistory, otherHistory } from "apis/home"
import { Image } from 'antd'
import ongoing from '../../community/imgs/ongoingIcon.png'
import okIcon from '../../community/imgs/okIcon.png'
import arrowup from '../../community/imgs/icon_arrowup.png'
import arrowdown from '../../community/imgs/icon_icon_arrowdown.png'

const LogsModal = forwardRef((props, ref) => {

    const [show, setShow] = useState(false)
    // 打开的是租赁纠纷还是扰民其他弹窗
    const [chooseTab, setChooseTab] = useState()

    // 租赁纠纷详情
    const [leaseDetail, setLeaseDetail] = useState()
    // 扰民投诉 和 其他报事
    const [otherDetail, setOtherDetail] = useState()

    // 报事记录
    const [logs, setLogs] = useState()
    const [showMoreLogs, setShowMoreLogs] = useState(false)

    useImperativeHandle(ref, () => ({
        open(id, chooseTab) {
            setChooseTab(chooseTab)
            setLeaseDetail()
            setOtherDetail()
            setShow(true)
            if (chooseTab === 'lease') {
                getLeaseDetail(id)
            } else {
                getOtherDetail(id)
            }
            getHistory(id)
        },
        close() {
            setShow(false)
        }
    }))

    // 获取租赁纠纷详情
    const getLeaseDetail = async (id) => {
        const { data } = await leaseHistory(id)
        setLeaseDetail(data)
    }

    // 租赁纠纷提报时间详情
    const leaseMemo = useMemo(() => {
        return [
            {
                label: '提报人：',
                value: leaseDetail?.reportName || '-'
            },
            {
                label: '提报时间：',
                value: leaseDetail?.reportTime || '-'
            },
            {
                label: '提报人手机号：',
                value: leaseDetail?.reportPhone || '-'
            },
            {
                label: '提报人绑定小区：',
                value: leaseDetail?.userChooseVillageName || '-'
            },
            {
                label: '提报人绑定房屋：',
                value: leaseDetail?.userChooseHouseName || '-'
            },
            {
                label: '投诉类型：',
                value: leaseDetail?.disputeType === 1 ? '合同纠纷' : leaseDetail?.disputeType === 2 ? '服务瑕疵' : leaseDetail?.disputeType === 3 ? '违规操作' : leaseDetail?.disputeType === 4 ? '超出范围' : '-'
            },
            {
                label: '被投诉对象：',
                value: leaseDetail?.compliantName || '-'
            },
            {
                label: 'CD号：',
                value: leaseDetail?.cdNumber || '-'
            },
            {
                label: '业务类型：',
                value: leaseDetail?.businessType === 1 ? '经纪' : leaseDetail?.businessType === 2 ? '租赁' : leaseDetail?.businessType === 3 ? '其他' : '-'
            },
            {
                label: '投诉内容：',
                value: leaseDetail?.disputeContent || '-',
                width: 'full'
            },
            {
                label: '证据资料：',
                value: leaseDetail?.imgs,
                img: true,
                width: 'full'
            },
        ]
    }, [leaseDetail])


    // 获取扰民投诉 和 其他报事
    const otherMemo = useMemo(() => {
        return [
            {
                label: '提报人：',
                value: otherDetail?.reportName || '-'
            },
            {
                label: '提报时间：',
                value: otherDetail?.reportTime || '-'
            },
            {
                label: '提报人手机号：',
                value: otherDetail?.reportPhone || '-'
            },
            {
                label: '提报人绑定小区：',
                value: otherDetail?.userChooseVillageName || '-'
            },
            {
                label: '提报人绑定房屋：',
                value: otherDetail?.userChooseHouseName || '-'
            },
            {
                label: '诉求内容：',
                value: otherDetail?.appealContent || '-',
                width: 'full'
            },
            {
                label: '上传图片：',
                value: otherDetail?.imgs,
                img: true,
                width: 'full'
            },
        ]
    }, [otherDetail])
    const getOtherDetail = async (id) => {
        const { data } = await otherHistory(id)
        setOtherDetail(data)
    }

    // 报事记录
    const getHistory = async (id) => {
        const { data } = await reportHistory(id)
        setLogs(data)
    }


    // 获取处理意见
    const statusMap = {
        1: '处理完成',
        2: '无效反馈',
        3: '取消上报',
        4: '正在处理',
        5: '通过待派发任务',
        6: '通过立即派发任务'
    }
    // 评价
    const score = {
        1: '不满意',
        2: '一般',
        3: '满意',
        0: '未评价',
    }
    return (
        <>
            {show ? (
                <div className="log-container flex flex-col">
                    <div className='title'>
                        <div>
                            <span>{chooseTab === 'lease' ? '租赁纠纷' : chooseTab === 'comp' ? '扰民投诉' : '其他诉求'}</span>
                            {chooseTab === 'lease' ? (
                                <span className={classNames('tag', leaseDetail?.reportStatus === 0 ? 'tag1' : leaseDetail?.reportStatus === 1 ? 'tag2' : leaseDetail?.reportStatus === 4 ? 'tag3' : 'tag4')}>
                                    {leaseDetail?.reportStatus === 0 ? '待处理' : leaseDetail?.reportStatus === 1 ? '已处理' : leaseDetail?.reportStatus === 2 ? '无效反馈' : leaseDetail?.reportStatus === 3 ? '已取消' : '处理中'}
                                </span>
                            ) : (
                                <span className={classNames('tag', otherDetail?.reportStatus === 0 ? 'tag1' : otherDetail?.reportStatus === 1 ? 'tag2' : 'tag3')}>
                                    {otherDetail?.reportStatus === 0 ? '待处理' : otherDetail?.reportStatus === 1 ? '已处理' : otherDetail?.reportStatus === 2 ? '无效反馈' : otherDetail?.reportStatus === 3 ? '已取消' : '处理中'}
                                </span>
                            )}
                        </div>
                        <img onClick={() => setShow(false)} src={require("../../../assets/home/icon_close.png")} alt="" />
                    </div>
                    <div className="flex-1 overflow-y-auto hideScall">
                        {/* 提报时间 */}
                        <div className="report-time">
                            <div className='itemImg'>
                                <img src={require('../../../assets/home/icon_dian.png')} alt="" />
                                <div className={classNames('line', logs?.length > 0 ? '' : 'hidden')}></div>
                            </div>
                            {chooseTab === 'lease' ? <div className="itemTitle">
                                <div className='logtitle'>提报内容</div>
                                <div className='itemCenter text-[16px]'>
                                    {leaseMemo.map(s => {
                                        return (
                                            <div style={{ width: s.width === 'full' ? '100%' : '380px' }} key={s.label}>
                                                <div className="item-label leading-6">{s.label}</div>
                                                {!s.img ? (
                                                    <div className="flex-1 leading-6">{s.value}</div>
                                                ) : (
                                                    <div className="img gap-4 flex-1">
                                                        <Image.PreviewGroup
                                                            preview={{
                                                                onChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
                                                            }}
                                                        >
                                                            {
                                                                s?.value ? s?.value?.split(',').map(s => <Image style={{ 'object-fit': 'cover' }} src={s} key={s} />)
                                                                    : '-'
                                                            }
                                                        </Image.PreviewGroup>

                                                    </div>
                                                )}
                                            </div>
                                        )
                                    })}

                                </div>
                                <div style={{ height: '24px' }}></div>
                            </div> : (
                                <div className="itemTitle">
                                    <div className='logtitle'>提报内容</div>
                                    <div className='itemCenter text-[16px]'>
                                        {otherMemo.map(s => {
                                            return (
                                                <div style={{ width: s.width === 'full' ? '100%' : '380px' }} key={s.label}>
                                                    <div className="item-label leading-6">{s.label}</div>
                                                    {!s.img ? (
                                                        <div className="flex-1 leading-6">{s.value}</div>
                                                    ) : (
                                                        <div className="img gap-4 flex-1">
                                                            <Image.PreviewGroup
                                                                preview={{
                                                                    onChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
                                                                }}
                                                            >
                                                                {
                                                                    s?.value ? s?.value?.split(',').map(s => <Image style={{ 'object-fit': 'cover' }} src={s} key={s} />)
                                                                        : '-'
                                                                }
                                                            </Image.PreviewGroup>

                                                        </div>
                                                    )}
                                                </div>
                                            )
                                        })}

                                    </div>
                                    <div style={{ height: '24px' }}></div>
                                </div>
                            )}
                        </div>
                        <div className={classNames(logs?.length > 0 ? '' : 'hidden')}>
                            <div className="report-time">
                                <div className='itemImg'>
                                    <img src={require('../../../assets/home/icon_dian.png')} alt="" />
                                    <div className={classNames('line', otherDetail?.score || leaseDetail?.score ? '' : 'hidden')}></div>
                                </div>
                                <div className="itemTitle">
                                    <div className='logtitle'>诉求处理</div>
                                    <div className="logsmsg text-[16px]">
                                        {logs?.map((s, i) => {
                                            return (
                                                <div className={classNames('logs-content flex flex-wrap', !showMoreLogs && i >= 2 ? 'hidden' : '')} key={i}>
                                                    <div className="w-[50%] leading-6">
                                                        <span className="item-label">处理意见：</span>
                                                        <span>{statusMap[s.reportStatus] || '-'}
                                                            <img className="inline-block w-4 h-4 ml-2" src={s.reportStatus === 1 ? okIcon : ongoing} alt="" />
                                                        </span>
                                                    </div>
                                                    <div className="w-[50%] leading-6">
                                                        <span className="item-label">处理时间：</span>
                                                        <span>{s.updateTime}</span>
                                                    </div>
                                                    <div className="w-[50%] leading-6">
                                                        <span className="item-label">处理人：</span>
                                                        <span>{s.auditName}</span>
                                                    </div>
                                                    <div className="w-full leading-6 flex">
                                                        <span className="item-label">反馈描述：</span>
                                                        <span className="flex-1">{s.feedback}</span>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                        {
                                            logs?.length > 2 ? <div onClick={() => setShowMoreLogs(!showMoreLogs)} className="showMoreLogs cursor-pointer pt-4 flex items-center justify-center">
                                                {showMoreLogs ? '收起' : '展开'}<img className="w-4 h-4 ml-1" src={showMoreLogs ? arrowup : arrowdown} alt="" />
                                            </div> : ''

                                        }
                                    </div>
                                    <div style={{ height: '24px' }}></div>
                                </div>
                            </div>
                        </div>
                        {leaseDetail?.score ? (
                            <div className="report-time">
                                <div className='itemImg'>
                                    <img src={require('../../../assets/home/icon_dian.png')} alt="" />
                                    {/* <div className='line'></div> */}
                                </div>
                                <div className="itemTitle text-[16px]">
                                    <div className='logtitle'>评价</div>
                                    <div className='logs-content flex'>
                                        <div className="flex-1">
                                            <span className="item-label">评价：</span>
                                            <span>{score[leaseDetail?.score] || '-'}</span>
                                        </div>
                                        <div className="flex-1">
                                            <span className="item-label">提报人评价时间：</span>
                                            <span>{leaseDetail?.scoreTime || '-'}</span>
                                        </div>
                                    </div>
                                    <div style={{ height: '24px' }}></div>
                                </div>
                            </div>
                        ) : null}
                        {otherDetail?.score ? (
                            <div className="report-time text-[16px]">
                                <div className='itemImg'>
                                    <img src={require('../../../assets/home/icon_dian.png')} alt="" />
                                    {/* <div className='line'></div> */}
                                </div>
                                <div className="itemTitle">
                                    <div className='logtitle'>评价</div>
                                    <div>
                                        <div className='logs-content flex'>
                                            <div className="flex-1">
                                                <span className="item-label">评价：</span>
                                                <span>{score[otherDetail?.score] || '-'}</span>
                                            </div>
                                            <div className="flex-1">
                                                <span className="item-label">提报人评价时间：</span>
                                                <span>{otherDetail?.scoreTime || '-'}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ height: '24px' }}></div>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            ) : null}
        </>
    )
})
export default LogsModal
import { useMemo } from "react"
// import { operationData } from "../../../apis/community"
import { platformInfo } from "apis/home"
import { useData } from "../../../hooks"
import { formatValue } from "../../../utils"
import { useContext } from "react"
import appContext from "App.context"

const PlatformData = () => {
    const {communeId} = useContext(appContext)
    const [data] = useData(platformInfo, communeId)

    const dataList = useMemo(
        () => [
            {
                label: "平台注册用户",
                count: data?.registerUsers,
                unit: "人",
            },
            {
                label: "居住信息申报",
                count: data?.flowPeople,
                unit: "条",
            },
            {
                label: "解答居民问题",
                count: data?.problemSolved,
                unit: "个",
            },
            {
                label: "租赁信息推介",
                count: data?.impressions,
                unit: "次",
            },
            {
                label: "租赁客户对接",
                count: data?.apartmentLease,
                unit: "人",
            },
        ],
        [data]
    )

    return (
        <div className="pl-4 pr-2 pt-2 flex flex-wrap justify-between">
            {dataList?.map((s) => {
                return (
                    <div key={s.label} className="flex ai-c justify-between w-[208px] leading-[24px]">
                        <div
                            className="fs-14 mr-[10px]"
                            style={{
                                width: "114px",
                            }}
                        >
                            {s.label}
                        </div>
                        <div className="flex ai-c">
                            <div
                                className="flex ai-c"
                                style={{ textAlign: "left" }}
                            >
                                <span className="fs-20 mr-1 t-Md t-color fw-b">{formatValue(s?.count)}</span>
                                <span className="fs-14 mr-1 t-nf">{s?.unit}</span>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default PlatformData

import { useEffect, useState, useContext } from "react"
import appContext from "../App.context"
const useData = (api, params) => {
    const [data, setData] = useState()
    const { updateTime } = useContext(appContext)

    const fetchData = async () => {
        const res = await api(params)
        setData(res?.data)
    }

    useEffect(() => {
        fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [api, updateTime])

    return [data]
}

export default useData

// Public health facilities in sub Saharan Africa

// Data: https://springernature.figshare.com/articles/Public_health_facilities_in_sub_Saharan_Africa/7725374/1

import { useCallback, useContext, useEffect, useRef, useState } from "react"
import { getCommunitiesInfo, getSmallCommunities } from "apis/map"
import Wrapper from "./Wrapper/Wrapper"
import communityPNG from "assets/map/icon_juzhu@2x.png"
import gridPNG from "assets/map/icon_juzhu@2x(1).png"
import buildingsPNG from "assets/map/icon_juzhu备份@2x.png"
import dropdownPNG from "assets/map/矩形@2x.png"
import mapSvg from "assets/map/4.svg"
import "./Map.scss"
import classNames from "classnames"
import { useData } from "hooks"
import { useNavigate } from "react-router-dom"
import appContext from "App.context"
import { LarkMap, Marker } from "@antv/larkmap"

const hasWarnEvent = (data) => {
    return !!(data?.populationWarnAmount || data?.eventWarnAmount || data?.securityWarnAmount)
}

const Map = () => {
    const larMapRef = useRef()
    const navigate = useNavigate()
    const { communeId } = useContext(appContext)

    // 社区数据
    const [smallCommunities] = useData(getSmallCommunities, communeId)

    // 小区信息 经纬度
    const [mapOringinData, setMapOringinData] = useState([])
    const fetchCommunutiesInfo = useCallback(async () => {
        const { data } = await getCommunitiesInfo(communeId)
        setMapOringinData(data)
    }, [communeId])

    // 创建地图场景容器
    const config = {
        mapType: "Gaode",
        mapOptions: {
            style: "amap://styles/bde9e0e7e4ef8b0b73933f55cd2c46f1?isPublic=true",
            center: [104.06437, 30.55769],
            pitch: 40,
            zoom: 15.3,
            token: "9367649176dddeb920da9ac4e03d8f4c",
        },
        logoVisible: false,
    }
    useEffect(() => {
        let center = [104.06437, 30.55769]
        if (mapOringinData?.length) {
            center = [mapOringinData[0]?.longitude, mapOringinData[0]?.latitude]
        }
        setTimeout(() => {
            larMapRef.current.getScene()?.setCenter(center)
        }, 200)
    }, [mapOringinData])

    // 小区下拉
    // const [selectedKey, setSelectedKey] = useState()
    const [hoverKey, setHoverKey] = useState()
    const [optionsIsShow, setOptionsIsShow] = useState(false)

    const selectRef = useRef(null)

    const showOptions = () => {
        setOptionsIsShow(!optionsIsShow)
    }

    function clickCallback(event) {
        if (selectRef.current.contains(event.target)) {
            return
        }
        setOptionsIsShow(false)
    }

    useEffect(() => {
        if (optionsIsShow) {
            document.addEventListener("click", clickCallback, false)
            return () => {
                document.removeEventListener("click", clickCallback, false)
            }
        }
    }, [optionsIsShow])

    return (
        <div
            className="map height-full"
            id="map-wrapper"
            style={{ position: "relative" }}
        >
            <LarkMap {...config} ref={larMapRef} onSceneLoaded={fetchCommunutiesInfo} className="lark-map h-full">
                {
                    mapOringinData?.map(s => {
                        if (hasWarnEvent(s)) {
                            return (
                                <Marker
                                    lngLat={{ lng: s.longitude, lat: s.latitude }}
                                    anchor="center"
                                    key={s.id}
                                >
                                    <div className="marker-popup flex flex-col items-center">
                                        <div
                                            className="marker-popup-box"
                                            onClick={() => {
                                                navigate("/community?communityInfoId=" + s.id + "&from=home")
                                            }}
                                        >
                                            <div className="text-[16px]">
                                                {s.communityName}
                                                <img src="https://image.uoko.com/community-big-screen/cheng-yun-map-title.svg" alt="" />
                                            </div>
                                            <div className="px-[8px] pb-1">
                                                {
                                                    s.populationWarnAmount ? (
                                                        <div className="flex items-center">
                                                            <div className="flex items-center">
                                                                <img className="w-[24px] h-[24px] mr-[4px]" src="https://image.uoko.com/community-big-screen/people.svg" alt="" />
                                                                <span className="text-[16px]">人口提示</span>
                                                            </div>
                                                            <div className="color-text">{s.populationWarnAmount}</div>
                                                        </div>
                                                    ) : null
                                                }
                                                {
                                                    s.eventWarnAmount ? (
                                                        <div className="flex items-center">
                                                            <div className="flex items-center">
                                                                <img className="w-[24px] h-[24px] mr-[4px]" src="https://image.uoko.com/community-big-screen/event.svg" alt="" />
                                                                <span className="text-[16px]">事件提示</span>
                                                            </div>
                                                            <div className="color-text">{s.eventWarnAmount}</div>
                                                        </div>
                                                    ) : null
                                                }
                                                {
                                                    s.securityWarnAmount ? (
                                                        <div className="flex items-center">
                                                            <div className="flex items-center">
                                                                <img className="w-[24px] h-[24px] mr-[4px]" src="https://image.uoko.com/community-big-screen/safe.svg" alt="" />
                                                                <span className="text-[16px]">安全提示</span>
                                                            </div>
                                                            <div className="color-text">{s.securityWarnAmount}</div>
                                                        </div>
                                                    ) : null
                                                }
                                            </div>
                                        </div>
                                        <img src={mapSvg} width="64" alt="" />
                                    </div>
                                </Marker>
                            )
                        } else {
                            return (
                                <Marker
                                    lngLat={{ lng: s.longitude, lat: s.latitude }}
                                    anchor="center"
                                    key={s.id}
                                >
                                    <div className="marker flex flex-col items-center">
                                        <div
                                            className="h-10 px-2 pt-2"
                                            onClick={() => {
                                                console.log(123)
                                            }}
                                        >
                                            {s.communityName}
                                        </div>
                                        <img src="https://image.uoko.com/community-big-screen/point-no-event.svg" width="24" alt="" />
                                    </div>
                                </Marker>
                            )
                        }
                    })
                }
            </LarkMap>
            {/* 操作栏 */}
            <div className="operations">
                <Wrapper className="item mr-4">
                    <div className="item-content">
                        <img className="icon" src={communityPNG} alt="" />
                        <span>社区</span>
                        <span className="number">{smallCommunities?.communityBaseInfoNum}</span>
                    </div>
                </Wrapper>
                <Wrapper className="item mr-4">
                    <div className="item-content">
                        <img className="icon" src={gridPNG} alt="" />
                        <span>网格</span>
                        <span className="number">{smallCommunities?.gridNum}</span>
                    </div>
                </Wrapper>
                <Wrapper className="item trigger">
                    <div ref={selectRef}>
                        <div className="item-content" onClick={showOptions}>
                            <img className="icon" src={buildingsPNG} alt="" />
                            <span>小区</span>
                            <span className="number">{smallCommunities?.communityNum}</span>
                            <img
                                className={classNames(["dropdown-icon", optionsIsShow && "opend"])}
                                src={dropdownPNG}
                                alt=""
                            />
                        </div>
                        {/* 小区下拉options */}
                        {optionsIsShow && (
                            <div className="options">
                                {smallCommunities?.communityInfoDTOS?.map((item, i) => (
                                    <div
                                        onMouseEnter={() => {
                                            setHoverKey(item)
                                        }}
                                        onClick={() => {
                                            setOptionsIsShow(false)
                                            navigate(
                                                "/community?communityInfoId=" +
                                                item.id +
                                                "&from=home"
                                            )
                                        }}
                                        key={i}
                                        className={classNames([
                                            "option",
                                            item === hoverKey && "hover",
                                        ])}
                                    >
                                        {item.communityName}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </Wrapper>
            </div>
        </div>
    )
}

export default Map

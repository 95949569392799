/*
 * @Author: 刘芳
 * @Date: 2022-07-30 14:15:04
 * @LastEditTime: 2022-11-16 10:48:02
 * @FilePath: \community-big-screen\src\views\community\Community.fhd.jsx
 */
import BuildInfo from './components/buildInfo/index.fhd'
import CommunityInfo from './components/communityInfo/index.fhd'
import './index.fhd.scss'
import BackGround from './components/BackGround/BackGround'

import { useNavigate, useSearchParams } from 'react-router-dom'
import appContext from '../../App.context'
import { useContext, useEffect, useState, useRef } from 'react'
import { useDate } from '../../hooks'

const Community = () => {
    const navigate = useNavigate()
    const [currentDate, currentTime] = useDate()
    const myuseRef = useRef()
    const { updateTime } = useContext(appContext)
    const [params] = useSearchParams()
    const communityInfoId = params.get('communityInfoId')
    const buildingUnitId = params.get('buildingUnitId')
    const houseId = params.get('houseId')

    const [unitId, setUnitId] = useState('')
    const [current, setCurrent] = useState({})
    const [houseClearRef, sethouseClearRef] = useState({})
    const onSelectUnit = val => {
        setUnitId(val)
    }

    const getCurrent = ({ current }, hideWarningListsFun, houseClearRef) => {
        setCurrent(current)
        sethouseClearRef(houseClearRef)
        myuseRef.current = hideWarningListsFun
    }
    const [rightCur, setRightCur] = useState(null)
    const getRightModal = ({ current }, onGetUnit) => {
        setRightCur(current)
        myuseRef.onGetUnit = onGetUnit
    }
    //点击了显示预警列表
    const showWarningList = () => {
        rightCur?.close()
    }
    //右侧详情
    const showRight = () => {
        current?.close && current?.close()
        houseClearRef?.current?.close && houseClearRef?.current?.close()
    }
    return (
        <div className="community">
            <BackGround
                onSelect={onSelectUnit}
                communityInfoId={communityInfoId}
            />
            <div className="left w-[774px] md:w-[1016px]">
                <CommunityInfo
                    communityId={communityInfoId}
                    getCurrent={getCurrent}
                    houseId={houseId}
                    showWarningList={showWarningList}
                    rightCur={rightCur}
                    rightonGetUnit={myuseRef.onGetUnit}
                ></CommunityInfo>
            </div>
            <div className="right">
                <BuildInfo
                    getCurrent={getRightModal}
                    showRight={showRight}
                    communityId={communityInfoId}
                    unitId={buildingUnitId || unitId}
                    houseId={houseId}
                    setShowWarningLists={myuseRef.current}
                ></BuildInfo>
            </div>
        </div>
    )
}

export default Community

import React, { useState, forwardRef, useImperativeHandle } from 'react'
import { getIdRecords } from '../../../../apis/community'
import Close from '../../imgs/close.png'
import Dot from '../../imgs/icon_dian.png'
import './index.fhd.scss'
const CheckRecord = forwardRef((props, ref) => {
    const [visible, setVisible] = useState(false)
    const [record, setRecord] = useState([])
    const hide = () => {
        setVisible(false)
    }
    useImperativeHandle(ref, () => ({
        open(id) {
            getInfo(id)
            if (!visible) {
                setVisible(true)
            }
        },
        close() {
            setVisible(false)
        }
    }))
    const getInfo = id => {
        const obj = {
            dto: {
                houseId: id
            },
            limit: 30,
            page: 1
        }
        getIdRecords(obj).then(res => {
            setRecord(res.data)
        })
    }
    const getUncheckNams = list => {
        let res = []
        for (let item of list) {
            res.push(item.name)
        }
        return res.length > 0 ? res.join('、') : ''
    }
    return visible ? (
        <div className="resident-info">
            <img onClick={hide} src={Close} alt="err" className="close-img" />
            <div className="report-title">
                <span className="title-text">近30天登记记录</span>
            </div>
            <div className="modal-content">
                {record?.map((item, ix) => (
                    <div className="record-item" key={ix}>
                        {ix === record?.length - 1 ? null : (
                            <div class="item-tail"></div>
                        )}
                        <div className="record-time">
                            <img src={Dot} alt="err" className="dot-img"></img>
                            <span className="time">
                                {item.dateMemo + ' | ' + item.dayMemo}
                            </span>
                            <span className="sumarys">
                                <span>
                                    <sapn
                                        style={{
                                            color: '#5CFFB5'
                                        }}
                                    >
                                        {item.register}
                                    </sapn>
                                    人已登记
                                </span>
                                <span>
                                    <sapn
                                        style={{
                                            color: '#FFC97A',
                                            marginLeft: 8
                                        }}
                                    >
                                        {item.unRegister}
                                    </sapn>
                                    人未登记
                                </span>
                            </span>
                        </div>
                        <div className="record-content">
                            <div className="record-row">
                                未登记：
                                {getUncheckNams(item.unRegisterItems)}
                            </div>
                            {item?.registerItems?.map((reg, index) => (
                                <div key={index} className="record-row">
                                    {reg.registerTime}：
                                    {getUncheckNams(reg.names)}
                                    <span className="checkUser">
                                        录入人:
                                        {reg.employeeName +
                                            '  ' +
                                            reg.employeePhone}
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    ) : null
})

export default CheckRecord

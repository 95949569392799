import {
    useState,
    useImperativeHandle,
    forwardRef,
    // useEffect,
    useRef
} from 'react'
import Close from '../../imgs/close.png'
import Drop from '../../imgs/xiala.png'
import Right from '../../imgs/icon_jiantou.png'
import Left from '../../imgs/left.png'
import RightIcon from '../../imgs/right.png'
// import Finish from '../../imgs/finish.png'
// import Alert from '../../imgs/alert.png'
// import Ok from '../../imgs/ok.png'
// import Dot from '../../imgs/icon_dian.png'
// import { Image } from 'antd'
import classNames from 'classnames'
import {
    getReportTask,
    getHouseReport,
    houseLabel,
    // getResident,
    getReportSumary,
    getRemark,
    getSafetyList,
    getWarnTask,
    getHouseInfos,
    getHouseSecurity,
} from '../../../../apis/community'
import ReportInfo from '../reportInfo'
import VirusInfo from '../virusInfo'
// import { useSearchParams } from 'react-router-dom'
import RecordPeople from '../residentPeople/index.fhd'
import MonthRecord from '../monthRecord/index.fhd'
import ResidentRecord from '../resident/index.fhd'
import ChangeRecord from '../changeRecord/index.fhd'
import WarnInfo from '../warnInfo/index.fhd'
import LogsModal from '../../../home/StatisticsList/LogsModal'
import './index.fhd.scss'
const HouseInfo = forwardRef((props, ref) => {
    // const [params] = useSearchParams()
    // const communityInfoId = params.get('communityInfoId')
    const [visible, setVisible] = useState(false)
    const [position, setPosition] = useState('')
    const [checkWork, setCheckWork] = useState(false)
    const [checkSafe, setCheckSafe] = useState(false)
    const [checkReport, setCheckReport] = useState(false)
    const scrollRef = useRef()
    const [report, setReport] = useState([])
    //房源详情
    const [live, setLive] = useState({})

    const [safe, setSafe] = useState([])
    const [note, setNote] = useState([])
    const [houseSecurity, setHouseSecurity] = useState([])
    const [reportTask, setReportTask] = useState([])
    const [reportCount, setReportCount] = useState(0)
    const [reportSumary, setReportSumary] = useState({})
    const [info, setInfo] = useState({})

    const [taskType, setTaskType] = useState(3)
    const [warn, setWarn] = useState([])
    // const houseRef = useRef()
    const virusRef = useRef()
    const [houseId, setHouseId] = useState('')

    const residentRef = useRef()
    const peopleRecordRef = useRef()
    const checkRef = useRef()
    const changeRef = useRef()
    const warnRef = useRef()
    const openWarnLog = item => {
        warnRef.current.open(item)
    }
    const [label, setLabel] = useState('')
    useImperativeHandle(ref, () => ({
        open(houseId, anchor, houseType = '') {
            const map = {
                402: '整租',
                401: '合租',
                103: '闲置'
            }
            const txt = map[houseType] || ''
            setLabel(txt ? ' | ' + txt : '')
            setHouseId(houseId)
            initDetail(houseId, anchor)
        },
        close() {
            setVisible(false)
        }
    }))

    const initDetail = async (id, anchor) => {
        if (!visible) {
            setVisible(true)
        }
        const param = {
            dto: {
                houseId: id
            },
            limit: 10,
            page: 1
        }
        const safeParam = {
            dto: {
                houseInfoId: id
            },
            limit: 10,
            page: 1
        }
        const [p1, p2, p3, p4, p5, p6, p7, p8, p9] = await Promise.all([
            houseLabel(id),
            getWarnTask(id),
            getReportTask(param),
            getHouseInfos(id),
            getReportSumary({ houseId: id }),
            getHouseReport(param),
            getSafetyList(safeParam),
            getRemark(id),
            getHouseSecurity(id)
        ])

        // getPeopleTask(param).then(res => {
        //     const data = res.data
        //     setCheckTask(data.list)
        //     setCheckCount(data.totalCount)
        // })
        setInfo(p1.data)
        setWarn(p2.data)
        setReportTask(p3.data.list)
        setReportCount(p3.data.totalCount)
        setLive(p4.data)
        setReportSumary(p5.data)
        setReport(p6.data.list)
        setSafe(p7.data.list)
        setNote(p8.data)
        setHouseSecurity(p9.data)
        if (anchor) {
            scrollToAnchor(anchor)
        } else {
            setPosition('')
            const anchorElement = document.getElementById('todo')
            anchorElement.scrollIntoView({
                block: 'start',
                behavior: 'smooth'
            })
        }
    }
    const [reportStatus, setReportStatus] = useState('')
    const filterStatus = status => {
        setReportStatus(status)
        const param = {
            dto: {
                houseId: houseId,
                reportStatus: status
            },
            limit: 10,
            page: 1
        }
        getHouseReport(param).then(res => {
            const data = res.data
            setReport(data.list)
        })
    }
    const changeType = type => {
        setTaskType(type)
    }
    const lableClass = type => {
        if ([0, 1].includes(type)) return 'label1'
        if (type === 2) return 'label2'
        if (type === 3) return 'label3'
        if (type === 4) return 'label4'
    }
    //0 待处理 1 已处理 2 无效反馈 3 已取消 4 处理中 空 全部
    const transStatus = type => {
        if (type === '待处理') return 'status1'
        if (type === '已处理') return 'status2'
        if (type === '无效反馈') return 'status3'
        if (type === '已取消') return 'status4'
        return 'status1'
    }
    const openDetail = row => {
        scrollRef.current.open(row.baseId, row.reportTypeCode)
    }
    const scrollToAnchor = anchorName => {
        if (anchorName) {
            setPosition(anchorName)
            // 找到锚点
            // 如果对应id的锚点存在，就跳转到锚点
            const anchorElement = document.getElementById(anchorName)
            if (anchorElement) {
                anchorElement.scrollIntoView({
                    block: 'start',
                    behavior: 'smooth'
                })
            }
        }
    }
    const checkMoreWork = () => {
        setCheckWork(!checkWork)
    }
    const checkMoreSafe = () => {
        setCheckSafe(!checkSafe)
    }
    const checkMoreReport = () => {
        setCheckReport(!checkReport)
    }
    //产权信息辅助库
    const [propertyTab, setPropertyTab] = useState(1)
    const switchPropertyTab = ix => {
        setPropertyTab(ix)
    }
    function formatValue(value) {
        const rules = ['', null, undefined]
        if (rules.includes(value)) {
            return '-'
        }
        return value
    }
    //居住信息辅助库
    const [baseTab, setBaseTab] = useState(1)
    const switchBaseTab = ix => {
        setBaseTab(ix)
    }
    //居住信息辅助库
    const [liveTab, setLiveTab] = useState(1)
    const switchLiveTab = ix => {
        setLiveTab(ix)
    }
    //出租信息辅助库
    const [rentTab, setRentTab] = useState(1)
    const switchRentTab = ix => {
        setRentTab(ix)
    }
    //承租信息辅助库
    const [chengzuTab, setChengzuTab] = useState(1)
    const switchChengzuTab = ix => {
        setChengzuTab(ix)
    }
    const currentList = taskType === 2 ? reportTask : warn

    const showTask =
        currentList.length > 4
            ? checkWork
                ? currentList
                : currentList.slice(0, 4)
            : currentList

    const showSafe =
        safe.length > 2 ? (checkSafe ? safe : safe.slice(0, 2)) : safe

    const showReport =
        report.length > 2 ? (checkReport ? report : report.slice(0, 2)) : report

    const safeMap = {
        0: '轻微',
        1: 'Ⅰ级',
        2: 'Ⅱ级',
        3: 'Ⅲ级'
    }
    const openRecord = type => {
        changeRef.current.open(type, houseId)
    }
    // const reportMap = {
    //     0: '待处理',
    //     1: '已处理',
    //     2: '无效反馈',
    //     3: '已取消',
    //     4: '处理中'
    // }
    // const idMap = {
    //     1: '中国大陆居民',
    //     2: '港澳台居民',
    //     3: '外国居民'
    // }
    const warnStatusMap = {
        0: '待处理',
        1: '处理中',
        2: '已处理'
    }
    const warnClassMap = {
        0: 'todo-label',
        1: 'pend-label',
        2: 'done-label'
    }
    const manageMap = {
        1: '重点管控类(A)',
        2: '重点关注类(B)',
        3: '常规管理类(C)'
    }
    const warnMap = {
        1: '人口提示',
        2: '事件提示',
        3: '安全提示'
    }
    const warnRightMap = {
        1: '疑似群租房',
        2: '人口上报爆发',
        3: '逾期任务超量',
        4: '报事处理超时',
        5: '诉求事件上报爆发',
        6: '不满意',
        7: '燃气隐患房屋',
        8: '人口流出爆发',
        9: '人口上报过少'
    }
    // const cardMap = {
    //     1: '居民身份证',
    //     2: '护照',
    //     3: '港澳台居住证'
    // }
    const {
        backupsLessorInfo,
        lessorInfo,
        backupsPropertyRightInfo,
        propertyRightInfo,
        backupsRentCompanyPeople,
        rentCompanyPeople,
        backupsRentLivePeople,
        rentLivePeople,
        backupsTenantInfo,
        tenantInfo,
        backupsHouseStatus,
        houseInfo
    } = live

    const liveInfoList =
        liveTab === 1 ? rentLivePeople || [] : backupsRentLivePeople || []

    const sigleProperty = (
        <>
            <div className="item-sub">
                {rentCompanyPeople?.propertyRightCompanyPhone
                    ? rentCompanyPeople?.residentCompanyName +
                    ' | ' +
                    rentCompanyPeople?.propertyRightCompanyPhone
                    : rentCompanyPeople?.residentCompanyName}
            </div>
            <div className="task-row">
                单位俗称：
                {rentCompanyPeople?.residentCompanyCalledName || '-'}
            </div>
            <div className="task-row">
                <span className="row-item4">
                    法人姓名（个体工商户）：
                    {rentCompanyPeople?.legalName || '-'}
                </span>
                <span className="row-item4">
                    证件类型：
                    {rentCompanyPeople?.legalIdCardTypeMemo || '-'}
                </span>
                <span className="row-item4">
                    证件号码：
                    {rentCompanyPeople?.legalIdCard || '-'}
                </span>
                <span className="row-item4">
                    联系电话：
                    {rentCompanyPeople?.legalLinkPhone || '-'}
                </span>
            </div>
            <div className="task-row">
                <span className="row-item4">
                    负责人姓名：
                    {rentCompanyPeople?.dutyName || '-'}
                </span>
                <span className="row-item4">
                    证件类型：
                    {rentCompanyPeople?.dutyIdCardTypeMemo || '-'}
                </span>
                <span className="row-item4">
                    证件号码：
                    {rentCompanyPeople?.dutyIdCard || '-'}
                </span>
                <span className="row-item4">
                    联系电话：
                    {rentCompanyPeople?.dutyLinkPhone || '-'}
                </span>
            </div>
            <div className="task-row">
                <span className="row-item4">
                    保卫负责人姓名：
                    {rentCompanyPeople?.defendDutyName || '-'}
                </span>
                <span className="row-item4">
                    证件类型：
                    {rentCompanyPeople?.defendDutyIdCardTypeMemo || '-'}
                </span>
                <span className="row-item4">
                    证件号码：
                    {rentCompanyPeople?.defendDutyIdCard || '-'}
                </span>
                <span className="row-item4">
                    联系电话：
                    {rentCompanyPeople?.defendDutyLinkPhone || '-'}
                </span>
            </div>
            <div className="task-row">
                <span className="row-item4">
                    营业执照号：
                    {rentCompanyPeople?.businessLicenseNo || '-'}
                </span>
                <span className="row-item4">
                    经营范围：
                    {rentCompanyPeople?.natureOfBusiness || '-'}
                </span>
                <span className="row-item4">
                    单位分类：
                    {rentCompanyPeople?.unitClassificationTypeMemo || '-'}
                </span>
                <span className="row-item4">
                    单位属性：
                    {rentCompanyPeople?.unitAttributeTypeMemo || '-'}
                </span>
            </div>
            <div className="task-row">
                <span className="row-item4">
                    是否有监控：
                    {rentCompanyPeople?.monitorMemo || '-'}
                </span>
                <span className="row-item4">
                    单位性质：
                    {rentCompanyPeople?.unitNatureTypeMemo || '-'}
                </span>
                {/* <span className="row-item4">
                            单位分类：{liveInfo.unitClassificationTypeMemo || '-'}
                        </span> */}
                {/* <span className="row-item4">
                            单位属性：{liveInfo.unitAttributeTypeMemo || '-'}
                        </span> */}
            </div>
            {rentCompanyPeople?.rentEmployeesInfos?.map((item, ix) => (
                <div className="live-item" key={ix}>
                    <div className="item-sub">从业人员信息&nbsp;{ix + 1}</div>
                    <div className="task-row">
                        <span className="row-item4">
                            姓名：
                            {item.employeesName || '-'}
                        </span>
                        <span className="row-item4">
                            证件类型：
                            {item.employeesIdCardTypeMemo || '-'}
                        </span>
                        <span className="row-item4">
                            证件号码：
                            {item.employeesIdCard || '-'}
                        </span>
                        <span className="row-item4">
                            联系电话：
                            {item.employeesLinkPhone || '-'}
                        </span>
                    </div>
                    <div className="task-row">
                        <span className="row-item4">
                            现住址：
                            {item.currentAddress || '-'}
                        </span>
                        <span className="row-item4">
                            职位类别：
                            {item.jobCategory || '-'}
                        </span>
                    </div>
                </div>
            ))}
        </>
    )
    const multiProperty = backupsRentCompanyPeople?.map((item, ix) => (
        <div key={ix} className="live-item">
            <div className="item-sub">
                {item.propertyRightCompanyPhone
                    ? item.residentCompanyName +
                    ' | ' +
                    item.propertyRightCompanyPhone
                    : item.residentCompanyName}
            </div>
            <div className="task-row">
                单位俗称：
                {item.residentCompanyCalledName || '-'}
            </div>
            <div className="task-row">
                <span className="row-item4">
                    法人姓名（个体工商户）：
                    {item.legalName || '-'}
                </span>
                <span className="row-item4">
                    证件类型：
                    {item.legalIdCardTypeMemo || '-'}
                </span>
                <span className="row-item4">
                    证件号码：
                    {item.legalIdCard || '-'}
                </span>
                <span className="row-item4">
                    联系电话：
                    {item.legalLinkPhone || '-'}
                </span>
            </div>
            <div className="task-row">
                <span className="row-item4">
                    负责人姓名：
                    {item.dutyName || '-'}
                </span>
                <span className="row-item4">
                    证件类型：
                    {item.dutyIdCardTypeMemo || '-'}
                </span>
                <span className="row-item4">
                    证件号码：
                    {item.dutyIdCard || '-'}
                </span>
                <span className="row-item4">
                    联系电话：
                    {item.dutyLinkPhone || '-'}
                </span>
            </div>
            <div className="task-row">
                <span className="row-item4">
                    保卫负责人姓名：
                    {item.defendDutyName || '-'}
                </span>
                <span className="row-item4">
                    证件类型：
                    {item.defendDutyIdCardTypeMemo || '-'}
                </span>
                <span className="row-item4">
                    证件号码：
                    {item.defendDutyIdCard || '-'}
                </span>
                <span className="row-item4">
                    联系电话：
                    {item.defendDutyLinkPhone || '-'}
                </span>
            </div>
            <div className="task-row">
                <span className="row-item4">
                    营业执照号：
                    {item.businessLicenseNo || '-'}
                </span>
                <span className="row-item4">
                    经营范围：
                    {item.natureOfBusiness || '-'}
                </span>
                <span className="row-item4">
                    单位分类：
                    {item.unitClassificationTypeMemo || '-'}
                </span>
                <span className="row-item4">
                    单位属性：
                    {item.unitAttributeTypeMemo || '-'}
                </span>
            </div>
            <div className="task-row">
                <span className="row-item4">
                    是否有监控：
                    {item.monitorMemo || '-'}
                </span>
                <span className="row-item4">
                    单位性质：
                    {item.unitNatureTypeMemo || '-'}
                </span>
                {/* <span className="row-item4">
      单位分类：{liveInfo.unitClassificationTypeMemo || '-'}
  </span> */}
                {/* <span className="row-item4">
      单位属性：{liveInfo.unitAttributeTypeMemo || '-'}
  </span> */}
            </div>
            {item?.rentEmployeesInfos?.map((item, ix) => (
                <div className="live-item" key={ix}>
                    <div className="item-sub">从业人员信息&nbsp;{ix + 1}</div>
                    <div className="task-row">
                        <span className="row-item4">
                            姓名：
                            {item.employeesName || '-'}
                        </span>
                        <span className="row-item4">
                            证件类型：
                            {item.employeesIdCardTypeMemo || '-'}
                        </span>
                        <span className="row-item4">
                            证件号码：
                            {item.employeesIdCard || '-'}
                        </span>
                        <span className="row-item4">
                            联系电话：
                            {item.employeesLinkPhone || '-'}
                        </span>
                    </div>
                    <div className="task-row">
                        <span className="row-item4">
                            现住址：
                            {item.currentAddress || '-'}
                        </span>
                        <span className="row-item4">
                            职位类别：
                            {item.jobCategory || '-'}
                        </span>
                    </div>
                </div>
            ))}
        </div>
    ))
    //辅助库单位信息
    const propertyEle = liveTab === 1 ? sigleProperty : multiProperty
    // const getUncheckNams = list => {
    //     let res = []
    //     for (let item of list) {
    //         res.push(item.name)
    //     }
    //     return res.length > 0 ? res.join('、') : ''
    // }
    const handleCloseModal = () => {
        setPosition('')
        const anchorElement = document.getElementById('todo')
        anchorElement.scrollIntoView({
            block: 'start',
            behavior: 'smooth'
        })
        setVisible(false)
    }
    return visible ? (
        <div style={{ top: props.top }} className="houseInfo">
            <div className="modal-head">
                <span className="house-name">
                    {info?.houseCompleteName
                        ? info?.houseCompleteName + label
                        : '-'}
                </span>
                {info?.labelList?.length > 0 && (
                    <>
                        {info?.labelList?.map((item, ix) => (
                            <span
                                className={classNames(
                                    'house-label',
                                    lableClass(item.labelCode)
                                )}
                            >
                                {item.labelName}
                            </span>
                        ))}
                    </>
                )}

                <img
                    onClick={handleCloseModal}
                    src={Close}
                    alt="err"
                    className="close-img"
                />
            </div>
            <div className="modal-tag">
                <span
                    onClick={() => scrollToAnchor('todo')}
                    className={classNames('jump-tag', {
                        tagActive: position === 'todo'
                    })}
                >
                    待办任务
                </span>
                {/* <span
                    onClick={() => scrollToAnchor('virus')}
                    className={classNames('jump-tag', {
                        tagActive: position === 'virus'
                    })}
                >
                    防疫打卡
                </span> */}
                {/* <span
                    onClick={() => scrollToAnchor('house')}
                    className={classNames('jump-tag', {
                        tagActive: position === 'house'
                    })}
                >
                    产权信息
                </span> */}
                <span
                    onClick={() => scrollToAnchor('baseInfo')}
                    className={classNames('jump-tag', {
                        tagActive: position === 'baseInfo'
                    })}
                >
                    房源信息
                </span>
                <span
                    onClick={() => scrollToAnchor('live')}
                    className={classNames('jump-tag', {
                        tagActive: position === 'live'
                    })}
                >
                    {/* 居住信息变更 */}
                    居住信息
                </span>
                {/* <span
                    onClick={() => scrollToAnchor('rent')}
                    className={classNames('jump-tag', {
                        tagActive: position === 'rent'
                    })}
                >
                    出租信息
                </span> */}
                {/* <span
                    onClick={() => scrollToAnchor('chengzu')}
                    className={classNames('jump-tag', {
                        tagActive: position === 'chengzu'
                    })}
                >
                    承租信息
                </span> */}
                <span
                    onClick={() => scrollToAnchor('report')}
                    className={classNames('jump-tag', {
                        tagActive: position === 'report'
                    })}
                >
                    报事信息
                </span>
                <span
                    onClick={() => scrollToAnchor('safety')}
                    className={classNames('jump-tag', {
                        tagActive: position === 'safety'
                    })}
                >
                    安全信息
                </span>
                {/* <span
                    onClick={() => scrollToAnchor('remark')}
                    className={classNames('jump-tag', {
                        tagActive: position === 'remark'
                    })}
                >
                    备注记录
                </span> */}
            </div>
            <div className="modal-content">
                <div className="item-box" id="todo">
                    <div className="task-title">
                        <span
                            className={
                                taskType === 3
                                    ? 'task-label task-active'
                                    : 'task-label'
                            }
                            onClick={() => changeType(3)}
                        >
                            工作提示
                            <span className="task-num">
                                {warn.length > 10 ? '10+' : warn.length}
                            </span>
                        </span>
                        {/* <span
                            className={
                                taskType === 1
                                    ? 'task-label task-active'
                                    : 'task-label'
                            }
                            onClick={() => changeType(1)}
                        >
                            待核实人口任务
                            <span className="task-num">
                                {checkCount > 10 ? '10+' : checkCount}
                            </span>
                        </span> */}
                        <span
                            className={
                                taskType === 2
                                    ? 'task-label task-active'
                                    : 'task-label'
                            }
                            onClick={() => changeType(2)}
                        >
                            待处理报事
                            <span className="task-num">
                                {reportCount > 10 ? '10+' : reportCount}
                            </span>
                        </span>
                    </div>
                    <div className="task-list">
                        {showTask.map((item, ix) => (
                            <div className="border-item" key={ix}>
                                <div className="task-top-row">
                                    <span className="task-top">
                                        {taskType === 2
                                            ? item.reportType
                                            : warnMap[item.eventType] +
                                            ' | ' +
                                            warnRightMap[item.warnType]}
                                    </span>
                                    {taskType === 3 && (
                                        <span
                                            className={
                                                warnClassMap[item.warnStatus]
                                            }
                                        >
                                            {warnStatusMap[item.warnStatus]}
                                        </span>
                                    )}
                                    {taskType === 3 && (
                                        <span
                                            className="check-detail"
                                            style={{ lineHeight: '32px' }}
                                            onClick={() => openWarnLog(item)}
                                        >
                                            <img
                                                src={Left}
                                                alt="err"
                                                className="icons"
                                            ></img>
                                            <span className="icon-text">
                                                查看处理记录
                                            </span>
                                            <img
                                                src={Right}
                                                alt="err"
                                                className="go-icon icons"
                                            ></img>
                                            <img
                                                src={RightIcon}
                                                alt="err"
                                                className="icons"
                                            ></img>
                                        </span>
                                    )}
                                </div>
                                <div className="task-row">
                                    {taskType === 2 ? (
                                        <>
                                            <span className="row-item">
                                                提报人手机号：
                                                {item.reportPhone || '-'}
                                            </span>
                                            <span className="row-item">
                                                提报人绑定小区：
                                                {item.userChooseVillageName ||
                                                    '-'}
                                            </span>
                                            <span className="row-item">
                                                提报人绑定房屋：
                                                {item.userChooseHouseName ||
                                                    '-'}
                                            </span>
                                        </>
                                    ) : (
                                        <>
                                            <span>
                                                提示内容：
                                                {item.warnDesc || '-'}
                                            </span>
                                        </>
                                    )}
                                </div>
                                <div className="task-row">
                                    {taskType === 2
                                        ? '提报时间：'
                                        : '提示时间：'}
                                    {taskType === 2
                                        ? item.reportTime
                                        : item.warnTime}
                                </div>
                            </div>
                        ))}
                    </div>
                    {currentList.length > 4 && (
                        <div className="check-more" onClick={checkMoreWork}>
                            {checkWork ? '收起' : '查看全部'}
                            <img
                                src={Drop}
                                alt="err"
                                className={checkWork ? 'up-img' : 'drop-img'}
                            ></img>
                        </div>
                    )}
                </div>
                {/* <div className="item-box" id="house">
                    <div className="item-title">产权信息</div>
                    <div className="virus-tab">
                        <div
                            onClick={() => switchPropertyTab(1)}
                            className={
                                propertyTab === 1
                                    ? 'tab-item tab-active'
                                    : 'tab-item'
                            }
                        >
                            标准库
                        </div>
                        <div
                            onClick={() => switchPropertyTab(2)}
                            className={
                                propertyTab === 2
                                    ? 'tab-item tab-active'
                                    : 'tab-item'
                            }
                        >
                            辅助库
                        </div>
                        <span
                            style={{ lineHeight: '32px' }}
                            className="check-detail"
                            onClick={() => openRecord('chanquan')}
                        >
                            <img src={Left} alt="err" className="icons"></img>
                            <span className="icon-text">查看变更记录</span>
                            <img
                                src={Right}
                                alt="err"
                                className="go-icon icons"
                            ></img>
                            <img
                                src={RightIcon}
                                alt="err"
                                className="icons"
                            ></img>
                        </span>
                    </div>
                    {propertyTab === 1 ? (
                        <>
                            {propertyRightInfo?.propertyRightType === 1 ? (
                                <>
                                    <div className="task-row">
                                        <span className="row-item">
                                            产权类型：个人
                                        </span>
                                    </div>
                                    <div className="task-row"> */}
                                        {/* <span className="row-item">
                                    证件类型：
                                    {cardMap[propertyInfo?.propertyRightIdCardType] ||
                                        '-'}
                                </span> */}
                                        {/* <span className="row-item">
                                            产权人姓名：
                                            {propertyRightInfo?.propertyRightName ||
                                                '-'}
                                        </span>
                                        <span className="row-item">
                                            身份证号：
                                            {propertyRightInfo?.propertyRightIdCard ||
                                                '-'}
                                        </span>
                                        <span className="row-item">
                                            联系电话：
                                            {propertyRightInfo?.propertyRightLinkPhone ||
                                                '-'}
                                        </span>
                                    </div>
                                    <div className="task-row">
                                        <span className="row-item">
                                            信息来源：
                                            {propertyRightInfo?.backupsSourceMemo ||
                                                '-'}
                                        </span>
                                        <span className="row-item">
                                            更新时间：
                                            {propertyRightInfo?.createTime ||
                                                '-'}
                                        </span>
                                    </div>
                                </>
                            ) : propertyRightInfo?.propertyRightType === 2 ? (
                                <>
                                    <div className="task-row">
                                        <span className="row-item">
                                            产权类型：单位
                                        </span>
                                        <span className="row-item">
                                            单位名称：
                                            {propertyRightInfo?.propertyRightCompanyName ||
                                                '-'}
                                        </span>
                                        <span className="row-item">
                                            单位电话：
                                            {propertyRightInfo?.propertyRightCompanyPhone ||
                                                '-'}
                                        </span>
                                    </div>
                                    <div className="task-row">
                                        <span className="row-item">
                                            负责人姓名：
                                            {propertyRightInfo?.propertyRightCompanyManName ||
                                                '-'}
                                        </span>
                                        <span className="row-item">
                                            负责人联系电话：
                                            {propertyRightInfo?.propertyRightCompanyLinkPhone ||
                                                '-'}
                                        </span>
                                    </div>
                                    <div className="task-row">
                                        <span className="row-item">
                                            信息来源：
                                            {propertyRightInfo?.backupsSourceMemo ||
                                                '-'}
                                        </span>
                                        <span className="row-item">
                                            更新时间：
                                            {propertyRightInfo?.createTime ||
                                                '-'}
                                        </span>
                                    </div>
                                </>
                            ) : (
                                <>
                                    {propertyRightInfo?.propertyRightType ===
                                        3 ? (
                                        <div className="task-row">
                                            <span className="row-item">
                                                产权类型：产权不明
                                            </span>
                                            <span className="row-item">
                                                信息来源：
                                                {propertyRightInfo?.backupsSourceMemo ||
                                                    '-'}
                                            </span>
                                        </div>
                                    ) : (
                                        <>
                                            <div className="task-row">
                                                <span className="row-item">
                                                    产权类型：-
                                                </span>
                                            </div>
                                            <div className="task-row">
                                                <span className="row-item">
                                                    产权人姓名：
                                                    {propertyRightInfo?.propertyRightName ||
                                                        '-'}
                                                </span>
                                                <span className="row-item">
                                                    联系电话：
                                                    {propertyRightInfo?.propertyRightLinkPhone ||
                                                        '-'}
                                                </span>
                                            </div>
                                            <div className="task-row">
                                                <span className="row-item">
                                                    信息来源：
                                                    {propertyRightInfo?.backupsSourceMemo ||
                                                        '-'}
                                                </span>
                                                <span className="row-item">
                                                    更新时间：
                                                    {propertyRightInfo?.createTime ||
                                                        '-'}
                                                </span>
                                            </div>
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            {backupsPropertyRightInfo?.map((item, ix) => (
                                <div key={ix} className="live-item">
                                    {item?.propertyRightType === 1 ? (
                                        <>
                                            <div className="task-row">
                                                <span className="row-item">
                                                    产权类型：个人
                                                </span>
                                            </div>
                                            <div className="task-row">
                                                <span className="row-item">
                                                    产权人姓名：
                                                    {item?.propertyRightName ||
                                                        '-'}
                                                </span>
                                                <span className="row-item">
                                                    身份证号：
                                                    {item?.propertyRightIdCard ||
                                                        '-'}
                                                </span>
                                                <span className="row-item">
                                                    联系电话：
                                                    {item?.propertyRightLinkPhone ||
                                                        '-'}
                                                </span>
                                            </div>
                                            <div className="task-row">
                                                <span className="row-item">
                                                    信息来源：
                                                    {item?.backupsSourceMemo ||
                                                        '-'}
                                                </span>
                                                <span className="row-item">
                                                    更新时间：
                                                    {item?.createTime || '-'}
                                                </span>
                                            </div>
                                        </>
                                    ) : item?.propertyRightType === 2 ? (
                                        <>
                                            <div className="task-row">
                                                <span className="row-item">
                                                    产权类型：单位
                                                </span>
                                                <span className="row-item">
                                                    单位名称：
                                                    {item?.propertyRightCompanyName ||
                                                        '-'}
                                                </span>
                                                <span className="row-item">
                                                    单位电话：
                                                    {item?.propertyRightCompanyPhone ||
                                                        '-'}
                                                </span>
                                            </div>
                                            <div className="task-row">
                                                <span className="row-item">
                                                    负责人姓名：
                                                    {item?.propertyRightCompanyManName ||
                                                        '-'}
                                                </span>
                                                <span className="row-item">
                                                    负责人联系电话：
                                                    {item?.propertyRightCompanyLinkPhone ||
                                                        '-'}
                                                </span>
                                            </div>
                                            <div className="task-row">
                                                <span className="row-item">
                                                    信息来源：
                                                    {item?.backupsSourceMemo ||
                                                        '-'}
                                                </span>
                                                <span className="row-item">
                                                    更新时间：
                                                    {item?.createTime || '-'}
                                                </span>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            {item?.propertyRightType === 3 ? (
                                                <div className="task-row">
                                                    <span className="row-item">
                                                        产权类型：产权不明
                                                    </span>
                                                </div>
                                            ) : (
                                                <>
                                                    <div className="task-row">
                                                        <span className="row-item">
                                                            产权类型：-
                                                        </span>
                                                    </div>
                                                    <div className="task-row">
                                                        <span className="row-item">
                                                            产权人姓名：
                                                            {item?.propertyRightName ||
                                                                '-'}
                                                        </span>
                                                        <span className="row-item">
                                                            联系电话：
                                                            {item?.propertyRightLinkPhone ||
                                                                '-'}
                                                        </span>
                                                    </div>
                                                    <div className="task-row">
                                                        <span className="row-item">
                                                            信息来源：
                                                            {item?.backupsSourceMemo ||
                                                                '-'}
                                                        </span>
                                                        <span className="row-item">
                                                            更新时间：
                                                            {item?.createTime ||
                                                                '-'}
                                                        </span>
                                                    </div>
                                                </>
                                            )}
                                        </>
                                    )}
                                </div>
                            ))}
                        </>
                    )} */}
                    {/* <div className="task-row">
                        <span className="row-item">
                            房屋户型：{propertyInfo?.houseTypeMemo || '-'}
                        </span>
                        <span className="row-item">
                            建筑面积：{propertyInfo?.buildArea || '-'}
                        </span>
                        <span className="row-item">
                            套内面积：{propertyInfo?.insideArea || '-'}
                        </span>
                    </div> */}
                {/* </div> */}
                <div className="item-box" id="baseInfo">
                    <div className="item-title">房源信息</div>
                    <div className="virus-tab">
                        <div
                            onClick={() => switchBaseTab(1)}
                            className={
                                baseTab === 1
                                    ? 'tab-item tab-active'
                                    : 'tab-item'
                            }
                        >
                            标准库
                        </div>
                        <div
                            onClick={() => switchBaseTab(2)}
                            className={
                                baseTab === 2
                                    ? 'tab-item tab-active'
                                    : 'tab-item'
                            }
                        >
                            辅助库
                        </div>
                        {/* <span
                            className="check-detail"
                            style={{ lineHeight: '32px' }}
                            onClick={() => openRecord('resident')}
                        >
                            <img src={Left} alt="err" className="icons"></img>
                            <span className="icon-text">查看变更记录</span>
                            <img
                                src={Right}
                                alt="err"
                                className="go-icon icons"
                            ></img>
                            <img
                                src={RightIcon}
                                alt="err"
                                className="icons"
                            ></img>
                        </span> */}
                    </div>
                    <div className="live-item">
                        <div className="subTitle">基础信息</div>
                        <div className="task-row">
                            <span className="row-item">
                                房源编号：{houseInfo?.houseCode || '-'}
                            </span>
                            <span className="row-item">
                                更新时间：
                                {houseInfo?.updateTime || '-'}
                            </span>
                            <span className="row-item1">
                                详细地址：
                                {houseInfo?.houseAddress || '-'}
                            </span>
                            {/* <span className="row-item">
                                录入人：
                                {houseInfo?.updateByName || '-'}
                            </span> */}
                        </div>
                        {/* <div className="task-row">
                            <span className="row-item">
                                房屋户型：
                                {`${formatValue(
                                    houseInfo?.roomNumber || 0
                                )}室${formatValue(
                                    houseInfo?.toiletNumber || 0
                                )}卫${formatValue(
                                    houseInfo?.livingRoomNumber || 0
                                )}厅`}
                            </span>
                            <span className="row-item">
                                建筑面积：{houseInfo?.buildArea || '-'}
                            </span>
                            <span className="row-item">
                                套内面积：
                                {houseInfo?.insideArea || '-'}
                            </span>
                        </div> */}
                        {/* <div className="task-row">
                            <span className="row-item">
                                房屋朝向：
                                {houseInfo?.orientationMemo || '-'}
                            </span>
                            <span className="row-item">
                                装修情况：{houseInfo?.renovationMemo || '-'}
                            </span>
                            <span className="row-item">
                                产权类型：
                                {houseInfo?.communityOwnershipMemo || '-'}
                            </span>
                        </div> */}
                        <div className="task-row">
                        </div>
                    </div>
                    <div className="live-item">
                        <div className="subTitle">房源状态</div>
                        {baseTab === 1 ? (
                            <>
                                <div className="task-row">
                                    <span className="row-item1">
                                        房屋使用类型：
                                        {formatValue(
                                            houseInfo?.houseUseTypeMemo
                                        )}
                                        {(houseInfo?.houseUseTypeUpdateTime ||
                                            houseInfo?.houseUseTypeUpdateTime) && (
                                                <>
                                                    （信息来源：
                                                    {formatValue(
                                                        houseInfo?.houseUseTypeSourceMemo
                                                    )}
                                                    <span className="ml-8">
                                                        更新时间：
                                                        {formatValue(
                                                            houseInfo?.houseUseTypeUpdateTime
                                                        )}
                                                    </span>
                                                    ）
                                                </>
                                            )}
                                    </span>
                                </div>
                                <div className="task-row">
                                    <span className="row-item1">
                                        租借类型：
                                        {formatValue(houseInfo?.toBeRentedMemo)}
                                        {(houseInfo?.toBeRentedSourceMemo ||
                                            houseInfo?.toBeRentedSourceUpdateTime) && (
                                                <>
                                                    （信息来源：
                                                    {formatValue(
                                                        houseInfo?.toBeRentedSourceMemo
                                                    )}{' '}
                                                    <span className="ml-8">
                                                        更新时间：
                                                        {formatValue(
                                                            houseInfo?.toBeRentedSourceUpdateTime
                                                        )}
                                                    </span>
                                                    ）
                                                </>
                                            )}
                                    </span>
                                </div>
                                <div className="task-row">
                                    <span className="row-item1">
                                        租借状态：
                                        {houseInfo?.leaseRentTypeMemo || '-'}
                                    </span>
                                </div>
                                <div className="task-row">
                                    <span className="row-item1">
                                        房屋用途：
                                        {formatValue(
                                            houseInfo?.housePurposeTypeMemo
                                        )}
                                        {(houseInfo?.housePurposeTypeSourceMemo ||
                                            houseInfo?.housePurposeTypeUpdateTime) && (
                                                <>
                                                    （信息来源：
                                                    {formatValue(
                                                        houseInfo?.housePurposeTypeSourceMemo
                                                    )}
                                                    <span className="ml-8">
                                                        更新时间：
                                                        {formatValue(
                                                            houseInfo?.housePurposeTypeUpdateTime
                                                        )}
                                                    </span>
                                                    ）
                                                </>
                                            )}
                                    </span>
                                </div>
                                {/* <div className="task-row">
                                    <span className="row-item">
                                        房源标签：
                                        {houseInfo?.orientationMemolabels?.join(
                                            ','
                                        ) || '-'}
                                    </span>
                                    <span className="row-item">
                                        装修状态：
                                        {houseInfo?.decorationStatusMemo || '-'}
                                    </span>
                                    <span className="row-item">
                                        委托情况：
                                        {houseInfo?.entrustStatusMemo || '-'}
                                    </span>
                                </div> */}
                            </>
                        ) : (
                            <>
                                <div className="sec-title">房屋使用类型</div>
                                <div className="t-table">
                                    <div className="t-head">
                                        <div className="col1">房屋使用类型</div>
                                        <div className="col2">信息来源</div>
                                        <div className="col1">更新时间</div>
                                    </div>
                                    {backupsHouseStatus?.houseUseTypeItems?.map(
                                        (item, ix) => (
                                            <div className="t-list" key={ix}>
                                                <div className="col1">
                                                    {item.typeMemo || '-'}
                                                </div>
                                                <div className="col2">
                                                    {item.backupsSourceMemo ||
                                                        '-'}
                                                </div>
                                                <div className="col1">
                                                    {item.updateTime || '-'}
                                                </div>
                                            </div>
                                        )
                                    )}
                                </div>
                                <div className="sec-title">租借类型</div>
                                <div className="t-table">
                                    <div className="t-head">
                                        <div className="col1">租借类型</div>
                                        <div className="col2">信息来源</div>
                                        <div className="col1">更新时间</div>
                                    </div>
                                    {backupsHouseStatus?.toBeRentedItems?.map(
                                        (item, ix) => (
                                            <div className="t-list" key={ix}>
                                                <div className="col1">
                                                    {item.typeMemo || '-'}
                                                </div>
                                                <div className="col2">
                                                    {item.backupsSourceMemo ||
                                                        '-'}
                                                </div>
                                                <div className="col1">
                                                    {item.updateTime || '-'}
                                                </div>
                                            </div>
                                        )
                                    )}
                                </div>
                                <div className="sec-title">房屋用途</div>
                                <div className="t-table">
                                    <div className="t-head">
                                        <div className="col1">房屋用途</div>
                                        <div className="col2">信息来源</div>
                                        <div className="col1">更新时间</div>
                                    </div>
                                    {backupsHouseStatus?.housePurposeTypeItems?.map(
                                        (item, ix) => (
                                            <div className="t-list" key={ix}>
                                                <div className="col1">
                                                    {item.typeMemo || '-'}
                                                </div>
                                                <div className="col2">
                                                    {item.backupsSourceMemo ||
                                                        '-'}
                                                </div>
                                                <div className="col1">
                                                    {item.updateTime || '-'}
                                                </div>
                                            </div>
                                        )
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <div className="item-box" id="live">
                    <div className="item-title">居住信息</div>
                    <div className="virus-tab">
                        <div
                            onClick={() => switchLiveTab(1)}
                            className={
                                liveTab === 1
                                    ? 'tab-item tab-active'
                                    : 'tab-item'
                            }
                        >
                            标准库
                        </div>
                        <div
                            onClick={() => switchLiveTab(2)}
                            className={
                                liveTab === 2
                                    ? 'tab-item tab-active'
                                    : 'tab-item'
                            }
                        >
                            辅助库
                        </div>
                        <span
                            className="check-detail"
                            style={{ lineHeight: '32px' }}
                            onClick={() => openRecord('resident')}
                        >
                            <img src={Left} alt="err" className="icons"></img>
                            <span className="icon-text">查看变更记录</span>
                            <img
                                src={Right}
                                alt="err"
                                className="go-icon icons"
                            ></img>
                            <img
                                src={RightIcon}
                                alt="err"
                                className="icons"
                            ></img>
                        </span>
                    </div>
                    {live?.updateRentSourceType ? (
                        propertyEle
                    ) : (
                        <>
                            {liveInfoList?.map((item, ix) => (
                                <div className="live-item" key={ix}>
                                    <div className="item-sub">
                                        {item.livePhone
                                            ? item.liveName +
                                            ' | ' +
                                            item.livePhone
                                            : item.liveName}
                                    </div>
                                    <div className="task-row">
                                        <span className="row-item">
                                            证件类型：
                                            {item.liveCardIdTypeMemo || '-'}
                                        </span>
                                        <span className="row-item">
                                            证件号码：
                                            {item.liveCardId || '-'}
                                        </span>
                                        {/* <span className="row-item">
                                            管理类别：
                                            {manageMap[
                                                item.managementCategoryType
                                            ] || '-'}
                                        </span> */}
                                    </div>
                                    {/* <div className="task-row">
                                        <span className="row-item">
                                            入住时间：
                                            {item.checkInTime || '-'}
                                        </span>
                                        <span className="row-item">
                                            租赁结束时间:
                                            {item.leaseEndTime || '-'}
                                        </span>
                                        <span className="row-item">
                                            人口标签：
                                            {item?.labels?.join(',') || '-'}
                                        </span>
                                    </div> */}
                                    <div className="task-row">
                                        {/* <span className="row-item">
                                            户籍是否是本地地址：
                                            {item.registerLocalAddressMemo ||
                                                '-'}
                                        </span> */}
                                        <span className="row-item">
                                            信息来源：
                                            {liveTab === 1
                                                ? item.infoSourceTypeMemo || '-'
                                                : item.backupsSourceMemo || '-'}
                                        </span>
                                        {/* <span className="row-item">
                                            备注：{item.liveRemark || '-'}
                                        </span> */}
                                    </div>
                                </div>
                            ))}
                        </>
                    )}
                </div>
                {/* <div className="item-box" id="rent">
                    <div className="item-title">出租信息</div>
                    <div className="virus-tab">
                        <div
                            onClick={() => switchRentTab(1)}
                            className={
                                rentTab === 1
                                    ? 'tab-item tab-active'
                                    : 'tab-item'
                            }
                        >
                            标准库
                        </div>
                        <div
                            onClick={() => switchRentTab(2)}
                            className={
                                rentTab === 2
                                    ? 'tab-item tab-active'
                                    : 'tab-item'
                            }
                        >
                            辅助库
                        </div>
                        <span
                            className="check-detail"
                            style={{ lineHeight: '32px' }}
                            onClick={() => openRecord('rent')}
                        >
                            <img src={Left} alt="err" className="icons"></img>
                            <span className="icon-text">查看变更记录</span>
                            <img
                                src={Right}
                                alt="err"
                                className="go-icon icons"
                            ></img>
                            <img
                                src={RightIcon}
                                alt="err"
                                className="icons"
                            ></img>
                        </span>
                    </div>
                    {rentTab === 1 ? (
                        <>
                            <div className="task-row">
                                <span className="row-item">
                                    出租人身份：
                                    {lessorInfo?.identityOfLessorTypeMemo ||
                                        '-'}
                                </span>
                                <span className="row-item">
                                    出租人姓名：{lessorInfo?.lessorName || '-'}
                                </span>
                                <span className="row-item">
                                    出租人证件类型：
                                    {lessorInfo?.lessorIdCardTypeMemo || '-'}
                                </span>
                            </div>
                            <div className="task-row">
                                <span className="row-item">
                                    出租人证件号码：
                                    {lessorInfo?.lessorIdCard || '-'}
                                </span>
                                <span className="row-item">
                                    出租人联系电话：
                                    {lessorInfo?.lessorLinkPhone || '-'}
                                </span> */}
                                {/* <span className="row-item">备注：{live.lessorIdCardTypeMemo || '-'}</span> */}
                            {/* </div>
                            <div className="task-row">
                                <span className="row-item">
                                    信息来源：
                                    {lessorInfo?.backupsSourceMemo || '-'}
                                </span>
                                <span className="row-item">
                                    更新时间：{lessorInfo?.createTime || '-'}
                                </span>
                            </div>
                        </>
                    ) : (
                        <>
                            {backupsLessorInfo?.map((item, ix) => (
                                <div key={ix} className="live-item">
                                    <div className="task-row">
                                        <span className="row-item">
                                            出租人身份：
                                            {item?.identityOfLessorTypeMemo ||
                                                '-'}
                                        </span>
                                        <span className="row-item">
                                            出租人姓名：
                                            {item?.lessorName || '-'}
                                        </span>
                                        <span className="row-item">
                                            出租人证件类型：
                                            {item?.lessorIdCardTypeMemo || '-'}
                                        </span>
                                    </div>
                                    <div className="task-row">
                                        <span className="row-item">
                                            出租人证件号码：
                                            {item?.lessorIdCard || '-'}
                                        </span>
                                        <span className="row-item">
                                            出租人联系电话：
                                            {item?.lessorLinkPhone || '-'}
                                        </span>
                                    </div>
                                    <div className="task-row">
                                        <span className="row-item">
                                            信息来源：
                                            {item?.backupsSourceMemo || '-'}
                                        </span>
                                        <span className="row-item">
                                            更新时间：{item?.createTime || '-'}
                                        </span>
                                    </div>
                                </div>
                            ))}
                        </>
                    )}
                </div> */}
                {/* <div className="item-box" id="chengzu">
                    <div className="item-title">承租信息</div>
                    <div className="virus-tab">
                        <div
                            onClick={() => switchChengzuTab(1)}
                            className={
                                chengzuTab === 1
                                    ? 'tab-item tab-active'
                                    : 'tab-item'
                            }
                        >
                            标准库
                        </div>
                        <div
                            onClick={() => switchChengzuTab(2)}
                            className={
                                chengzuTab === 2
                                    ? 'tab-item tab-active'
                                    : 'tab-item'
                            }
                        >
                            辅助库
                        </div>
                        <span
                            className="check-detail"
                            style={{ lineHeight: '32px' }}
                            onClick={() => openRecord('chengzu')}
                        >
                            <img src={Left} alt="err" className="icons"></img>
                            <span className="icon-text">查看变更记录</span>
                            <img
                                src={Right}
                                alt="err"
                                className="go-icon icons"
                            ></img>
                            <img
                                src={RightIcon}
                                alt="err"
                                className="icons"
                            ></img>
                        </span>
                    </div>
                    {chengzuTab === 1 ? (
                        <>
                            <div className={classNames(tenantInfo?.tenantName?'':'hidden')}>
                                <span className="item-sub">
                                    {tenantInfo?.tenantLinkPhone
                                        ? tenantInfo?.tenantName +
                                        '-' +
                                        tenantInfo?.tenantLinkPhone
                                        : tenantInfo?.tenantName || '-'}
                                </span>
                                <span className="chengzu-label">承租人</span>
                            </div>
                            <div className="task-row">
                                <span className="row-item">
                                    承租人证件类型：
                                    {tenantInfo?.tenantIdCardTypeMemo || '-'}
                                </span>
                                <span className="row-item">
                                    承租人证件号码：
                                    {tenantInfo?.tenantIdCard || '-'}
                                </span>
                            </div>
                            <div className="task-row">
                                <span className="row-item">
                                    入住时间：
                                    {tenantInfo?.checkInTime || '-'}
                                </span>
                                <span className="row-item">
                                    租赁结束日期：
                                    {tenantInfo?.leaseEndTime || '-'}
                                </span>
                            </div>
                            <div className="task-row">
                                <span className="row-item">
                                    信息来源：
                                    {tenantInfo?.backupsSourceMemo || '-'}
                                </span>
                                <span className="row-item">
                                    更新时间：
                                    {tenantInfo?.createTime || '-'}
                                </span>
                            </div>
                        </>
                    ) : (
                        <>
                            {backupsTenantInfo?.map((item, ix) => (
                                <div key={ix} className="live-item">
                                    <div className={classNames(item?.tenantName||item?.tenantLinkPhone?'':'hidden')}>
                                        <span className="item-sub">
                                            {item?.tenantName || '-'} |
                                            {item?.tenantLinkPhone || '-'}
                                        </span>
                                        <span className="chengzu-label">
                                            承租人
                                        </span>
                                    </div>
                                    <div className="task-row">
                                        <span className="row-item">
                                            证件类型：
                                            {item?.tenantIdCardTypeMemo || '-'}
                                        </span>
                                        <span className="row-item">
                                            证件号码：
                                            {item?.tenantIdCard || '-'}
                                        </span>
                                    </div>
                                    <div className="task-row">
                                        <span className="row-item">
                                            入住时间：
                                            {item?.checkInTime || '-'}
                                        </span>
                                        <span className="row-item">
                                            租赁结束日期：
                                            {item?.leaseEndTime || '-'}
                                        </span>
                                    </div>
                                    <div className="task-row">
                                        <span className="row-item">
                                            信息来源：
                                            {item?.backupsSourceMemo || '-'}
                                        </span>
                                        <span className="row-item">
                                            更新时间：
                                            {item?.infoSourceTypeMemo || '-'}
                                        </span>
                                    </div>
                                </div>
                            ))}
                        </>
                    )}
                </div> */}

                <div className="item-box" id="report">
                    <div className="item-title">报事信息</div>
                    <div className="task-title">
                        <span
                            className={
                                reportStatus === ''
                                    ? 'task-label task-active'
                                    : 'task-label'
                            }
                            onClick={() => filterStatus('')}
                        >
                            全部
                            <span className="task-num">
                                {reportSumary.total}
                            </span>
                        </span>
                        <span
                            className={
                                reportStatus === '0'
                                    ? 'task-label task-active'
                                    : 'task-label'
                            }
                            onClick={() => filterStatus('0')}
                        >
                            待处理
                            <span className="task-num">
                                {reportSumary.todo}
                            </span>
                        </span>
                        <span
                            className={
                                reportStatus === '4'
                                    ? 'task-label task-active'
                                    : 'task-label'
                            }
                            onClick={() => filterStatus('4')}
                        >
                            处理中
                            <span className="task-num">
                                {reportSumary.doing}
                            </span>
                        </span>
                        <span
                            className={
                                reportStatus === '1'
                                    ? 'task-label task-active'
                                    : 'task-label'
                            }
                            onClick={() => filterStatus('1')}
                        >
                            已处理
                            <span className="task-num">
                                {reportSumary.done}
                            </span>
                        </span>
                        <span
                            className={
                                reportStatus === '2'
                                    ? 'task-label task-active'
                                    : 'task-label'
                            }
                            onClick={() => filterStatus('2')}
                        >
                            无效反馈
                            <span className="task-num">
                                {reportSumary.useless}
                            </span>
                        </span>
                        <span
                            className={
                                reportStatus === '3'
                                    ? 'task-label task-active'
                                    : 'task-label'
                            }
                            onClick={() => filterStatus('3')}
                        >
                            已取消
                            <span className="task-num">
                                {reportSumary.canceled}
                            </span>
                        </span>
                    </div>
                    {showReport?.map((item, ix) => (
                        <div className="border-item" key={ix}>
                            <div>
                                <span className="item-sub">
                                    {item.reportType +
                                        '  ' +
                                        item.reportPhone +
                                        '提报'}
                                </span>
                                <span
                                    className={classNames(
                                        'report-status',
                                        transStatus(item.reportStatus)
                                    )}
                                >
                                    {item.reportStatus}
                                </span>
                                <span
                                    className="check-detail"
                                    onClick={() => openDetail(item)}
                                >
                                    <img
                                        src={Left}
                                        alt="err"
                                        className="icons"
                                    ></img>
                                    <span className="icon-text">
                                        查看报事详情
                                    </span>
                                    <img
                                        src={Right}
                                        alt="err"
                                        className="go-icon icons"
                                    ></img>
                                    <img
                                        src={RightIcon}
                                        alt="err"
                                        className="icons"
                                    ></img>
                                </span>
                            </div>
                            <div className="task-row">
                                <span className="row-item">
                                    提报人绑定小区：
                                    {item.userChooseVillageName || '-'}
                                </span>
                                <span className="row-item">
                                    提报人绑定房屋：
                                    {item.userChooseHouseName || '-'}
                                </span>
                                <span className="row-item">
                                    提报时间：{item.reportTime || '-'}
                                </span>
                            </div>
                            <div className="task-row">
                                <span className="row-item">
                                    处理人角色：{item.updateByRole || '-'}
                                </span>
                                <span className="row-item">
                                    处理人：{item.updateBy || '-'}
                                </span>
                                <span className="row-item">
                                    处理时间：{item.updateTime || '-'}
                                </span>
                            </div>
                            <div className="task-row">
                                <span className="row-item">
                                    提报人评价：{item.score || '-'}
                                </span>
                                <span className="row-item">
                                    评价时间：{item.scoreTime || '-'}
                                </span>
                            </div>
                        </div>
                    ))}
                    {report.length > 2 && (
                        <div className="check-more" onClick={checkMoreReport}>
                            {checkReport ? '收起' : '查看全部'}
                            <img
                                src={Drop}
                                alt="err"
                                className={checkReport ? 'up-img' : 'drop-img'}
                            ></img>
                        </div>
                    )}
                </div>
                <div className="item-box" id="safety">
                    <div className="item-title">安全信息</div>
                    {showSafe.map((item, ix) => (
                        <div className="border-item" key={ix}>
                            <div className="task-row">
                                <span className="row-item">
                                    隐患级别：{safeMap[item.hiddenTroubleLevel]}
                                </span>
                                <span className="row-item">
                                    隐患描述：
                                    {item.hiddenTroubleDescription || '-'}
                                </span>
                                <span className="row-item">
                                    隐患备注：{item.hiddenTroubleRemark || '-'}
                                </span>
                            </div>
                            <div className="task-row">
                                <span className="row-item">
                                    是否整改：
                                    {item.whetherRectification === 1
                                        ? '未整改'
                                        : '已整改'}
                                </span>
                                <span className="row-item">
                                    检查时间：{item.checkTime || '-'}
                                </span>
                                <span className="row-item">
                                    燃气公司：{item.naturalGasCompany || '-'}
                                </span>
                            </div>
                            <div className="task-row">
                                <span className="row-item">
                                    导入操作人：{item.createName || '-'}
                                </span>
                                <span className="row-item">
                                    导入时间：{item.importTime || '-'}
                                </span>
                            </div>
                        </div>
                    ))}
                    {safe.length > 2 && (
                        <div className="check-more" onClick={checkMoreSafe}>
                            {checkSafe ? '收起' : '查看全部'}
                            <img
                                src={Drop}
                                alt="err"
                                className={checkSafe ? 'up-img' : 'drop-img'}
                            ></img>
                        </div>
                    )}
                </div>
                {/* <div className="item-box" id="remark">
                    <div className="item-title">备注信息</div>
                    {note.map((item, ix) => (
                        <div className="border-item" key={ix}>
                            <div className="task-row">
                                <span className="row-item">
                                    操作人角色：{item.operateUserName}
                                </span>
                                <span className="row-item">
                                    操作人：{item.operateUserName}
                                </span>
                                <span className="row-item">
                                    操作时间：{item.createTime}
                                </span>
                            </div>
                            <div className="task-row">备注：{item.content}</div>
                        </div>
                    ))}
                </div> */}
            </div>
            {/* <ReportInfo ref={scrollRef}></ReportInfo> */}
            <LogsModal ref={scrollRef}></LogsModal>
            <WarnInfo ref={warnRef}></WarnInfo>
            <RecordPeople ref={residentRef}></RecordPeople>
            <MonthRecord ref={peopleRecordRef}></MonthRecord>
            <ResidentRecord ref={checkRef}></ResidentRecord>
            <VirusInfo top={0} right={0} ref={virusRef}></VirusInfo>
            <ChangeRecord ref={changeRef}></ChangeRecord>
        </div>
    ) : null
})

export default HouseInfo

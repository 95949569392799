import { useData } from "../../../hooks"
import { institutionInfo, institutionList } from "apis/home"
import { orgInfo, getHouseList } from "./api/CommunityFB"
import shang from "../../../assets/home/shang.svg"
import xia from "../../../assets/home/xia.svg"
import classNames from "classnames"
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react"
import Modal from "./Modal"
import "./CommunityFB.fhd.scss"
import { formatValue } from "../../../utils"
import { useContext } from "react"
import appContext from "App.context"

const CommunityFB = forwardRef(({ openModal }, ref) => {
    const { communeId } = useContext(appContext)
    const [data] = useData(institutionInfo, communeId)

    const [dataSource, setDataSource] = useState([])
    const [detail, setDetail] = useState(null)

    const [visible, setVisible] = useState(false)
    const [detailVisible, setDetailVisible] = useState(false)
    const [imgVisible, setImgVisible] = useState(false)

    const fetch = useCallback(async (type) => {
        // 机构模态框列表的机构名称数组
        const orgName = []
        const { data } = await institutionList(communeId, type)
        try {
            data.forEach(s => {
                orgName.push(s.institutionName)
            })
            const res = await orgInfo(orgName)
            jionArr(data, res.data)
        } catch (error) {
            setDataSource(data)
        }
    }, [])

    // 对外接口获取的机构状态和机构房屋数添加到机构列表里面，合成一个
    const jionArr = (data, resData) => {
        data.forEach((s, i) => {
            for (let index = 0; index < resData.length; index++) {
                if (i === index) {
                    s.institutionType = resData[index].sincerityState
                    s.leasedHouseCount = resData[index].rentHouseCount
                }
            }
        })
        setDataSource(data)
    }

    // 机构状态类型区分
    const communityType = (s) => {
        if (s === '优质') {
            return 'tag1'
        } else if (s === '良好') {
            return 'tag2'
        } else if (s === '达标') {
            return 'tag3'
        } else if (s === '预警') {
            return 'tag4'
        } else if (s === '失信') {
            return 'tag5'
        }
    }

    const columns = [
        {
            title: "机构类型",
            width: 160,
            dataIndex: "institutionTypeName",
        },
        {
            title: "机构名称",
            width: 300,
            dataIndex: "institutionName",
        },
        {
            title: "联系人",
            width: 128,
            dataIndex: "contacts",
        },
        {
            title: "联系电话",
            width: 140,
            dataIndex: "contactPhone",
        },
        {
            title: '在租房屋数（套）',
            width: 152,
            dataIndex: "leasedHouseCount"
        },
        {
            title: "换证数（次）",
            width: 120,
            dataIndex: "renewalCount",
        },
        {
            title: new Date().getMonth() + 1 + "月人口上报情况",
            width: 156,
            dataIndex: "hasCurrentMonthReport",
        },
    ]

    useEffect(() => {
        if (!visible) {
            setDataSource(null)
            setDetail(null)
            setDetailVisible(false)
            setImgVisible(false)
        }
        if (!detailVisible) {
            setDetail(null)
            setImgVisible(false)
        }
    }, [detailVisible, visible])

    useImperativeHandle(
        ref,
        () => ({
            closeModal: () => {
                setVisible(false)
            },
        }),
        []
    )

    // 机构详情在租房源列表
    const [houseList, setHouseList] = useState()
    // 在租房屋概览列表的title
    const detailListTitle = [
        { name: '所属小区', width: 196 },
        { name: '房屋号', width: 152 },
        { name: '房屋户型', width: 120 },
        { name: '租借类型', width: 120 },
        { name: '租借状态', width: 120 },
        { name: '核验状态', width: 128 },
    ]
    // 获取机构详情在租房源列表
    const getHouseListAsync = async (info) => {
        const { data } = await getHouseList({
            dto: { orgName: info.institutionName },
            limit: 100,
            page: 1
        })
        setHouseList(data.list)
    }

    return (
        <div className="pr-4">
            {data?.map((s, i) => {
                return (
                    <div
                        key={i}
                        className="flex ai-c jc-b trigger pl-4 leading-[19px]"
                        onClick={() => {
                            fetch(s.institutionType)
                            setVisible(true)
                            openModal("CommunityFB")
                        }}
                    >
                        <div className="flex ai-c jc-b" style={{ width: "37%" }}>
                            <div
                                className="fs-14"
                                style={{
                                    width: "80px",
                                    textAlign: "left",
                                    marginTop: "4px"
                                }}
                            >
                                {s?.institutionTypeName}
                            </div>
                            <div
                                className="flex ai-c"
                                style={{ textAlign: "right", justifyContent: "left" }}
                            >
                                <span className="fs-20 mr-4 t-Md t-color ">{s?.count}<span className="fs-14">家</span></span>
                            </div>
                        </div>
                        <div className="flex ai-c jc-b" style={{ width: "57%" }}>
                            <div className="flex ai-c jc-e ml-2 w-[100px] color-4C99FF mt-1.5">
                                <div
                                    className={classNames(
                                        s?.ratio < 0 ? "color-10DF9D" : s?.ratio === 0 ? "t-color" : "", 'fs-14', ''
                                    )}
                                >
                                    {s?.ratio > 0
                                        ? "+" + s?.ratio + "%"
                                        : s?.ratio === 0
                                            ? "-"
                                            : "-" + s?.ratio + "%"}
                                </div>
                                <img
                                    className="ml-1 h-[17px] w-[17px]"
                                    src={s?.ratio > 0 ? shang : xia}
                                    alt=""
                                    style={{
                                        display: s?.ratio === 0 ? "none" : "block",
                                    }}
                                />
                            </div>
                            <div className="fs-14 mt-2">
                                <span className="color-A4DAFE">{new Date().getMonth() + 1}月</span>{s?.currentMonthCount === 0 ? 0 : formatValue(s?.currentMonthCount)}
                                家已上报
                            </div>
                        </div>
                    </div>
                )
            })}

            <Modal visible={visible} width={1229} height={856}>
                <div className="CommunityFB-modalContent">
                    <div className="modalTitle">
                        <div>
                            {columns.map((item) => (
                                <span key={item.dataIndex} style={{ width: item.width }}>
                                    {item.title}
                                </span>
                            ))}

                            <img
                                className="trigger"
                                style={{ transform: "translateX(-4px)", position: 'absolute', right: 0 }}
                                src={require("../../../assets/home/icon_close.png")}
                                alt=""
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setVisible(false)
                                }}
                            />
                        </div>
                    </div>
                    <ul className="modalContent">
                        {dataSource?.map((s, i) => {
                            return (
                                <li
                                    key={i}
                                    onClick={() => {
                                        setDetailVisible(true)
                                        setDetail(s)
                                        getHouseListAsync(s)
                                    }}
                                >
                                    {columns.map((item) => (
                                        <span
                                            className="text-ellipesis"
                                            style={{ width: item.width }}
                                            key={item.dataIndex}
                                        >
                                            {
                                                item.dataIndex === "institutionName"
                                                    ?
                                                    <span className="institutionName">
                                                        <span className="infoName text-ellipesis">{formatValue(s[item.dataIndex])}</span>
                                                        <span className={classNames('tag', communityType(s.institutionType))} >{s.institutionType}</span>
                                                    </span>
                                                    :
                                                    item.dataIndex === "hasCurrentMonthReport" ? s[item.dataIndex]
                                                        ? "已上报"
                                                        : "-" : formatValue(s[item.dataIndex])
                                            }
                                        </span>
                                    ))}
                                </li>
                            )
                        })}
                    </ul>
                </div>

                <div
                    className="community-fb-detail"
                    style={{
                        display: detailVisible ? "block" : "none",
                        position: "fixed",
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%,-50%)',
                    }}
                >
                    <div className="title">
                        <div className="content flex ai-c">
                            <span className="mr-4">机构名称：{detail?.institutionName}</span>
                            <span className={classNames('tag', communityType(detail?.institutionType))}>{detail?.institutionType}</span>
                        </div>
                        <img
                            src={require("../../../assets/home/icon_close.png")}
                            alt=""
                            className="trigger"
                            width={24}
                            onClick={(e) => {
                                e.stopPropagation()
                                setDetailVisible(false)
                            }}
                        />
                    </div>

                    <div className="content">
                        <div className="t-color fw-b">公司信息</div>
                        <div className="pl-4 pb-2">
                            <div className="flex">
                                <div className="item" style={{ width: 365 }}>
                                    <span>联系人：</span>
                                    <span>{formatValue(detail?.contacts)}</span>
                                </div>
                                <div className="item">
                                    <span>联系电话：</span>
                                    <span>{formatValue(detail?.contactPhone)}</span>
                                </div>
                            </div>

                            <div className="flex">
                                <div className="item" style={{ width: 365 }}>
                                    <span>换证数：</span>
                                    <span>{detail?.renewalCount}</span>
                                </div>
                                <div className="item">
                                    <span>租住平台账号：</span>
                                    <span>{detail?.hasOpenPlatformAccount ? "已开通" : "未开通"}</span>
                                </div>
                            </div>

                            <div></div>
                            <div className="item">
                                <span>{new Date().getMonth() + 1}月人口上报情况：</span>
                                <span>{detail?.hasCurrentMonthReport ? "已上报" : "未上报"}</span>
                            </div>
                            <div></div>
                            <div className="item">
                                <span>统一社会信用代码：</span>
                                <span>{formatValue(detail?.creditCode)}</span>
                            </div>
                            <div></div>
                            <div className="item">
                                <span>企业执照：</span>
                                {detail?.businessLicense ? (
                                    <img
                                        className="trigger"
                                        src={detail?.businessLicense}
                                        alt=""
                                        width={280}
                                        onClick={() => {
                                            setImgVisible(true)
                                        }}
                                    />
                                ) : (
                                    "-"
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="content-two" style={{ display: houseList?.length > 0 ? '' : 'none' }}>
                        <div className="t-color fw-b mb-2">在租房屋概览</div>
                        <div className="tableList">
                            <div className="flex detail-title ai-c">
                                {detailListTitle.map(s => {
                                    return <span style={{ width: s.width }} key={s.name}>{s.name}</span>
                                })}
                            </div>
                            <ul className="overflow-y">
                                {houseList?.map((s, i) => {
                                    return <li className="flex ai-c height-32" key={i}>
                                        <span className="pl-4 w-[196px]">{s.communityName}</span>
                                        <span className="pl-4 w-[152px]">{`${s.buildingNo}${s.unitNo}${s.houseNo}`}</span>
                                        <span className="pl-4 w-[120px]">{`${s.roomNumber}室${s.livingRoomNumber}厅${s.toiletNumber}卫`}</span>
                                        <span className="pl-4 w-[120px]">{s.rentTypeDesc}</span>
                                        <span className="pl-4 w-[120px]">{s.rentStatusDesc}</span>
                                        <span className="pl-4 w-[128px]">{s.verifyStatusDesc}</span>
                                    </li>
                                })}
                            </ul>
                        </div>
                    </div>

                    {/* img */}
                    <div
                        style={{
                            position: "fixed",
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%,-50%)',
                            width: 1200,
                            height: 860,
                            display: imgVisible ? "flex" : "none",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: 24,
                            background: "rgba(5, 24, 52, 1)",
                        }}
                    >
                        <img
                            className="trigger"
                            style={{
                                position: "absolute",
                                right: 24,
                                top: 24,
                            }}
                            src={require("../../../assets/home/icon_close.png")}
                            alt=""
                            width={24}
                            onClick={(e) => {
                                e.stopPropagation()
                                setImgVisible(false)
                            }}
                        />
                        <img
                            style={{ maxHeight: "100%", maxWidth: "100%" }}
                            src={detail?.businessLicense}
                            alt=""
                        />
                    </div>
                </div>
            </Modal>
        </div>
    )
})

export default CommunityFB

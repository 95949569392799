import axios from "../request"

// 桂溪街道-社区租赁房屋数据
export const leaseHouse = (communityBaseInfoId) => axios.get(`/big_screen/community/institution/house/${communityBaseInfoId}/rent/data`)

// 桂溪街道-社区租赁房屋模态框列表
export const houseList = (communityBaseInfoId) => axios.get(`/big_screen/community/institution/house/${communityBaseInfoId}/rent`)

// 桂溪街道-社区租赁人口数据
export const leasePeople = (communityBaseInfoId) => axios.get(`/big_screen/community/rent/population/info/${communityBaseInfoId}`)

// 桂溪街道-社区租赁人口模态框列表
export const peopleList = (communityBaseInfoId) => axios.get(`/big_screen/community/rent/population/list/${communityBaseInfoId}`)

// 桂溪街道-社区租赁房屋变化趋势
export const houseTrend = (communityBaseInfoId) => axios.get(`/big_screen/community/institution/house/${communityBaseInfoId}/rent/drift`)

// 桂溪街道-租赁人口搬入搬出趋势
export const populationTrend = (communityBaseInfoId) => axios.get(`/big_screen/community/rent/population/changing_trend/${communityBaseInfoId}`)

// 桂溪街道-租赁纠纷数据统计
export const reportInfo = (communityBaseInfoId) => axios.get(`/big_screen/community/report/info/${communityBaseInfoId}`)

// 桂溪街道-租赁纠纷模态框列表
export const reportList = (communityBaseInfoId) => axios.get(`/big_screen/community/report/list/${communityBaseInfoId}`)

// 桂溪街道-租赁纠纷上报趋势
export const reportTrend = (communityBaseInfoId) => axios.get(`/big_screen/community/report/trend/${communityBaseInfoId}`)

// 桂溪街道-预警详情处理记录
export const disposeDetail = (id) => axios.get(`/big_screen/warn/community/house/detail?id=${id}`)


// 桂溪街道-社区租赁经营主体分布
export const institutionInfo = (communityBaseInfoId) => axios.get(`/big_screen/community/institution/${communityBaseInfoId}`)

// 桂溪街道-社区租赁经营主体分布模态框列表
export const institutionList = (communityBaseInfoId, institutionType) => axios.get(`/big_screen/community/institution/list/${communityBaseInfoId}/${institutionType}`)

// 桂溪街道-租赁平台运营数据
export const platformInfo = (communityBaseInfoId) => axios.get(`/big_screen/community/platform/operation/${communityBaseInfoId}`)

// 桂溪街道-诉求事件处理统计
export const handleData = communityBaseInfoId => axios.get(`/big_screen/community/affairs/handle/${communityBaseInfoId}`)

// 桂溪街道-诉求事件滚动列表
export const scrollList = (communityBaseInfoId, type) => axios.get(`/big_screen/community/affairs/handleList/${communityBaseInfoId}/${type}`)

// 桂溪街道-诉求事件租赁纠纷处理记录
export const leaseHistory = id => axios.get(`/big_screen/community/house/reports/lease/${id}`)

// 桂溪街道-扰民投诉和其他报事
export const otherHistory = id => axios.get(`/big_screen/community/house/reports/comp/${id}`)

// 桂溪街道-报事记录
export const reportHistory = id => axios.get(`/big_screen/community/house/reports/auditRecords/${id}`)

// 诉求事件上报统计
export const demandsReportAsync = communityBaseInfoId => axios.get(`/big_screen/community/affairs/statistics/${communityBaseInfoId}`)

// 诉求事件上报统计列表
export const demandsList = communityBaseInfoId => axios.get(`/big_screen/community/affairs/list/${communityBaseInfoId}`)

// 诉求事件上报趋势
export const demandsTrend = communityBaseInfoId => axios.get(`/big_screen/community/affairs/trend/${communityBaseInfoId}`)

// 桂溪街道-搬入搬离柱状图
export const reportComp = data => axios.post('/big_screen/street/people/source/report', data)

// 桂系街道-搬入搬离模态框列表
export const sourcePeople = data => axios.post('/big_screen/street/community/people/source/table', data)

// 桂溪街道-搬入搬离模态框列表详情表格
export const detailList = data => axios.post('/big_screen/street/community/people/source/detail/table', data)

// 【社区大屏】单个预警详情
export const getWarnDetail = id => axios.get(`/big_screen/warn/detail/${id}`)

import moment from "moment"
import { useEffect, useRef, useState } from "react"

const useDate = () => {
    const timer = useRef()

    // 当前时间
    const [currentTime, setCurrentTime] = useState("")
    const [currentDate, setCurrentDate] = useState("")

    const getNowDate = () => {
        const now = moment()
        setCurrentTime(now.format("HH:mm:ss"))
        setCurrentDate(now.format("YYYY年MM月DD日"))
    }

    useEffect(() => {
        if (timer.current) {
            clearInterval(timer.current)
            timer.current = null
        }
        timer.current = setInterval(() => {
            getNowDate()
        }, 1000)

        return () => {
            clearInterval(timer.current)
            timer.current = null
        }
    }, [])

    return [currentDate, currentTime]
}
export default useDate

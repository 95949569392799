/*
 * @Author: 刘芳
 * @Date: 2022-08-07 15:33:54
 * @LastEditTime: 2022-11-16 09:45:02
 * @FilePath: \community-big-screen\src\views\community\components\houseClearList\index.fhd.js
 */
import React, { useState, forwardRef, useImperativeHandle, useRef, useEffect } from 'react'
import classNames from 'classnames'
import {
    buildingStatistics,
    getVirusList,
    registrationDetail
} from "../../../../apis/community"
import VirusInfo from '../virusInfo'
import Close from '../../imgs/close.png'
import './index.fhd.scss'
import ListModal from '../../../../components/ListModal/ListModal'

const WarningList = forwardRef((props, ref) => {
    const [visible, setVisible] = useState(false);
    const [selectDay, setSelectDay] = useState(1);
    const [buildingStatisticsList, setbuildingStatisticsList] = useState([]);
    const [eventType, seteventType] = useState('');
    const [communityId, setcommunityId] = useState('');
    const virusRef = useRef()
    const hideWarningList = () => {
        setVisible(false);
    }
    useImperativeHandle(ref, () => ({
        open(communityId, eventType) {
            if (!visible) {
                setVisible(true)
            }
            if (eventType === 'clear') {
                getbuildingStatistics(1, communityId)
            } else if (eventType === 'unClear') {
                getVirusListFun(1, communityId)
            } else {
                getRegistrationDetail(1, communityId)
            }
            seteventType(eventType);
            setcommunityId(communityId);
            virusRef?.current?.close()
            if (eventType === 'clear' || eventType === 'unClear') {
                setdateQuery([
                    {
                        title: '今日',
                        days: 1,
                        active: true
                    },
                    {
                        title: '近3日',
                        days: 3,
                        active: false

                    },
                    {
                        title: '近7日',
                        days: 7,
                        active: false
                    },
                ])

            } else {
                setdateQuery2([
                    {
                        title: '今日',
                        days: 1,
                        active: true
                    },
                    {
                        title: '昨日',
                        days: 2,
                        active: false

                    }
                ])
            }

        },
        close() {
            setVisible(false)
            virusRef?.current?.close()
        }
    }))

    const changeSelectDay = (day) => {
        setSelectDay(day)
        if (eventType === 'clear') {
            getbuildingStatistics(day)
        } else if (eventType === 'unClear') {
            getVirusListFun(day)
        } else {
            getRegistrationDetail(day)
        }
        if (eventType === 'clear' || eventType === 'unClear') {
            dateQuery.forEach(v => {
                v.active = v.days === day
            });
            setdateQuery(dateQuery)
        } else {
            dateQuery2.forEach(v => {
                v.active = v.days === day
            });
            setdateQuery2(dateQuery2)
        }
    }
    const getbuildingStatistics = async (day, id) => {
        const { data } = await buildingStatistics(id ? id : communityId, day);
        setbuildingStatisticsList(data)
    }
    const getVirusListFun = async (day, id) => {
        const { data } = await getVirusList(id ? id : communityId, 0, day);
        setbuildingStatisticsList(data)
    }
    // 大屏-核酸登记小区数据详情
    const getRegistrationDetail = async (day, id) => {
        const { data } = await registrationDetail(id ? id : communityId, day);
        setbuildingStatisticsList(data)
    }
    const selectHouse = (unitId, type) => {
        props?.rightonGetUnit(unitId, type);
    }
    const openVirusDetail = row => {
        virusRef.current.open(row)
    }
    // useEffect(() => {
    //     let timer = 0
    //     timer = setTimeout(() => {
    //         if (eventType !== 'clear' && eventType !== 'unClear') {
    //             const header = document.getElementById('header');
    //             const listItem = document.getElementById('listItem');
    //             header.addEventListener('scroll', () => {
    //                 listItem.scrollLeft = header.scrollLeft
    //             })
    //         }
    //     }, 1000)
    //     return () => {
    //         clearTimeout(timer)
    //     }
    // }, [eventType])
    // const inner = () => {
    //     const list = document.getElementById('list');
    //     const header = document.getElementById('header');
    //     const listItem = document.getElementById('listItem');
    //     window.onmousewheel = document.onmousewheel = (e) => {
    //         if (list) {
    //             if (e.wheelDelta < 0) {
    //                 header.scrollLeft = header.scrollLeft + 20
    //                 listItem.scrollLeft = listItem.scrollLeft + 20
    //             } else if (e.wheelDelta > 0) {
    //                 header.scrollLeft = header.scrollLeft - 20
    //                 listItem.scrollLeft = listItem.scrollLeft - 20
    //             }

    //         }
    //     }
    // }
    // const out = () => {
    //     const header = document.getElementById('header');
    //     const listItem = document.getElementById('listItem');
    //     window.onmousewheel = document.onmousewheel = (e) => {
    //     }
    //     header.addEventListener('scroll', () => {
    //         listItem.scrollLeft = header.scrollLeft
    //     })
    // }
    const title = [
        {
            name: '楼栋单元',
            width: 160
        },
        {
            name: '居住人数',
            width: 250
        },
        {
            name: '已打卡房屋数',
            width: 270
        },
        {
            name: '已打卡人数',
            width: 180
        },
    ]
    const title2 = [
        {
            name: '姓名',
            width: 160
        },
        {
            name: '联系电话',
            width: 250
        },
        {
            name: '证件号码',
            width: 260
        },
        {
            name: '最近一次打卡时间',
            width: 180
        },
    ]
    const [dateQuery, setdateQuery] = useState([
        {
            title: '今日',
            days: 1,
            active: true
        },
        {
            title: '近3日',
            days: 3,
            active: false

        },
        {
            title: '近7日',
            days: 7,
            active: false
        },
    ])
    const [dateQuery2, setdateQuery2] = useState([
        {
            title: '今日',
            days: 1,
            active: true
        },
        {
            title: '昨日',
            days: 2,
            active: false

        }
    ])
    let title3 = [
        { name: '楼栋单元', width: 143 },
        { name: '居民数', width: 101 },
        { name: '登记居民数', width: 142 },
        { name: '登记户数', width: 159 },
        { name: '居民登记率', width: 158 },
        { name: '房屋登记率', width: 159 },
        { name: '有居民户数', width: 159 },
        { name: '登记居民日环比', width: 158 },
        { name: '登记户数日环比', width: 192 },
        { name: '全部户数', width: 126 },
        { name: '有码居民数', width: 126 },
        { name: '有码居民率', width: 159 },
    ]

    return (
        visible ?

            <div className={classNames('warningList ')} style={{ 'width': eventType === 'clear' || eventType === 'unClear' ? '960px' : '1840px' }}>
                {
                    eventType === 'clear' || eventType === 'unClear' ?
                        <ListModal
                            height='88vh'
                            width={960}
                            title={eventType === 'clear' ? title : eventType === 'unClear' ? title2 : ''}
                            dateChoose={true}
                            dateQuery={dateQuery}
                            selectDate={changeSelectDay}
                            closeModal={hideWarningList}
                        >
                            <div className={classNames(' flex fd-c')} >
                                {/* <img
                                 onClick={hideWarningList}
                                 src={Close}
                                 alt="err"
                                 className="close-img"
                             /> */}
                                {/* <div className={classNames('head ai-c flex')}>
                                 {
                                     eventType === 'clear' ?
                                         <>
                                             <div className={classNames('item fs-16 pl-4 pr-16')} style={{ width: '160px' }}>楼栋单元</div>
                                             <div className={classNames('item fs-16 pl-4 pr-16')} style={{ width: '270px' }}>居住人数</div>
                                             <div className={classNames('item fs-16 pl-4 pr-16')} style={{ width: '270px' }}>已打卡房屋数</div>
                                             <div className={classNames('item fs-16 pl-4 pr-16')}>已打卡人数</div>
                                         </>
                                         : eventType === 'unClear' ? <>
                                             <div className={classNames('item fs-16 pl-4 pr-16')} style={{ width: '160px' }}>姓名</div>
                                             <div className={classNames('item fs-16 pl-4 pr-16')} style={{ width: '270px' }}>联系电话</div>
                                             <div className={classNames('item fs-16 pl-4 pr-16')} style={{ width: '270px' }}>证件号码</div>
                                             <div className={classNames('item fs-16 pl-4 pr-16')}>最近一次打卡时间</div>
                                         </> :
                                             <>
                                             </>
 
                                 }
 
                             </div> */}
                                {/* <div className={classNames('btns flex ai-c pd-tb-4')}>
                                 <div className={classNames('days flex ai-c ml-12')}>
                                     <div className={classNames('day trigger', selectDay === 1 ? 'active' : '')} onClick={() => changeSelectDay(1)}>今日</div>
                                     <div className={classNames('day trigger', selectDay === 3 ? 'active' : '')} onClick={() => changeSelectDay(3)}>近3日</div>
                                     <div className={classNames('day trigger', selectDay === 7 ? 'active' : '')} onClick={() => changeSelectDay(7)}>近7日</div>
                                 </div>
                             </div> */}
                                <div className={classNames('list flex-1')}>
                                    {
                                        eventType === 'clear' ?
                                            buildingStatisticsList?.map(item => {
                                                return (
                                                    <div className={classNames('item flex ai-c')} style={{ cursor: 'pointer' }} onClick={() => selectHouse(item?.unitId)}>
                                                        <div className={classNames('fs-16 pl-4 pr-16 pt-3 pb-3')} style={{ width: '160px' }}>{item?.buildingName}</div>
                                                        <div className={classNames('fs-16 pl-4 pr-16 pt-3 pb-3')} style={{ width: '250px' }}>{item?.livePeopleCount}</div>
                                                        <div className={classNames('fs-16 pl-4 pr-16 pt-3 pb-3')} style={{ width: '270px' }}>{item?.houseCount}</div>
                                                        <div className={classNames('fs-16 pl-4 pr-16 pt-3 pb-3')} style={{ width: '180px' }}>{item?.peopleCount}</div>
                                                    </div>
                                                )
                                            }) : eventType === 'unClear' ?
                                                buildingStatisticsList?.map(item => {
                                                    return (
                                                        <div className={classNames('item flex ai-c')} style={{ cursor: 'pointer' }} onClick={() => openVirusDetail({
                                                            houseId: 0,
                                                            userName: item.userNameHidden,
                                                            userCardNumber: item.userCardNumberHidden
                                                        })}>
                                                            <div className={classNames('fs-16 pl-4 pr-16 pt-3 pb-3')} style={{ width: '160px' }}>{item?.userName}</div>
                                                            <div className={classNames('fs-16 pl-4 pr-16 pt-3 pb-3')} style={{ width: '250px' }}>{item?.userPhone}</div>
                                                            <div className={classNames('fs-16 pl-4 pr-16 pt-3 pb-3')} style={{ width: '270px' }}>{item?.userCardNumber}</div>
                                                            <div className={classNames('fs-16 pl-4 pr-16 pt-3 pb-3')} style={{ width: '180px' }}>{item?.lastTime}</div>
                                                        </div>
                                                    )
                                                }) : ''

                                    }
                                </div>
                            </div>
                            <VirusInfo top={'93px'} left={'25.5vw'} ref={virusRef}></VirusInfo>
                        </ListModal> :
                        <ListModal
                            height='88vh'
                            width={1840}
                            title={title3}
                            dateChoose={true}
                            dateQuery={dateQuery2}
                            selectDate={changeSelectDay}
                            closeModal={hideWarningList}
                        >
                            <div id='list' className={classNames('other flex fd-c')} >
                                {/* <div className='header flex'> */}
                                {/* <img
                                        onClick={hideWarningList}
                                        src={Close}
                                        alt="err"
                                        className="close-img"
                                    /> */}
                                {/* <div id="header" className={classNames('head flex-1')}>
                                        {
                                            tableHeader.map((v, i) => {
                                                return <div className={classNames('fs-16 pl-4 pr-16')} style={{ width: i === 0 ? '160px' : '112px', display: 'inline-block' }} key={v}>{v}</div>
                                            })
                                        }
                                    </div> */}
                                {/* <div className={classNames('btns flex ai-c pd-tb-4')}>
                                        <div className={classNames('days flex ai-c ml-12')}>
                                            <div className={classNames('day trigger', selectDay === 1 ? 'active' : '')} onClick={() => changeSelectDay(1)}>今日</div>
                                            <div className={classNames('day trigger', selectDay === 2 ? 'active' : '')} onClick={() => changeSelectDay(2)}>昨日</div>
                                        </div>
                                    </div> */}
                                {/* </div> */}
                                <div className={classNames('list flex-1 flex')}>
                                    <div id="listItem" className='listItem flex-1'>
                                        {
                                            buildingStatisticsList?.map(item => {
                                                return (
                                                    <div className={classNames('item')} style={{ cursor: 'pointer' }} onClick={() => selectHouse(item?.unitId, 2)}>
                                                        <div className={classNames('fs-16 pl-4 pr-16 pt-3 pb-3')} style={{ width: '143px', display: 'inline-block' }}>{item?.buildingUnitName}</div>
                                                        <div className={classNames('fs-16 pl-4 pr-16 pt-3 pb-3')} style={{ width: '101px', display: 'inline-block' }}>{item?.totalResidentCount}</div>
                                                        <div className={classNames('fs-16 pl-4 pr-16 pt-3 pb-3')} style={{ width: '142px', display: 'inline-block' }}>{item?.registrationResidentCount}</div>
                                                        <div className={classNames('fs-16 pl-4 pr-16 pt-3 pb-3')} style={{ width: '159px', display: 'inline-block' }}>{item?.registrationResidentRatio}</div>
                                                        <div className={classNames('fs-16 pl-4 pr-16 pt-3 pb-3')} style={{ width: '158px', display: 'inline-block' }}>{item?.registrationResidentDayRingRatio}</div>
                                                        <div className={classNames('fs-16 pl-4 pr-16 pt-3 pb-3')} style={{ width: '159px', display: 'inline-block' }}>{item?.totalHouseCount}</div>
                                                        <div className={classNames('fs-16 pl-4 pr-16 pt-3 pb-3')} style={{ width: '159px', display: 'inline-block' }}>{item?.totalResidentHouseCount}</div>
                                                        <div className={classNames('fs-16 pl-4 pr-16 pt-3 pb-3')} style={{ width: '158px', display: 'inline-block' }}>{item?.registrationHouseCount}</div>
                                                        <div className={classNames('fs-16 pl-4 pr-16 pt-3 pb-3')} style={{ width: '192px', display: 'inline-block' }}>{item?.registrationHouseRatio}</div>
                                                        <div className={classNames('fs-16 pl-4 pr-16 pt-3 pb-3')} style={{ width: '126px', display: 'inline-block' }}>{item?.registrationHouseDayRingRatio}</div>
                                                        <div className={classNames('fs-16 pl-4 pr-16 pt-3 pb-3')} style={{ width: '126px', display: 'inline-block' }}>{item?.hasCodeResidentCount}</div>
                                                        <div className={classNames('fs-16 pl-4 pr-16 pt-3 pb-3')} style={{ width: '159px', display: 'inline-block' }}>{item?.hasCodeResidentRatio}</div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <div style={{ width: '57px' }}></div>
                                </div>
                            </div>

                            <VirusInfo top={'93px'} left={'25.5vw'} ref={virusRef}></VirusInfo>
                        </ListModal>
                }

            </div> : ''
    )
})

export default WarningList

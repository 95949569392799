import React, { useState, forwardRef, useImperativeHandle } from 'react'
import Close from '../../imgs/close.png'
import './index.fhd.scss'
const ResidentPeople = forwardRef((props, ref) => {
    const [visible, setVisible] = useState(false)
    const [list, setList] = useState({})
    const hide = () => {
        setVisible(false)
    }
    useImperativeHandle(ref, () => ({
      open (list) {
        setList(list)
            if (!visible) {
                setVisible(true)
            }
        },
        close() {
            setVisible(false)
        }
    }))
    return visible ? (
        <div className="resident-people">
            <img onClick={hide} src={Close} alt="err" className="close-img" />
            <div className="report-title">
                <span className="title-text">历史居民</span>
            </div>
            <div className="modal-content">
                <div className="resident-list">
                    {list.map((item, ix) => (
                        <div className="list-row">
                            <span className="row-name">
                                {item.mosaicPhone
                                    ? item.name + ' | ' + item.mosaicPhone
                                    : item.name}
                            </span>
                            <span>{item.statusMemo}</span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    ) : null
})

export default ResidentPeople

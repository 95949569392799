// 社区id
// export const communityId = "1506221716040622080"
// 优客里邻+ 月牙湖社区id：1502171912507531264
// 云上月牙   月牙湖社区id：1518836506768482304

const { REACT_APP_ENV } = process.env

const communityIds = {
    prod: "1518836506768482304", // 月牙湖社区
    // prod: "1576169649732236374", // 大源社区
    // prod: "1576169649732231366", // 和平社区
    // prod: "1574644582066724864", // 天华社区
    // prod: "1631542615152566272", // 临江社区
    // prod: "1531843866948575232", // 三瓦窑社区
    // prod: "1631542615152566273", // 科创社区
    // prod: "1587275077497692160", // 昆华社区
    // prod: "1550366752017522688", // 永安社区
    // prod: "1551745096491507712", // 益州社区
    // prod: "1535175001036333056", // 南新社区
    // prod: "1576169649732231280", // 吉泰社区
    // prod: "1576169649732231392", // 交子公园社区
    test: "1", // 墨世社区
    dev: "1518836506768482304", // 本地开发id
    // dev: "1", // 本地开发id
}
const communityName = {
    prod: "月牙湖社区"
    // prod: "大源社区",
    // prod: "和平社区",
    // prod: "天华社区",
    // prod: "临江社区",
    // prod: "三瓦窑社区",
    // prod: "科创社区",
    // prod: "昆华社区",
    // prod: "永安社区",
    // prod: "益州社区",
    // prod: "南新社区",
    // prod: "吉泰社区",
    // prod: "交子公园社区",
}
export const defaultCommunityId = communityIds[REACT_APP_ENV]
export const defaultCommunityName = communityName?.[REACT_APP_ENV]
// 高德key
export const aMapKey = "4e7f77246d4d55a60bbc47c9883405e7"

// 数据更新周期 ms
export const updateCycle = 3600000 // 一小时 3600000

// 网格轮动周期 ms
export const scrollSpeed = 30000

export const tooltipConfig = {
    domStyles: {
        "g2-tooltip": {
            background: "rgba(5, 24, 50, 0.8)",
            boxShadow: " 0px 0px 16px 0px rgba(41, 102, 204, 0.16)",
            backdropFilter: " blur(2px)",
        },
        "g2-tooltip-title": {
            color: "#fff",
        },
        "g2-tooltip-name": {
            color: "#D0DEEE",
        },
        "g2-tooltip-value": {
            color: "#fff",
        },
    },
}

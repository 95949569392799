import React, { forwardRef, useImperativeHandle, useState } from 'react'
import style from './Statistics.fhd.module.scss'
import people from '../../../assets/home/people.png'
import thing from '../../../assets/home/thing.png'
import save from '../../../assets/home/save.png'
import gou from "../../../assets/gou.png"
import StatisticsModal from '../../../components/StatisticsModal/StatisticsModal'
import ListModal from 'components/ListModal/ListModal'
import { statisticsNum, statisticsEventType, warnDetail } from '../../../apis/community'
import { useData } from '../../../hooks'
import classNames from 'classnames'
import { useEffect } from 'react'
import { useContext } from 'react'
import appContext from 'App.context'

export default forwardRef(function Statistics({ openModal }, ref) {
    const { communeId } = useContext(appContext)
    const [data] = useData(statisticsNum, communeId)
    const [listShow, setListShow] = useState(false)
    const [typeList, setTypeList] = useState([])
    // 用作筛选的列表数据
    const [filterList, setFilterList] = useState([])
    // 单个数据详情
    const [warnData, setWarnData] = useState()
    const [detailShow, setDetailShow] = useState(false)

    // 模态框标题
    const [title] = useState([
        { name: '提示类型', width: 180 },
        { name: '提示内容', width: 270 },
        { name: '当前状态', width: 127, select: true },
        { name: '所属小区', width: 140 },
        { name: '提示日期', width: 150 },
    ])
    const [spanWidth, setSpanWidth] = useState()
    // 模态框标题的状态列表
    const [selectList] = useState([
        { label: '已处理', type: 2 },
        { label: '处理中', type: 1 },
        { label: '待处理', type: 0 },
    ])
    useEffect(() => {
        let width = []
        title.forEach((s, i) => {
            width[i] = s.width
        })
        setSpanWidth(width)
    }, [title])

    // 点击打开预警列表模态框
    const showModal = async (eventType, down) => {
        const { data } = await statisticsEventType(communeId, eventType)
        setListShow(true)
        openModal('Statistics')
        setTypeList(data)
        if (down === 'down') {
            setFilterList(data?.filter(s => s.warnStatus == 2))
        } else if (down === 'on') {
            setFilterList(data?.filter(s => s.warnStatus !== 2))
        }
    }

    // 筛选当前状态   当时有筛选全部，现在取消了，方法先留着不动
    const filterType = async (type) => {
        setFilterList(typeList?.filter(s => {
            if (s.warnStatus != 3) {
                return s.warnStatus == type
            } else {
                return s.warnStatus != 3
            }
        }))
    }

    // 打开单个详情页面
    const showDetail = async (id) => {
        const { data } = await warnDetail(id)
        setWarnData(data)
        setDetailShow(true)
    }

    // 关闭单个详情页面
    const closeDetailModal = () => {
        setDetailShow(false)
    }

    // 关闭模态框
    const closeModal = () => {
        closeDetailModal()
        setListShow(false)
    }

    useImperativeHandle(ref, () => ({
        closeModal
    }), [])

    return (
        <div className={style.container}>
            <div className={style.item}>
                <div className={style.img}>
                    <img src={people} alt="" />
                    <span>社区人口</span>
                </div>
                <div className={style.content}>
                    <div>
                        <div>
                            <div onClick={() => {
                                showModal(2,'on')
                            }}>
                                <span className={style.span1}>{data?.populationReportAmount}</span><span className={style.span2}>条</span>
                            </div>
                            <div className={style.down} onClick={() => {
                                showModal(2, 'down')
                            }}>
                                <img src={gou} alt="" />
                                <span>{data?.populationReportProcessedAmount}</span>
                                <span>条</span>
                            </div>
                        </div>
                        <span className={style.item_title}>人口上报过多</span>
                    </div>
                    <div className={style.mt}>
                        <div>
                            <div onClick={() => {
                                showModal(9,'on')
                            }} >
                                <span className={style.span1}>{data?.populationLessAmount}</span><span className={style.span2}>条</span>
                            </div>
                            <div className={style.down} onClick={() => {
                                showModal(9, 'down')
                            }} >
                                <img src={gou} alt="" />
                                <span>{data?.populationLessProcessedAmount}</span>
                                <span>条</span>
                            </div>
                        </div>
                        <span className={style.item_title}>人口上报过少</span>
                    </div>
                </div>
            </div>
            <div className={style.item}>
                <div className={style.img}>
                    <img src={thing} alt="" />
                    <span>社区事件</span>
                </div>
                <div className={style.content}>
                    <div>
                        <div>
                            <div onClick={() => {
                                showModal(5,'on')
                            }}>
                                <span className={style.span1}>{data?.reportBurstAmount}</span><span className={style.span2}>条</span>
                            </div>
                            <div className={style.down} onClick={() => {
                                showModal(5, 'down')
                            }}>
                                <img src={gou} alt="" />
                                <span>{data?.reportBurstProcessedAmount}</span>
                                <span>条</span>
                            </div>
                        </div>
                        <span className={style.item_title}>诉求上报爆发</span>
                    </div>
                    <div className={style.mt}>
                        <div>
                            <div onClick={() => {
                                showModal(4,'on')
                            }}>
                                <span className={style.span1}>{data?.reportHandleOvertimeAmount}</span><span className={style.span2}>条</span>
                            </div>
                            <div className={style.down} onClick={() => {
                                showModal(4, 'down')
                            }}>
                                <img src={gou} alt="" />
                                <span>{data?.reportHandleOvertimeProcessedAmount}</span>
                                <span>条</span>
                            </div>
                        </div>
                        <span className={style.item_title}>事件处理超时</span>
                    </div>
                </div>
            </div>
            <div className={style.item}>
                <div className={style.img}>
                    <img src={save} alt="" />
                    <span>社区安全</span>
                </div>
                <div className={style.content}>
                    <div>
                        <div>
                            <div onClick={() => {
                                showModal(1,'on')
                            }}>
                                <span className={style.span1}>{data?.groupRentalAmount}</span><span className={style.span2}>条</span>
                            </div>
                            <div className={style.down} onClick={() => {
                                showModal(1, 'down')
                            }}>
                                <img src={gou} alt="" />
                                <span>{data?.groupRentalProcessedAmount}</span>
                                <span>条</span>
                            </div>
                        </div>
                        <span className={style.item_title}>疑似群租房</span>
                    </div>
                    <div className={style.mt}>
                        <div>
                            <div onClick={() => {
                                showModal(7,'on')
                            }}>
                                <span className={style.span1}>{data?.gasAmount}</span><span className={style.span2}>条</span>
                            </div>
                            <div className={style.down} onClick={() => {
                                showModal(7, 'down')
                            }}>
                                <img src={gou} alt="" />
                                <span>{data?.gasProcessedAmount}</span>
                                <span>条</span>
                            </div>
                        </div>
                        <span className={style.item_title}>燃气隐患房屋</span>
                    </div>
                </div>
            </div>
            {/* 模态框 */}
            {listShow ? <div className={style.modal}>
                <ListModal
                    height={856}
                    width={900}
                    closeModal={closeModal}
                    filterType={filterType}
                    title={title}
                    selectList={selectList}
                >
                    <ul className={style.modalContent}>
                        {filterList?.map(s => {
                            return (
                                <li key={s.id} onClick={() => {
                                    showDetail(s.id)
                                }}>
                                    <span style={{ width: spanWidth[0] }}>{s.warnType === 1 ? '疑似群租房' : s.warnType === 2 ? '人口上报过多' : s.warnType === 4 ? '事件处理超时' : s.warnType === 5 ? '诉求上报爆发' : s.warnType === 7 ? '燃气隐患房屋' : s.warnType === 8 ? '人口流出爆发' : '人口上报过少'}</span>
                                    <span style={{ width: spanWidth[1], paddingRight: '20px' }}>{s.warnDesc}</span>
                                    <span style={{ width: spanWidth[2] }} className={classNames(s.warnStatus === 0 ? style.statusOn : s.warnStatus === 1 ? style.statusIn : style.down)}>{s.warnStatus === 0 ? '待处理' : s.warnStatus === 1 ? '处理中' : '已处理'}</span>
                                    <span style={{ width: spanWidth[3] }}>{s.communityInfoName ? s.communityInfoName : '--------'}</span>
                                    <span style={{ width: spanWidth[4] }}>{s.warnTime}</span>
                                </li>
                            )
                        })}
                    </ul>
                </ListModal>
            </div> : null}
            {detailShow ? (<div className={style.detailModal}><StatisticsModal warnData={warnData} closeModal={closeDetailModal}></StatisticsModal></div>) : null}
        </div>
    )
})

import Container from "./components/Container/Container"
import appContext, { appContextMethods } from "./App.context"
import { useEffect, useMemo, useState } from "react"
import { defaultCommunityId, updateCycle } from "./config"
import { HashRouter, Route, Routes } from "react-router-dom"
import Home from "./views/home/Home"
// import Login from 'views/login'

import Community from "./views/community/Community"
// import { useData } from "./hooks"
import { ConfigProvider } from "antd"
import zhCN from "antd/lib/locale/zh_CN"

const App = () => {
    // 大屏数据切换：房源/人口 population
    const [type, setType] = useState("population")

    // 更新时间
    const [updateTime, setUpdateTime] = useState()

    // 所有网格

    // 当前网格
    const [grid, setGrid] = useState()

    // 当前选中网格
    const [checkedGridID, setCheckedGridID] = useState(null)

    const contextValues = useMemo(() => {
        const communeId = getCommunityIdFromSearch() || defaultCommunityId

        return { type, updateTime, grid, checkedGridID, communeId }
    }, [type, updateTime, grid, checkedGridID])

    const contextMethods = useMemo(
        () => ({
            changeType: setType,
            setGrid,
            setCheckedGridID,
        }),
        []
    )

    useEffect(() => {
        setUpdateTime(new Date())

        let timer = setInterval(() => {
            setUpdateTime(new Date())
        }, updateCycle)

        return () => {
            clearInterval(timer)
        }
    }, [])

    return (
        <ConfigProvider locale={zhCN}>
            <appContext.Provider value={contextValues}>
                <appContextMethods.Provider value={contextMethods}>
                    <HashRouter>
                        <Routes>
                            {/* <Route path="/login" element={<Login />} /> */}
                            <Route path="/" element={<Container />}>
                                <Route path="" element={<Home />} />
                                <Route path="/community" element={<Community />} />
                                <Route path="*" element={<Home />} />
                            </Route>
                        </Routes>
                    </HashRouter>
                </appContextMethods.Provider>
            </appContext.Provider>
        </ConfigProvider>
    )
}
export default App

function getCommunityIdFromSearch() {
    const searchString = window.location.href.substring(window.location.href.indexOf("?") + 1)
    if (/[/]|[;]|[?]/.test(searchString)) {
        console.error("search 参数非标准化，请确认")
        return ""
    }
    const params = searchString.split("&")
    const result = {}
    params.forEach((item) => {
        // 简单切割下，不支持value 包含“=”
        const [key, value] = item.split("=")
        result[key] = value
    })
    return result["communeId"]
}

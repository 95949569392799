import { message } from "antd";
import Axios from "axios";

const successCode = 200;
const noAuthCode = 401;
const accountDisabled = 20003;
const roleLoseEfficacy = 20001;
const appURL = process.env.APP_URL
const axios = Axios.create({
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
    RequestSource: "management",
  },
});

axios.interceptors.request.use((config) => {
  config.url = `api${config.url}`;
  config.headers.Authorization = localStorage.token ?? "";
  return config;
});
let roleChanged = false;
axios.interceptors.response.use(
  (res) => {
    return new Promise((resolve, reject) => {
      let { data } = res;
      // 错误情况
      if (data?.code !== successCode) {
        switch (data?.code) {
          // 登录过期
          case noAuthCode:
            reject(data);
            window.open(appURL)
            message.warning("登录已过期");
            break;
          // 账号关停
          case accountDisabled:
            reject(data);
            message.warning(data?.message ?? "账号已关停");
            window.open(appURL)
            break;
          // 角色失效
          case roleLoseEfficacy:
            reject(data);
            if (!roleChanged) {
              roleChanged = true;
              message.warning(data?.message ?? "角色权限失效");
              window.open(appURL)
            }

            break;
          default:
            // message.error(data?.message ?? "请求错误,请检查网络后重试");
            reject(data);
        }
      } else {
        // 标准格式判断
        if (!isStandardFormat(data)) {
          console.error("非标准返回格式，请联系后端人员修改");
        }
        resolve(data);
      }
    });
  },
  (error) => {
    console.log(error);
    const res = error.response?.data;
    const msg = res?.message ?? "请求错误,请检查网络后重试";
    return Promise.reject(msg);
  }
);

function isStandardFormat(data) {
  return !!data?.code;
}
export default axios;

import { useContext, useEffect } from 'react'

import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import appContext from '../../App.context'
import backSVG from 'assets/back.svg'
import { useState } from 'react'
// import { useDispatch, useSelector } from 'react-redux'
// import { logout } from 'store/reducers/userSlice'
// import arrowDownIcon from './up.png'
// import HeadIcon from './head.png'
import './ContainerStylesCommon.scss'
import './ContainerStyles.scss'
import { useToken } from './useToken'
import { defaultCommunityName } from 'config'
import { getQuery, resetUrl } from 'utils'

// 时间补零
function fillTime(num) {
    if ((num + '').length === 1) {
        return '0' + num
    }
    return num
}
const Container = ({ children }) => {
    const location = useLocation()
    const navigate = useNavigate()
    useToken()
    const [localDate, setLocalDate] = useState('-')
    const [localTime, setLocalTime] = useState('-')
    // const loginUser = useSelector(state => state.userInfo)

    const { updateTime } = useContext(appContext)

    const iseOtherPage = !['/', '/home'].includes(location?.pathname)

    // 没有登录信息则退回到登录页面
    // useEffect(() => {
    //     const timer = setTimeout(() => {
    //         if (!loginUser.phone) {
    //             navigate('/login', { replace: true })
    //         }
    //     })
    //     return () => {
    //         clearTimeout(timer)
    //     }
    // }, [loginUser, navigate])

    // url上没有token，就跳转到社事通的登录页面
    const getUrlValue = () => {
        const query = getQuery(location?.search)
        if(query?.token){
            sessionStorage.setItem("token",query?.token)
            resetUrl()
        }
        if (!sessionStorage.getItem("token")) {
            window.location.href = "https://street.zl.cdhtgx.com/"
        }
    }

    useEffect(() => {
        getUrlValue()
        const timer = setInterval(() => {
            const date = new Date()
            setLocalDate(
                `${date?.getFullYear()}年${fillTime(
                    date?.getMonth() + 1
                )}月${fillTime(date?.getDate())}日`
            )
            setLocalTime(
                `${fillTime(date?.getHours())}:${fillTime(
                    date?.getMinutes()
                )}:${fillTime(date?.getSeconds())}`
            )
        }, 1000)
        return () => {
            clearInterval(timer)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // 退出登录
    // const logoutHandle = () => {
    //     dispatch(logout())
    // }
    // const [isDrop, setIsDrop] = useState(false)
    // const toggleDrop = () => {
    //     setIsDrop(!isDrop)
    // }
    //   const desPhone = phone => {
    //     typeof phone !== 'string' && (phone = '')
    //     return phone.replace(/^(.{3})(.*)(.{4})$/, '$1****$3')
    // }
    return (
        <div className="root-container">

            <header>
                {/* <img
                    style={{ position: "absolute", left: 0 }}
                    src={process.env.DISPLAY_FORMAT === "FHD" ? header16BG : headerBG}
                    alt=""
                /> */}
                {iseOtherPage && (
                    <span
                        className="back-to-home"
                        onClick={() => {
                            console.log(1)
                            navigate(-1)
                        }}
                    >
                        <img src={backSVG} alt="" width={24} height={24} />
                        返回社区
                    </span>
                )}
                <div className="container-title t-color fm-ysbth">
                    {defaultCommunityName || "桂溪街道"}「租赁智慧治理」大数据可视化平台
                </div>

                {/* {!iseOtherPage && (
                    <div className="user-dropdown">
                        <span onClick={toggleDrop} className="drop-area">
                            <img
                                className="head-icon"
                                src={HeadIcon}
                                alt="err"
                            />
                            <span className='phone-text'>{desPhone(loginUser?.phone)}</span>
                            <img
                                className={
                                    isDrop ? 'dropdown-icon' : 'dropup-icon'
                                }
                                src={arrowDownIcon}
                                alt="err"
                            />
                        </span>
                        {isDrop && (
                            <div className="log-out" onClick={logoutHandle}>
                                退出登录
                            </div>
                        )}
                    </div>
                )} */}

                <span className="update-time">
                    最近更新：
                    <span className="time">
                        {updateTime?.getFullYear()}年
                        {fillTime(updateTime?.getMonth() + 1)}月
                        {fillTime(updateTime?.getDate())}日{' '}
                        {fillTime(updateTime?.getHours())}:
                        {fillTime(updateTime?.getMinutes())}:
                        {fillTime(updateTime?.getSeconds())}
                    </span>
                    <span className='inline-block ml-[4px]'>数据实时更新</span>
                </span>

                <div
                    style={{
                        height: 24,
                        width: 1,
                        background: 'white',
                        position: 'absolute',
                        right: 130,
                        top: 11
                    }}
                ></div>
                {/* 当前时间 */}
                <div className="local-date">
                    <span>{localTime}</span>
                    <span>{localDate}</span>
                </div>
            </header>
            <main>
                <Outlet />
            </main>
        </div>
    )
}

export default Container

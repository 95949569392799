import ReactDOM from 'react-dom/client'
import App from './App'
// import store from 'store'
// import { Provider } from 'react-redux'

import './index.scss'
import './virtual:windi.css'
//    <Provider store={store}>
// </Provider>
ReactDOM.createRoot(document.getElementById('root')).render(<App />)

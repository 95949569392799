import { Column } from "@antv/g2plot"
import { useEffect, useMemo, useRef, useState } from "react"
import { reportComp, sourcePeople, detailList } from "apis/home"
import useDOM from "../../../hooks/useDOM"
import Wrapper from "../../../components/Wrapper/Wrapper"
import { useContext } from "react"
import appContext from "App.context"
import ListModal from "components/ListModal/ListModal"
import "./index.scss"
import classNames from "classnames"
import { useNavigate } from "react-router-dom"
import { each, groupBy } from '@antv/util';

// tabs
const tabs = [
    {
        title: '搬入',
        value: 0
    },
    {
        title: '搬离',
        value: 1
    },
]

const typeMap = {
    1: '线上主动上报',
    2: '线下自助上报',
    3: '租赁机构上报',
    4: '物业上报',
    5: '网格员上报'
}

const PopulationTrend = () => {
    const navigate = useNavigate()
    const [container, getDOM] = useDOM()
    const { communeId } = useContext(appContext)
    // const [dataSource] = useData(populationTrend, communeId)
    const [dataSource, setDataSource] = useState()
    const [rendered, setRendered] = useState(false)
    const columnRef = useRef()

    const legend = [
        { value: '线上主动上报', color: '#25A6FF' },
        { value: '线下自主上报', color: '#10DF9D' },
        { value: '租赁机构上报', color: '#FEB903' },
        { value: '物业上报', color: '#5CE1FF' },
        { value: '网格员上报', color: '#FF711F' },
    ]

    // 模态框列表显示
    const [listShow, setListShow] = useState(false)
    // 图表点击的日期
    const [chartsDate, setChartsDate] = useState()

    // 详情列表弹窗  第二层
    const [detailData, setDetailData] = useState()

    // 获取柱状图数据
    const getData = async (type = 0) => {
        const { data } = await reportComp({
            communityBaseInfoId: communeId,
            peopleStatue: type
        })
        setDataSource(data)
    }
    useEffect(() => {
        getData()
    }, [])

    // tab切换
    const [tabType, setTabType] = useState(0)
    const chooseTab = (value) => {
        setTabType(value)
        getData(value)
        setListShow(false)
    }

    // 模态框标题 流入
    const title = [
        { name: '小区名称', width: 101 },
        { name: '时间', width: 86 },
        { name: '线上主动上报数', width: 127 },
        { name: '线下自助上报数', width: 127 },
        { name: '租赁机构上报数', width: 127 },
        { name: '物业上报数', width: 95 },
        { name: '网格员上报数', width: 111 },
        { name: !tabType ? '流入总数' : '流出总数', width: 127 },
    ]

    // 第二层弹窗
    const twoTitle = [
        { name: '姓名', width: 101 },
        { name: '上报渠道', width: 127 },
        { name: '手机号', width: 127 },
        { name: '身份证号', width: 155 },
        { name: '楼栋单元', width: 95 },
        { name: '房屋号', width: 111 },
        { name: '上报时间', width: 137 },
    ]

    const data = useMemo(() => {
        const first = []
        dataSource?.forEach(s => {
            first.push(
                {
                    "date": s.month,
                    "type": '线上主动上报',
                    "value": tabType === 0 ? s.items?.filter(e => e.type === 1)[0].intoNum : s.items?.filter(e => e.type === 1)[0].outNum,
                }
            )
        })
        const two = []
        dataSource?.forEach(s => {
            two.push(
                {
                    "date": s.month,
                    "type": '线下自主上报',
                    "value": tabType === 0 ? s.items?.filter(e => e.type === 2)[0].intoNum : s.items?.filter(e => e.type === 2)[0].outNum
                }
            )
        })
        const three = []
        dataSource?.forEach(s => {
            three.push(
                {
                    "date": s.month,
                    "type": '租赁机构上报',
                    "value": tabType === 0 ? s.items?.filter(e => e.type === 3)[0].intoNum : s.items?.filter(e => e.type === 3)[0].outNum
                }
            )
        })
        const four = []
        dataSource?.forEach(s => {
            four.push(
                {
                    "date": s.month,
                    "type": '物业上报',
                    "value": tabType === 0 ? s.items?.filter(e => e.type === 4)[0].intoNum : s.items?.filter(e => e.type === 4)[0].outNum
                }
            )
        })
        const five = []
        dataSource?.forEach(s => {
            five.push(
                {
                    "date": s.month,
                    "type": '网格员上报',
                    "value": tabType === 0 ? s.items?.filter(e => e.type === 5)[0].intoNum : s.items?.filter(e => e.type === 5)[0].outNum
                }
            )
        })
        // const six = []
        // dataSource?.forEach(s => {
        //     two.push(
        //         {
        //             "date": s.month,
        //             "type": '其他上报',
        //             "value": s.outNum
        //         }
        //     )
        // })
        return [...first, ...two, ...three, ...four, ...five]
    }, [dataSource])

    const charts = () => {
        const annotations = [];
        each(groupBy(data, 'date'), (values, k) => {
            const value = values.reduce((a, b) => a + b.value, 0);
            annotations.push({
                type: 'text',
                position: [k, value],
                content: `${value}`,
                style: { textAlign: 'center', fontSize: 14, fill: 'rgba(255,255,255)' },
                offsetY: -10,
            });
        });

        const config = {
            data,
            isStack: true,
            xField: 'date',
            yField: 'value',
            width: container.clientWidth,
            height: container.clientHeight,
            padding: [20, 0, 30, 50],
            yAxis: {
                label: {
                    // 数值格式化为千分位
                    formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
                    style: {
                        fill: "white"
                    }
                },
                grid: {
                    line: {
                        style: {
                            opacity: 0.1,
                        }
                    },
                },
                max: 1000,
            },
            xAxis: {
                label: {
                    style: {
                        fill: "white"
                    }
                },
            },
            legend: false,
            colorField: 'type',
            color: ['#25A6FF', '#10DF9D', '#FEB903', '#5CE1FF', '#FF711F'],
            seriesField: 'type',
            tooltip: {
                formatter: (datum) => {
                    setChartsDate(datum.date)
                    datum.name = datum.type
                    return datum;
                },
            },
            annotations,
        }
        if (rendered) {
            columnRef.current.update(config)
            return
        }
        const line = new Column("populationTrend_fhd", config)
        line.render()
        setRendered(true)
        columnRef.current = line
    }

    useEffect(() => {
        if (!container) return
        charts()

    }, [container, data])

    // 列表模态框
    // 获取数据
    const [modalList, setModalList] = useState()
    const getList = async (date) => {
        const { data } = await sourcePeople({
            month: date,
            peopleStatue: tabType
        })
        setModalList(data)
    }
    const closeModal = () => {
        setListShow(false)
    }

    // 详情列表弹窗
    const [detailShow, setDetailShow] = useState(false)
    const getDetailList = async (communityInfoId) => {
        const { data } = await detailList({
            communityInfoId,
            month: chartsDate,
            peopleStatue: tabType
        })
        setDetailData(data)
    }
    const openDetailShow = (communityInfoId) => {
        setDetailShow(true)
        getDetailList(communityInfoId)
    }
    const closeDetailShow = () => {
        setDetailShow(false)
    }

    return (
        <>
            <Wrapper
                height={213}
                className="w-[458px] md:w-[600px]"
                extra={(
                    <div className="w-full flex justify-end">
                        <div className="extra-title h-[28px] w-[160px] flex items-center">
                            {tabs.map(s => {
                                return (
                                    <div
                                        className={classNames(tabType === s.value ? 'active' : '', "flex-1 text-center leading-[28px]")}
                                        key={s.value}
                                        onClick={() => chooseTab(s.value)}
                                    >
                                        {s.title}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                )}
                title="社区租赁人口搬入搬离趋势"
            >
                <div
                    className="height-full flex justify-between"
                // onClick={() => {
                //     openModal()
                // }}
                >
                    <div style={{ width: "74%" }} ref={getDOM} onClick={() => {
                        getList(chartsDate)
                        setListShow(true)
                    }}>
                        <div id="populationTrend_fhd"></div>
                    </div>
                    <ul className="mt-2 mr-2">
                        {legend.map(s => {
                            return (
                                <li key={s.value} className="flex ai-c h-[20px]">
                                    <span style={{ backgroundColor: s.color, width: "8px", height: "8px", borderRadius: "50%", marginRight: '8px' }}></span>
                                    <span className="fs-12">{s.value}</span>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </Wrapper>
            {
                listShow && (
                    <div className="bg-[rgba(0,0,0,0.4)] w-full h-full absolute top-0 left-0 z-20">
                        <div className="box-center">
                            <ListModal width={940} title={title} height={856} closeModal={closeModal}>
                                <ul className="overflow-y" style={{ height: '808px' }}>
                                    {modalList?.map((s, i) => {
                                        return (
                                            <li
                                                className='flex ai-c height-48 trigger m-hover'
                                                key={s.communityInfoId}
                                                onClick={() => {
                                                    // navigate(`/community?communityInfoId=${s.communityInfoId}`)
                                                    openDetailShow(s.communityInfoId)
                                                }}
                                                style={{ backgroundColor: i % 2 === 0 ? 'rgba(78,90,105,0.4)' : '' }}
                                            >
                                                <span className='w-[101px] pl-4 text-ellipesis '>{s.communityName}</span>
                                                <span className='w-[88px] pl-4'>{s.month}</span>
                                                <span className='w-[127px] pl-4'>{s.typeNum1}</span>
                                                <span className='w-[127px] pl-4'>{s.typeNum2}</span>
                                                <span className='w-[127px] pl-4'>{s.typeNum3}</span>
                                                <span className='w-[95px] pl-4'>{s.typeNum4}</span>
                                                <span className='w-[111px] pl-4'>{s.typeNum5}</span>
                                                <span className='w-[127px] pl-4'>{s.totalNum}</span>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </ListModal>
                        </div>
                    </div>
                )
            }
            {
                detailShow && (
                    <div className="box-center">
                        <ListModal width={940} title={twoTitle} height={856} closeModal={closeDetailShow}>
                            <ul className="overflow-y" style={{ height: '808px' }}>
                                {detailData?.map((s, i) => {
                                    return (
                                        <li
                                            className='flex ai-c height-48 trigger m-hover'
                                            key={s.id}
                                            onClick={() => {
                                                // navigate(`/community?communityInfoId=${s.communityInfoId}`)
                                                navigate(`/community?communityInfoId=${s.communityInfoId}&buildingUnitId=${s.buildingUnitId}&houseId=${s.houseId}`)
                                            }}
                                            style={{ backgroundColor: i % 2 === 0 ? 'rgba(78,90,105,0.4)' : '' }}
                                        >
                                            <span className='w-[101px] pl-4 text-ellipesis '>{s.liveName || '-'}</span>
                                            <span className='w-[127px] pl-4'>{typeMap[s.type]}</span>
                                            <span className='w-[127px] pl-4'>{s.livePhone || '-'}</span>
                                            <span className='w-[155px] pl-4'>{s.liveCardId || '-'}</span>
                                            <span className='w-[95px] pl-4'>{s.buildingUnitName || '-'}</span>
                                            <span className='w-[111px] pl-4'>{s.floorHouseName || '-'}</span>
                                            <span className='w-[180px] pl-4'>{s.createTime || '-'}</span>
                                        </li>
                                    )
                                })}
                            </ul>
                        </ListModal>
                    </div>
                )
            }
        </>

    )
}
export default PopulationTrend

import React, { useState, forwardRef, useImperativeHandle } from 'react'
import { getIdSingleRecord } from '../../../../apis/community'
import Close from '../../imgs/close.png'
import Dot from '../../imgs/icon_dian.png'
import './index.fhd.scss'
const MonthRecord = forwardRef((props, ref) => {
    const [visible, setVisible] = useState(false)
    const [detail, setDetail] = useState({})
    const hide = () => {
        setVisible(false)
    }
    useImperativeHandle(ref, () => ({
        open(param) {
            getIdSingleRecord(param.houseId, param.name).then(res => {
                setDetail(res.data)
            })
            if (!visible) {
                setVisible(true)
            }
        },
        close() {
            setVisible(false)
        }
    }))

    return visible ? (
        <div className="resident-month">
            <img onClick={hide} src={Close} alt="err" className="close-img" />
            <div className="report-title">
                <span className="title-text">
                    {detail.name} 丨 {detail.phone}
                </span>
            </div>
            <div className="modal-content">
                {detail?.items?.map((item, ix) => (
                    <div className="record-item" key={ix}>
                        {ix === detail?.items.length - 1 ? null : (
                            <div class="item-tail"></div>
                        )}
                        <div className="record-time">
                            <img src={Dot} alt="err" className="dot-img"></img>
                            <span className="time">
                                登记时间： {item.createTime}
                            </span>
                        </div>
                        <div className="record-content">
                            录入人：
                            {item.employeeName + '  ' + item.employeePhone}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    ) : null
})

export default MonthRecord

/*
 * @Author: 刘芳
 * @Date: 2022-08-01 20:58:03
 * @LastEditTime: 2023-03-13 14:14:02
 * @FilePath: \community-big-screen\src\apis\community.js
 */
import axios from "../request";

// 获取预警列表
export const statisticsAsync = (data) => axios.get(`/big_screen/warn/statistics/list/sq/${data}`)

// 预警事件数量统计
export const statisticsNum = (communityBaseInfoId) => axios.get(`/big_screen/warn/statistics/type/sq/${communityBaseInfoId}`)

// 根据预警事件类型查询社区预警列表
export const statisticsEventType = (communityBaseInfoId, eventType) => axios.get(`/big_screen/warn/statistics/list/sq/${communityBaseInfoId}/${eventType}`)

// 社区预警事件趋势图
export const statisticsSq = (communityBaseInfoId) => axios.get(`/big_screen/warn/community/statistics/month/sq/${communityBaseInfoId}`)

// 获取社区居住房屋数据
export const statisticsData = (communityBaseInfoId) => axios.get(`/big_screen/yyh/type/statistics/${communityBaseInfoId}`)

// 社区居住房屋房态变化趋势图
export const houseTrend = (communityBaseInfoId) => axios.get(`/big_screen/yyh/house/month/trend/${communityBaseInfoId}`)

// 社区租赁主体
export const institution = (communityBaseInfoId) => axios.get(`/big_screen/yyh/user/institution/${communityBaseInfoId}`)

// 社区租赁主体-分布
export const institutionDetail = (type) => axios.get(`/big_screen/yyh/user/institution/list/${type}`)

// 运营数据
export const operationData = (communityBaseInfoId) => axios.get(`/big_screen/yyh/platform/operation/${communityBaseInfoId}`)

// 获取社区人口数据
export const peopleData = (communityBaseInfoId) => axios.get(`/big_screen/yyh/population/statistics/${communityBaseInfoId}`)

// 人口走访数据来源
export const population_visit = (communityBaseInfoId) => axios.get(`/big_screen/yyh/population_visit/${communityBaseInfoId}`)

// 人口周变化趋势
export const week = (communityBaseInfoId) => axios.get(`/big_screen/yyh/population/week/trend/${communityBaseInfoId}`)

// 人口月变化趋势
export const month = (communityBaseInfoId) => axios.get(`/big_screen/yyh/population/month/trend/${communityBaseInfoId}`)

// 租住人口周上报趋势
export const peopleWeek = (communityBaseInfoId) => axios.get(`/big_screen/yyh/flow_people/week/${communityBaseInfoId}`)

// 租住人口月上报趋势
export const peopleMonth = (communityBaseInfoId) => axios.get(`/big_screen/yyh/flow_people/month/${communityBaseInfoId}`)

//yyh
export const statistics = (data) => axios.post('/big_screen/warn/statistics/list', data)

export const getHouses = (unitId) => axios.get(`/big_screen/yyh/community/floor/house/${unitId}`)

export const anotherStatistics = (communityId, unitId) => axios.get(`/big_screen/community/detail/unit/statistics/${communityId}/${unitId}`)
export const getAnotherHouses = (unitId) => axios.get(`/big_screen/community/detail/floor/house/${unitId}`)

//大屏-核酸登记【楼栋单元】
export const getIdHouses = (unitId) => axios.get(`/big_screen/yyh/community/building/nucleic_acid/registration/detail/${unitId}`)
export const getIdStatistic = (unitId) => axios.get(`/big_screen/yyh/community/building/nucleic_acid/registration/statistics/${unitId}`)

// 人口走访任务统计
export const visit_task_statistics = (communityBaseInfoId) => axios.get(`/big_screen/yyh/visit_task_statistics/${communityBaseInfoId}`)

// 社区数据统计
export const house_use_type = (communityBaseInfoId) => axios.get(`/big_screen/yyh/community/house_use_type/${communityBaseInfoId}`)

// 诉求事件类型分布
export const affairsTrend = (communityBaseInfoId) => axios.get(`/big_screen/yyh/reports/trend/${communityBaseInfoId}`)

// 诉求事件处理分布
export const affairs_handle = (communityBaseInfoId) => axios.get(`/big_screen/yyh/affairs_handle/${communityBaseInfoId}`)

// 燃气隐患房屋统计
export const natural_gas = (communityBaseInfoId) => axios.get(`/big_screen/yyh/naturalGas/statics/${communityBaseInfoId}`)

// 诉求事件上报统计
export const affairs_statistics = (communityBaseInfoId) => axios.get(`/big_screen/yyh/affairs_statistics/${communityBaseInfoId}`)

// 诉求事件上报趋势
export const floating_population_changing_trend = (communityBaseInfoId) => axios.get(`/big_screen/yyh/floating_population_changing_trend/${communityBaseInfoId}`)


export const getStatics = (communityId, unitId) => axios.get(`/big_screen/yyh/community/unit/statistics/${communityId}/${unitId}`)
export const getWarnTask = (id) => axios.get(`/big_screen/warn/community/house/${id}`)
export const getPeopleTask = (data) => axios.post('/big_screen/yyh/community/house/upcoming_tasks', data)
export const getReportTask = (data) => axios.post('/big_screen/yyh/community/house/upcoming_reports', data)
export const getHouseReport = (data) => axios.post('/big_screen/yyh/community/house/reports', data)
export const getReportSumary = (data) => axios.post('/big_screen/yyh/community/house/counts', data)
export const getSafetyList = (data) => axios.post('/big_screen/yyh/naturalGas/table', data)

export const houseLabel = (id) => axios.get(`/big_screen/yyh/community/house/label/${id}`)
export const getResident = (id) => axios.get(`/big_screen/yyh/community/resident/${id}`)
export const getRemark = (id) => axios.get(`/big_screen/yyh/community/remarks/${id}`)
export const getUnitList = (id) => axios.get(`/big_screen/yyh/community/building/unit/${id}`)
export const getReportRecord = (id) => axios.get(`/big_screen/yyh/community/house/reports/auditRecords/${id}`)
export const getComplain = (id) => axios.get(`/big_screen/yyh/community/house/reports/comp/${id}`)
export const getRent = (id) => axios.get(`/big_screen/yyh/community/house/reports/lease/${id}`)
export const getMul_pop = (id) => axios.get(`/big_screen/yyh/community/house/reports/mul_pop/${id}`)

//辅助库
export const getHouseInfos = (houseId) => axios.get(`/big_screen/backups/info?houseId=${houseId}`)
export const getChanquanLog = (houseId) => axios.get(`/big_screen/backups/property/logs?houseId=${houseId}`)
export const getJuzhuProperty = (houseId) => axios.get(`/big_screen/backups/rent/live/people/logs?houseId=${houseId}`)
export const getChuzuLog = (houseId) => axios.get(`/big_screen/backups/lessor/logs?houseId=${houseId}`)
export const getChenzuLog = (houseId) => axios.get(`/big_screen/backups/tenant/logs?houseId=${houseId}`)

// 获取小区详情
export const getCommunityDetail = (communityId) => axios.get(`/big_screen/yyh/community/detail/${communityId}`)

// 小区预警事件数量统计
export const getCommunityStatistics = (communityId) => axios.get(`/big_screen/warn/community/statistics/${communityId}`)

// 单元预警事件数量统计
export const getUnitStatistics = (communityId) => axios.get(`/big_screen/warn/community/statistics/all/${communityId}`)

// 小区居住房屋总数
export const getCommunityHouseStatistics = (communityId) => axios.get(`/big_screen/yyh/community/house/statistics/${communityId}`)

// 社区居住人口数据
export const getCommunityPopulationStatistics = (communityId) => axios.get(`/big_screen/yyh/community/population/statistics/${communityId}`)


// 社区预警详情
export const warnDetail = (id) => axios.get(`/big_screen/warn/detail/${id}`)

// 【小区大屏】小区预警事件数量统计
export const getWarnCommunityStatistics = (communityInfoId) => axios.get(`/big_screen/warn/community/statistics/type/${communityInfoId}`)

// 【小区大屏】小区预警事件统计列表 【小区大屏】根据预警事件类型查询小区预警列表
export const getWarnCommunityListXq = (communityInfoId, eventType) => axios.get(`/big_screen/warn/statistics/list/xq/${communityInfoId}${eventType ? '/' + eventType : ''}`)

// 【小区大屏】小区月度趋势图数据
export const getWarnCommunityMonthXq = (communityInfoId) => axios.get(`/big_screen/warn/community/statistics/month/xq/${communityInfoId}`)

export const warnInfo = (id) => axios.get(`/big_screen/warn/community/house/detail?id=${id}`)


//打卡
//record
export const getVirusRecord = (data) => axios.post('/big_screen/yyh/community/acid/house/person/detail', data)
//list
export const getVirusList = (villageId, houseId, days) => axios.get(`/big_screen/yyh/community/acid/list/${villageId}/${houseId}/${days}`)
export const getVirusSumary = (houseId) => axios.get(`/big_screen/yyh/community/acid/count/${houseId}`)
//身份证打卡
export const getIdCheckInfo = (houseId) => axios.get(`/big_screen/yyh/community/nucleic_acid/house/detail?houseId=${houseId}`)
//个人登记记录
export const getIdSingleRecord = (houseId, name) => axios.get(`/big_screen/yyh/community/nucleic_acid/house/detail/record?houseId=${houseId}&name=${name}`)
//核酸登记记录
export const getIdRecords = (data) => axios.post('/big_screen/yyh/community/nucleic_acid/house/record', data)

// 大屏-小区防疫打卡数据统计
export const acidStatistics = (villageId, days) => axios.get(`/big_screen/yyh/community/acid/statistics/${villageId}/${days}`)

// 大屏-防疫打卡楼栋统计
export const buildingStatistics = (villageId, days) => axios.get(`/big_screen/yyh/community/acid/buildingStatistics/${villageId}/${days}`)

// 大屏-核酸登记小区数据统计
export const registrationStatistics = (communityId) => axios.get(`/big_screen/yyh/community/nucleic_acid/registration/statistics/${communityId}`)

// 大屏-核酸登记小区数据详情
export const registrationDetail = (communityId, days) => axios.get(`/big_screen/yyh/community/nucleic_acid/registration/detail/${communityId}/${days}`)

// 小区-租赁人口数据
export const getRentPopulationInfo = (communityId) => axios.get(`/big_screen/community/detail/rent/population/info/${communityId}`)

// 小区-租赁纠纷数据统计
export const getDetailReportInfo = (communityId) => axios.get(`/big_screen/community/detail/report/info/${communityId}`)

// 小区租赁房屋数据
export const getHouseRentData = (communityId) => axios.get(`/big_screen/community/detail/report/house/rent/data/${communityId}`)

// 租赁纠纷列表
export const getReportList = (communityId) => axios.get(`/big_screen/community/detail/report/list/${communityId}`)

// 小区-房屋列表
export const getHouseUseList = (communityId, houseUseTypeQuery) => axios.get(`/big_screen/community/detail/house/Use/type/list/${communityId}?houseUseTypeQuery=${houseUseTypeQuery}`)

// 租赁人口列表数据
export const getPopulationList = (data) => axios.post(`/big_screen/community/detail/rent/population/list`, data)

// 小区诉求事件上报统计
export const getAffairsStatistics = (communityInfoId) => axios.get(`/big_screen/community/detail/affairs/statistics/${communityInfoId}`)

// 小区诉求事件上报统计
export const getAffairsList = (communityInfoId) => axios.get(`/big_screen/community/detail/affairs/list/${communityInfoId}`)

// 小区诉求事件上报统计
export const getHouseSecurity = (houseId) => axios.get(`/big_screen/warn/community/house/security/${houseId}`)
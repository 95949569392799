/*
 * @Author: 刘芳
 * @Date: 2022-07-30 10:47:07
 * @LastEditTime: 2022-11-18 09:33:55
 * @FilePath: \community-big-screen\src\components\Wrapper\Wrapper.jsx
 */
import classNames from "classnames"
import classes from "./Wrapper.module.scss"

const Wrapper = ({
    width,
    height,
    padding,
    styles,
    extra,
    title,
    titleStyle,
    subTitle,
    subTitleBlock,
    className,
    children,
    onClick,
    icon,
    iconStyle
}) => {
    const hasHeader = title || extra
    return (
        <div
            className={classNames(classes.wrapper, className)}
            style={{ width, height, padding: padding ? padding + "px" : null }}
            onClick={onClick}
        >
            {hasHeader && (
                subTitleBlock ?
                    <div className={classNames("flex", "height-18", "ai-c", "jc-b", classes.titleBlock)} style={styles}>
                        <div className="flex fs-20">
                            {icon && <img src={icon} alt="" className={classes.icon} style={iconStyle} />}
                            <div className={classNames("flex-1")}>
                                <div className={classNames('text-ellipesis w-100 md:w-135')}>{title}</div>
                                <div className={classNames('text-ellipesis w-100 md:w-135', classes['sub-title-block'])}>{subTitle}</div>
                            </div>
                        </div>
                        <span>{extra}</span>
                    </div> :
                    <div className={classNames("flex", "height-18", "ai-c", "jc-b", classes.title)} style={styles}>
                        <span className="fs-20" style={titleStyle}>
                            {icon && <img src={icon} alt="" className={classes.icon} style={iconStyle}/>}
                            {title}
                            <span className={classes['sub-title']}>{subTitle}</span>
                        </span>
                        <span className="flex-1">{extra}</span>
                    </div>
            )}
            <div className={classNames("flex-1", classes.children)}>{children}</div>
        </div>
    )
}

export default Wrapper

import classNames from "classnames"
import shang from "../../../assets/home/shang.svg"
import xia from "../../../assets/home/xia.svg"
import { leaseHouse } from "apis/home"
import { houseList } from "apis/home"
import ListModal from "components/ListModal/ListModal"
import { useData } from "../../../hooks"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useContext } from "react"
import appContext from "App.context"

const CommunityIntro = () => {
    const navigate = useNavigate()
    const { communeId } = useContext(appContext)
    const [data] = useData(leaseHouse, communeId)
    const [listShow, setListShow] = useState(false)
    // 模态框数据
    const [modalList, setModalList] = useState()

    const title = [
        { name: '小区名称', width: 172 },
        { name: '租赁房屋数', width: 138 },
        { name: '整租房屋数', width: 138 },
        { name: '合租房屋数', width: 138 },
        { name: '闲置房屋数', width: 138 },
        { name: '未知房屋数', width: 138 },
    ]

    // 获取模态框列表
    const getList = async () => {
        const { data } = await houseList(communeId)
        setModalList(data)
    }

    // 打开模态框
    const openModal = () => {
        setListShow(true)
        getList()
    }

    // 关闭模态框
    const closeModal = () => {
        setListShow(false)
    }

    return (
        <div style={{ display: data?.rentCount ? '' : 'none' }}>
            {/* 头部 */}
            <div className="fs-20 pl-4 flex ai-c trigger" onClick={openModal}>
                <div className="t-color  flex ai-c"><span className="t-Md fs-36">{data?.rentCount}</span>套</div>
                <div className="ml-2">
                    <div className="flex color-4C99FF ai-c">
                        <div className={classNames('fs-14 mr-1', data?.rentCountRatio < 0 ? 'color-10DF9D' : data?.rentCountRatio === 0 ? 't-color' : '')}>{data?.rentCountRatio === 0 ? '-' : data?.rentCountRatio > 0 ? '+' + data?.rentCountRatio + '%' : data?.rentCountRatio + '%'}</div>
                        <img style={{ display: data?.rentCountRatio !== 0 ? "" : 'none' }} className="height-16 width-16" src={data?.rentCountRatio > 0 ? shang : xia} alt="" />
                    </div>
                </div>
            </div>
            {/* 内容 */}
            <div className="pl-3 pr-2 trigger" onClick={openModal}>
                <div className="flex ai-c jc-b">
                    <div className="fs-14 ">整租</div>
                    <div className="flex ai-c">
                        <div className="fs-20 t-Md t-color mr-1">{data?.wholeCount}</div>
                        <div className="flex ai-c jc-e color-4C99FF" style={{ minWidth: '80px' }}>
                            <div className={classNames('fs-14 mr-1', data?.wholeCountRatio < 0 ? 'color-10DF9D' : data?.wholeCountRatio === 0 ? 't-color' : '')}>{data?.wholeCountRatio === 0 ? '-' : data?.wholeCountRatio > 0 ? '+' + data?.wholeCountRatio + '%' : data?.wholeCountRatio + '%'}</div>
                            <img style={{ display: data?.wholeCountRatio !== 0 ? "" : 'none' }} className="height-16 width-16" src={data?.wholeCountRatio > 0 ? shang : xia} alt="" />
                        </div>
                    </div>
                </div>
                <div className="flex ai-c jc-b" style={{ lineHeight: '24px' }}>
                    <div className="fs-14 ">合租</div>
                    <div className="flex ai-c">
                        <div className="fs-20 t-Md t-color mr-1">{data?.jointRentCount}</div>
                        <div className="flex ai-c jc-e  color-4C99FF" style={{ minWidth: '80px' }}>
                            <div className={classNames('fs-14 mr-1', data?.jointRentCountRatio < 0 ? 'color-10DF9D' : data?.jointRentCountRatio === 0 ? 't-color' : '')}>{data?.jointRentCountRatio === 0 ? '-' : data?.jointRentCountRatio > 0 ? '+' + data?.jointRentCountRatio + '%' : data?.jointRentCountRatio + '%'}</div>
                            <img style={{ display: data?.jointRentCountRatio !== 0 ? "" : 'none' }} className="height-16 width-16" src={data?.jointRentCountRatio > 0 ? shang : xia} alt="" />
                        </div>
                    </div>
                </div>
                <div className="flex ai-c jc-b" style={{ lineHeight: '24px' }}>
                    <div className="fs-14 ">闲置</div>
                    <div className="flex ai-c">
                        <div className="fs-20 t-Md t-color mr-1">{data?.unusedCount}</div>
                        <div className="flex ai-c jc-e color-4C99FF" style={{ minWidth: '80px' }}>
                            <div className={classNames('fs-14 mr-1', data?.unusedCountRatio < 0 ? 'color-10DF9D' : data?.unusedCountRatio === 0 ? 't-color' : '')}>{data?.unusedCountRatio === 0 ? '-' : data?.unusedCountRatio > 0 ? '+' + data?.unusedCountRatio + '%' : data?.unusedCountRatio + '%'}</div>
                            <img style={{ display: data?.unusedCountRatio !== 0 ? "" : 'none' }} className="height-16 width-16" src={data?.unusedCountRatio > 0 ? shang : xia} alt="" />
                        </div>
                    </div>
                </div>
                <div className="flex ai-c jc-b" style={{ lineHeight: '24px' }}>
                    <div className="fs-14 ">未知</div>
                    <div className="flex ai-c">
                        <div className="fs-20 t-Md t-color mr-1">{data?.unknownCount}</div>
                        <div className="flex ai-c jc-e color-4C99FF" style={{ minWidth: '80px' }}>
                            <div className={classNames('fs-14 mr-1', data?.unknownCountRatio < 0 ? 'color-10DF9D' : data?.unknownCountRatio === 0 ? 't-color' : '')}>{data?.unknownCountRatio === 0 ? '-' : data?.unknownCountRatio > 0 ? '+' + data?.unknownCountRatio + '%' : data?.unknownCountRatio + '%'}</div>
                            <img style={{ display: data?.unknownCountRatio !== 0 ? "" : 'none' }} className="height-16 width-16" src={data?.unknownCountRatio > 0 ? shang : xia} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            {listShow ? (
                <div className="box-center">
                    <ListModal height={856} width={902} title={title} closeModal={closeModal}>
                        <ul className="overflow-y" style={{ height: '808px' }}>
                            {modalList?.map((s, i) => {
                                return (
                                    <li
                                        className="m-hover height-48 flex ai-c trigger"
                                        style={{ backgroundColor: i % 2 === 0 ? 'rgba(78,90,105,0.4)' : '' }}
                                        key={s.communityInfoId}
                                        onClick={() => {
                                            navigate(`/community?communityInfoId=${s.communityInfoId}`)
                                        }}
                                    >
                                        <span className="w-[172px] pl-4 text-ellipesis">{s.communityName}</span>
                                        <span className="w-[138px] pl-4">{s.rentCount}</span>
                                        <span className="w-[138px] pl-4">{s.wholeCount}</span>
                                        <span className="w-[138px] pl-4">{s.jointRentCount}</span>
                                        <span className="w-[138px] pl-4">{s.unusedCount}</span>
                                        <span className="w-[138px] pl-4">{s.unknownCount}</span>
                                    </li>
                                )
                            })}
                        </ul>
                    </ListModal>
                </div>
            ) : null}
        </div>
    )
}

export default CommunityIntro

import { Line } from "@antv/g2plot"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { houseTrend } from "apis/home"
import useDOM from "../../../hooks/useDOM"
import { useData } from "../../../hooks"
import Wrapper from "../../../components/Wrapper/Wrapper"
import { useContext } from "react"
import appContext from "App.context"
import classNames from "classnames"

const HouseStatusTrend = ({className}) => {
    const { communeId } = useContext(appContext)
    const [container, getDOM] = useDOM()
    const [dataSource] = useData(houseTrend, communeId)
    const [rendered, setRendered] = useState(false)
    const lineRef = useRef()

    const legend = [
        { value: '整租', color: '#10DF9D' },
        { value: '合租', color: '#FEB903' },
        { value: '闲置', color: '#5D7092' },
        { value: '未知', color: '#ec808d' },
    ]

    const data = useMemo(() => {

        const first = []
        dataSource?.forEach(s => {
            first.push(
                {
                    "date": s.statisticsDate,
                    "type": '整租',
                    "value": s.wholeCount
                }
            )
        })
        const two = []
        dataSource?.forEach(s => {
            two.push(
                {
                    "date": s.statisticsDate,
                    "type": '合租',
                    "value": s.jointRentCount
                }
            )
        })
        const three = []
        dataSource?.forEach(s => {
            three.push(
                {
                    "date": s.statisticsDate,
                    "type": '闲置',
                    "value": s.unusedCount
                }
            )
        })
        const four = []
        dataSource?.forEach(s => {
            four.push(
                {
                    "date": s.statisticsDate,
                    "type": '未知',
                    "value": s.unknownCount
                }
            )
        })

        return [...first, ...two, ...three, ...four]
    }, [dataSource])

    const createChart = useCallback(() => {
        if (!container) return

        const config = {
            data,
            xField: 'date',
            yField: 'value',
            width: container.clientWidth,
            height: container.clientHeight,
            padding: [10, 0, 28, 50],
            yAxis: {
                label: {
                    // 数值格式化为千分位
                    formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
                    style: {
                        fill: "white"
                    }
                },
                grid: {
                    line: {
                        style: {
                            opacity: 0.1,
                        }
                    }
                }
            },
            xAxis: {
                label: {
                    style: {
                        fill: "white"
                    }
                }
            },
            legend: false,
            colorField: 'type',
            color: ['#10DF9D', '#FEB903', '#5D7092', '#ec808d'],
            seriesField: 'type',
        }
        if (rendered) {
            lineRef.current.update(config)
            return
        }
        const line = new Line("houseTrend_fhd", config)
        line.render()
        setRendered(true)
        lineRef.current = line
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [container, data])

    useEffect(() => {
        createChart()
    }, [createChart])

    return (
        <Wrapper className={classNames("w-[458px] md:w-[600px]",className)} height={186} title="社区租赁房屋变化趋势">
            <div className="height-full pt-2 flex">
                <div className="height-full" style={{ width: "85%" }} ref={getDOM}>
                    <div id="houseTrend_fhd"></div>
                </div>
                <ul className="ml-4 mt-2.5">
                    {legend.map(s => {
                        return (
                            <li key={s.value} className="flex ai-c mb-1.5">
                                <span style={{ backgroundColor: s.color, width: "8px", height: "8px", borderRadius: "50%", marginRight: '8px' }}></span>
                                <span className="fs-12">{s.value}</span>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </Wrapper>
    )
}
export default HouseStatusTrend

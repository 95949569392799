import React, { useState, forwardRef, useImperativeHandle } from 'react'
import { getVirusRecord } from '../../../../apis/community'
import { Image } from 'antd'
import Close from '../../imgs/close.png'
import Dot from '../../imgs/icon_dian.png'
import './index.fhd.scss'
const VirusInfo = forwardRef((props, ref) => {
    const [visible, setVisible] = useState(false)
    const [detail, setDetail] = useState({})
    const hide = () => {
        setVisible(false)
    }
    useImperativeHandle(ref, () => ({
        open(row) {
            getInfo(row)
            if (!visible) {
                setVisible(true)
            }
        },
        close() {
            setVisible(false)
        }
    }))
    const getInfo = row => {
        getVirusRecord(row).then(res => {
            setDetail(res.data)
        })
    }

    return visible ? (
        <div
            className="virus-info"
            style={{ top: props?.top, right: props?.right, left: props?.left }}
        >
            <img onClick={hide} src={Close} alt="err" className="close-img" />
            <div className="report-title">
                <span className="title-text">
                    {detail.userName} 丨 {detail.userPhone} 丨{' '}
                    {detail.userCardNumber}
                </span>
            </div>
            <div className="report-detail">
                {detail?.list?.map((item, ix) => (
                    <div className="record-item" key={ix}>
                        {ix === detail?.list.length - 1 ? null : (
                            <div class="item-tail"></div>
                        )}
                        <div className="record-time">
                            <img src={Dot} alt="err" className="dot-img"></img>
                            <span className="time">
                                打卡日期： {item.postTime}
                            </span>
                        </div>
                        <div className="record-content">
                            {item?.list.map((img, ix) => (
                                <div className="record-box" key={ix}>
                                    <div className="record-row">
                                        打卡时间：{img.postTime || '-'}
                                    </div>
                                    <div className="record-row flex">
                                        <span> 打卡图片：</span>
                                        <Image
                                            src={img.imgUrl}
                                            width={90}
                                            height={90}
                                        ></Image>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    ) : null
})

export default VirusInfo

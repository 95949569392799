import React, { useState, forwardRef, useImperativeHandle } from 'react'
import {
    getChanquanLog,
    getJuzhuProperty,
    getChuzuLog,
    getChenzuLog
} from '../../../../apis/community'
import Close from '../../imgs/close.png'
import Dot from '../../imgs/icon_dian.png'
import Empty from '../../imgs/empty.png'

import './index.fhd.scss'
const ChangeRecord = forwardRef((props, ref) => {
    const [visible, setVisible] = useState(false)
    const [record, setRecord] = useState([])
    const hide = () => {
        setType('')
        setRecord([])
        setVisible(false)
    }
    const [type, setType] = useState('')

    //chengzu rent resident chanquan
    useImperativeHandle(ref, () => ({
        open(type, houseId) {
            setType(type)
            getRecord(type, houseId)
            if (!visible) {
                setVisible(true)
            }
        },
        close() {
            setType('')
            setRecord([])
            setVisible(false)
        }
    }))
    const getRecord = (type, houseId) => {
        let method
        if (type === 'chanquan') {
            method = getChanquanLog
        } else if (type === 'rent') {
            method = getChuzuLog
        } else if (type === 'chengzu') {
            method = getChenzuLog
        } else {
            method = getJuzhuProperty
        }
        method(houseId).then(res => {
            setRecord(res.data)
        })
    }
    return visible ? (
        <div className="change-record">
            <img onClick={hide} src={Close} alt="err" className="close-img" />
            <div className="report-title">
                <span className="title-text">变更记录</span>
            </div>
            {record.length > 0 ? (
                <div className="modal-content">
                    {record?.map((item, ix) => (
                        <div className="record-item" key={ix}>
                            {ix === record?.length - 1 ? null : (
                                <div class="item-tail"></div>
                            )}
                            <div className="record-time">
                                <img
                                    src={Dot}
                                    alt="err"
                                    className="dot-img"
                                ></img>
                                <span className="time">
                                    <span className="time-label">
                                        {' '}
                                        更新时间：
                                    </span>
                                    <span className="time-text">
                                        {type === 'resident'
                                            ? item.logType === 1
                                                ? item?.rentCompany?.createTime
                                                : item?.rentLivePeople
                                                      ?.createTime
                                            : item.updateTime}
                                    </span>
                                </span>
                                {type === 'resident' ? (
                                    <span
                                        className={
                                            item?.rentLivePeople
                                                ?.peopleStatue === 0
                                                ? 'item-label in-label'
                                                : 'item-label out-label'
                                        }
                                    >
                                        {item?.rentLivePeople?.peopleStatue ===
                                        0
                                            ? '流入'
                                            : '流出'}
                                        -
                                        {item?.dbType === 1
                                            ? '标准库'
                                            : '辅助库'}
                                    </span>
                                ) : (
                                    <span className="item-label in-label">
                                        {item?.dbType === 1
                                            ? '标准库'
                                            : '辅助库'}
                                    </span>
                                )}
                            </div>
                            <div className="record-content">
                                {type === 'resident' && (
                                    <>
                                        {/* 单位 */}
                                        {item.logType === 1 ? (
                                            <>
                                                <div className="item-title">
                                                    单位信息
                                                </div>
                                                <div className="staff-item">
                                                    <div className="record-row">
                                                        <div className="row-item">
                                                            单位名称：
                                                            {item.rentCompany
                                                                .residentCompanyName ||
                                                                '-'}
                                                        </div>
                                                        <div className="row-item">
                                                            单位分类：
                                                            {item.rentCompany
                                                                .unitClassificationTypeMemo ||
                                                                '-'}
                                                        </div>
                                                    </div>
                                                    <div className="record-row">
                                                        <div className="row-item">
                                                            营业执照：
                                                            {item.rentCompany
                                                                .businessLicenseNo ||
                                                                '-'}
                                                        </div>
                                                        <div className="row-item">
                                                            单位性质:{' '}
                                                            {item.rentCompany
                                                                .unitNatureTypeMemo ||
                                                                '-'}
                                                        </div>
                                                    </div>
                                                    <div className="record-row">
                                                        <div className="row-item">
                                                            单位俗称:
                                                            {item.rentCompany
                                                                .residentCompanyCalledName ||
                                                                '-'}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="company-row">
                                                    <div className="record-row">
                                                        <div className="row-item">
                                                            法人姓名(个体工商户)：
                                                            {item.rentCompany
                                                                .legalName ||
                                                                '-'}
                                                        </div>
                                                        <div className="row-item">
                                                            证件类型：
                                                            {item.rentCompany
                                                                .legalIdCardTypeMemo ||
                                                                '-'}
                                                        </div>
                                                    </div>
                                                    <div className="record-row">
                                                        <div className="row-item">
                                                            联系电话：
                                                            {item.rentCompany
                                                                .legalLinkPhone ||
                                                                '-'}
                                                        </div>
                                                        <div className="row-item">
                                                            证件号码:
                                                            {item.rentCompany
                                                                .legalIdCard ||
                                                                '-'}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="company-row">
                                                    <div className="record-row">
                                                        <div className="row-item">
                                                            负责人姓名：
                                                            {item.rentCompany
                                                                .dutyName ||
                                                                '-'}
                                                        </div>
                                                        <div className="row-item">
                                                            证件类型：
                                                            {item.rentCompany
                                                                .dutyIdCardTypeMemo ||
                                                                '-'}
                                                        </div>
                                                    </div>
                                                    <div className="record-row">
                                                        <div className="row-item">
                                                            联系电话：
                                                            {item.rentCompany
                                                                .dutyLinkPhone ||
                                                                '-'}
                                                        </div>
                                                        <div className="row-item">
                                                            证件号码:
                                                            {item.rentCompany
                                                                .dutyIdCard ||
                                                                '-'}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="company-row">
                                                    <div className="record-row">
                                                        <div className="row-item">
                                                            保卫负责人姓名：
                                                            {item.rentCompany
                                                                .defendDutyName ||
                                                                '-'}
                                                        </div>
                                                        <div className="row-item">
                                                            证件类型：
                                                            {item.rentCompany
                                                                .defendDutyIdCardTypeMemo ||
                                                                '-'}
                                                        </div>
                                                    </div>
                                                    <div className="record-row">
                                                        <div className="row-item">
                                                            联系电话：
                                                            {item.rentCompany
                                                                .defendDutyLinkPhone ||
                                                                '-'}
                                                        </div>
                                                        <div className="row-item">
                                                            证件号码:
                                                            {item.rentCompany
                                                                .defendDutyIdCard ||
                                                                '-'}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="company-row">
                                                    <div className="record-row">
                                                        <div className="row-item">
                                                            单位分类：
                                                            {item.rentCompany
                                                                .unitClassificationTypeMemo ||
                                                                '-'}
                                                        </div>
                                                        <div className="row-item">
                                                            单位属性：
                                                            {item.rentCompany
                                                                .unitAttributeTypeMemo ||
                                                                '-'}
                                                        </div>
                                                    </div>
                                                    <div className="record-row">
                                                        <div className="row-item">
                                                            是否有监控：
                                                            {item.rentCompany
                                                                .monitorMemo ||
                                                                '-'}
                                                        </div>
                                                        <div className="row-item">
                                                            经营范围:{' '}
                                                            {item.rentCompany
                                                                .natureOfBusiness ||
                                                                '-'}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="item-title">
                                                    从业人员信息
                                                </div>
                                                {item?.rentCompany?.rentEmployeesInfos?.map(
                                                    (v, i) => (
                                                        <div
                                                            key={i}
                                                            className="staff-item"
                                                        >
                                                            <div className="record-row">
                                                                <div className="row-item">
                                                                    姓名：
                                                                    {v.employeesName ||
                                                                        '-'}
                                                                </div>
                                                                <div className="row-item">
                                                                    证件类型：
                                                                    {v.employeesIdCardTypeMemo ||
                                                                        '-'}
                                                                </div>
                                                            </div>
                                                            <div className="record-row">
                                                                <div className="row-item">
                                                                    联系电话：
                                                                    {v.employeesLinkPhoneMosaic ||
                                                                        '-'}
                                                                </div>
                                                                <div className="row-item">
                                                                    证件号码：
                                                                    {v.employeesIdCardMosaic ||
                                                                        '-'}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <div className="item-title">
                                                    居民信息
                                                </div>
                                                <div className="staff-item">
                                                    <div className="record-row">
                                                        <div className="row-item">
                                                            姓名：
                                                            {item.rentLivePeople
                                                                .liveName ||
                                                                '-'}
                                                        </div>
                                                        <div className="row-item">
                                                            证件类型：
                                                            {item.rentLivePeople
                                                                .liveCardIdTypeMemo ||
                                                                '-'}
                                                        </div>
                                                        <div className="row-item">
                                                            证件号码：
                                                            {item.rentLivePeople
                                                                .liveCardId ||
                                                                '-'}
                                                        </div>
                                                    </div>
                                                    <div className="record-row">
                                                        <div className="row-item">
                                                            联系电话：
                                                            {item.rentLivePeople
                                                                .livePhone ||
                                                                '-'}
                                                        </div>
                                                    </div>
                                                    {item.rentLivePeople
                                                        .peopleStatue === 0 ? (
                                                        <>
                                                            <div className="record-row">
                                                                <div className="row-item">
                                                                    入住时间：
                                                                    {item
                                                                        .rentLivePeople
                                                                        .checkInTime ||
                                                                        '-'}
                                                                </div>
                                                                <div className="row-item">
                                                                    租赁结束时间:
                                                                    {item
                                                                        .rentLivePeople
                                                                        .leaseEndTime ||
                                                                        '-'}
                                                                </div>
                                                                <div className="row-item">
                                                                    管理类别：
                                                                    {item
                                                                        .rentLivePeople
                                                                        .managementCategoryTypeMemo ||
                                                                        '-'}
                                                                </div>
                                                            </div>
                                                            <div className="record-row">
                                                                <div className="row-item">
                                                                    人口标签：
                                                                    {item.rentLivePeople?.labels?.join(
                                                                        ','
                                                                    ) || '-'}
                                                                </div>
                                                                <div className="row-item">
                                                                    户籍是否是本地地址：
                                                                    {item
                                                                        .rentLivePeople
                                                                        .registerLocalAddressMemo ||
                                                                        '-'}
                                                                </div>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div className="record-row">
                                                                <div className="row-item">
                                                                    离开时间：
                                                                    {item
                                                                        .rentLivePeople
                                                                        .leaveTime ||
                                                                        '-'}
                                                                </div>
                                                                <div className="row-item">
                                                                    离开原因：
                                                                    {item
                                                                        .rentLivePeople
                                                                        .leaveReason ||
                                                                        '-'}
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                    <div className="record-row">
                                                        <div className="row-item2">
                                                            信息来源：
                                                            {item.rentLivePeople
                                                                .backupsSourceMemo ||
                                                                '-'}
                                                        </div>
                                                        {item.rentLivePeople
                                                            .removeRemark && (
                                                            <div className="row-item">
                                                                信息状态：
                                                                {item
                                                                    .rentLivePeople
                                                                    .removeRemark ||
                                                                    '-'}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </>
                                )}
                                {type !== 'resident' && (
                                    <>
                                        <div className="record-row-f">
                                            {item?.items?.map((v, i) => (
                                                <div
                                                    key={i}
                                                    className="row-item"
                                                >
                                                    <span>
                                                        {v.operateField}：
                                                    </span>
                                                    <span>
                                                        {v.operateContent ||
                                                            '-'}
                                                    </span>
                                                </div>
                                            ))}
                                            <div className="row-item2">
                                                <span>信息来源：</span>
                                                <span>
                                                    {item.backupsSourceMemo ||
                                                        '-'}
                                                </span>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <div className="empty">
                    <img src={Empty} alt="err" className="empty-img"></img>
                    <div className="empty-text">暂无数据</div>
                </div>
            )}
        </div>
    ) : null
})

export default ChangeRecord

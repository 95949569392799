/*
 * @Author: 刘芳
 * @Date: 2022-08-07 15:33:54
 * @LastEditTime: 2023-03-14 21:44:50
 * @FilePath: \community-big-screen\src\views\community\components\warningList\index.fhd.js
 */
import React, { useState, forwardRef, useImperativeHandle } from 'react'
import classNames from 'classnames'
import {
    getWarnCommunityListXq
} from "../../../../apis/community"
import Close from '../../imgs/close.png'
import ListModal from '../../../../components/ListModal/ListModal'
import StatisticsModal from '../../../../components/StatisticsModal/StatisticsModal'
import './index.fhd.scss'
import { type } from '@testing-library/user-event/dist/type'
const WarningList = forwardRef((props, ref) => {
    const [visible, setVisible] = useState(false);
    const [warnCommunityListXq, setWarnCommunityListXq] = useState([]);
    const [oldwarnCommunityListXq, setoldWarnCommunityListXq] = useState([]);
    const [showTypes, setShowTypes] = useState(false);
    const [show, setShow] = useState(false);
    const [warnData, setWarnData] = useState({});
    const [activeType, setActiveType] = useState(0);
    const [choose, setChoose] = useState(null);
    const [number, setNumber] = useState({ all: 0, one: 0, two: 0, three: 0 });
    const hideWarningList = () => {
        setVisible(false);
    }
    useImperativeHandle(ref, () => ({
        open(communityId, eventType, type) {
            if (!visible) {
                setVisible(true)
            }
            setWarnData()
            setShow(false)
            setoldWarnCommunityListXq([])
            setWarnCommunityListXq([])
            setShowTypes(eventType === 7)
            getWarnCommunityListXqFun(communityId, eventType, type);
            setChoose(type === "processed" ? 2 : null)
        },
        close() {
            setVisible(false)
        }
    }))
    const getWarnCommunityListXqFun = async (communityId, eventType, type) => {
        const { data } = await getWarnCommunityListXq(communityId, eventType);
        if (eventType === 7) {
            const one = data.filter(item => item.eventType === 1);
            const two = data.filter(item => item.eventType === 2);
            const three = data.filter(item => item.eventType === 3);
            setNumber({
                all: data.length,
                one: one.length,
                two: two.length,
                three: three.length
            })
        }
        setoldWarnCommunityListXq(data)
        setWarnCommunityListXq(type === "processed" ? data.filter(item => item.warnStatus === 2) : data.filter(item => item.warnStatus !== 2) )
    }
    const openHouse = (item) => {
        console.log(item);
        // if (item.houseId && item.houseId > 0) {
        //     props?.openHouse(item.houseId);
        // } else {
            setWarnData(item)
            setShow(true)
        // }

    }
    // 切换分类
    const changeType = (type) => {
        setActiveType(type)
        if (type === 0) {
            setWarnCommunityListXq(oldwarnCommunityListXq)
        } else {
            setWarnCommunityListXq(oldwarnCommunityListXq.filter(item => item.eventType === type))
        }
    }
    // 切换状态
    const filterType = (type) => {
        setWarnCommunityListXq(oldwarnCommunityListXq.filter(item => item.warnStatus === Number(type)))
    }
    return (
        visible ?
            <div className='warningList h-[950px]'>
                <ListModal
                    height={'950px'}
                    width={900}
                    title={[
                        {
                            name: '提示类型',
                            width: 150
                        },
                        {
                            name: '提示内容',
                            width: 300
                        },
                        {
                            name: '当前状态',
                            width: 120,
                            select: true
                        },
                        {
                            name: '所属房屋号',
                            width: 150
                        },
                        {
                            name: '提示日期',
                            width: 80
                        },
                    ]}
                    selectList={
                        [
                            {
                                label: '已处理',
                                type: 2
                            },
                            {
                                label: '处理中',
                                type: 1
                            },
                            {
                                label: '待处理',
                                type: 0
                            },
                        ]
                    }
                    dateChoose={false}
                    closeModal={hideWarningList}
                    filterType={filterType}
                    choose={choose}
                >
                    <div className={classNames('flex fd-c')} >

                        <div className={classNames('list h-[902px]')} style={{ paddingTop: '0' }}>
                            {
                                warnCommunityListXq?.map(item => {
                                    return (
                                        <div className={classNames('item flex ai-c')} onClick={() => openHouse(item)} style={{ cursor: 'pointer' }}>
                                            <div className={classNames('fs-14 w-37.5 pl-4 py-3')}>{({ 1: '疑似群租房', 2: '人口上报过多', 3: '逾期任务超量', 4: '事件处理超时', 5: '诉求上报爆发', 6: '不满意', 7: '燃气隐患房屋', 9: '人口上报过少' }[item?.warnType] || '-')}</div>
                                            <div className={classNames('fs-14 w-75 pl-4 py-3')}>{item?.warnDesc}</div>
                                            <div className={classNames('fs-14 w-30 pl-4 py-3', ({ 0: 'text-[#ffb900]', 1: 'text-[#26a6ff]',2:'text-[#10df9d]' })[item?.warnStatus])}>{({ 0: '待处理', 1: '处理中', 2: '已处理' })[item?.warnStatus]}</div>
                                            <div className={classNames('fs-14 w-37.5 pl-4 py-3')}>{item?.houseCompleteName}</div>
                                            <div className={classNames('fs-14 w-20 pl-4 py-3')}>{item?.warnTime}</div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </ListModal>

                {show ? <StatisticsModal warnData={warnData} closeModal={() => setShow(false)} communityinfo={false}></StatisticsModal> : null}
            </div>
            : ''
    )
})

export default WarningList
